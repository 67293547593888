import Vue from 'vue'

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

// extend('positive', value => {
//   if (value == "") {
//     return true;
//   }
//   if(value)

//   return `This field must be a positive number`;
// });

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);