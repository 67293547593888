// import Vue from 'vue';
import axios from "@/plugins/axios";
import createPersistedState from "vuex-persistedstate";

import "toastify-js/src/toastify.css";
import Toastify from "toastify-js";
// Vue.prototype.$http = http

const getDefaultState = () => {
  return {
    users: [],
    single_user: {},
    loading: false,
    isSuccessful: false,
    allUsers: [],
    dataSet: null,
    metaData: {},
    validationErrors: {}
  };
};

export default {
  namespaced: true,
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    getUsers: (state) => {
      return state.users;
    },
    user: (state) => {
      return state.single_user;
    },
    isLoading: (state) => {
      return state.loading;
    },
    isSuccessful: (state) => {
      return state.isSuccessful;
    },
  },
  mutations: {
    SET_DATA: (state, payload) => {
      state.dataSet = payload.items || payload;
      state.metaData = payload.meta.pagination;
    },
    SET_SINGLE_USER: (state, single_user) => {
      state.single_user = single_user;
    },
    
    SET_LOGGED_IN: (state, token) => {
      state.token = token;
    },
    SET_LOADING_STATUS: (state, data) => {
      state.loading = data;
    },
    SET_VALIDATION_ERRORS: (state, payload) => {
      state.loading = false;
      state.validationErrors = payload;
    },
    SET_SUCCESS_STATUS: (state, data) => {
      state.isSuccessful = data;
    },
    REMOVE_ERRORS: (state) => {
      state.validationErrors = {}
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    // Get Users
    getUsers({ commit }, page) {
      commit("SET_LOADING_STATUS", true);
      axios
        .get("/admin/users?page=" + page)
        .then((res) => {
          let resPayload = res.data.data;
          commit("SET_DATA", resPayload);
          return res
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
        });
    },

    // Search Users
    searchUsers({ commit }, payload) {
      commit("SET_LOADING_STATUS", true);
      axios
        .post(`/admin/users/search`, payload.search)
        .then((res) => {
          let resPayload = res.data.data;
          commit("SET_DATA", resPayload);
          console.log(res.data.data);
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
        });
    },

    // Get User By Id
    getUserById({ commit }, id) {
      commit("SET_LOADING_STATUS", true);
      axios
        .get(`/admin/users/get/${id}`)
        .then((res) => {
          commit("SET_SINGLE_USER", res.data.data);
          return res
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
        });
    },

    // Add User to plan
    async deleteUser({ commit, dispatch }, id) {
      commit("SET_LOADING_STATUS", true);
      try {
        const res = await axios.get(`admin/users/delete/${id}`);
        Toastify({
          text: "User Deleted",
          className: "info",
          style: {
            background: "green",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
        return res;
      } catch (error) {
        Toastify({
          text: "User not deleted",
          className: "info",
          style: {
            background: "red",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
      } finally {
        commit("SET_LOADING_STATUS", false);
        dispatch("getUsers");
      }
    },

    // Add User to plan
    async editUser({ commit, dispatch }, payload) {
      commit("SET_LOADING_STATUS", true);
      try {
        const res = await axios.post(
          `admin/users/update/${payload.id}`,
          payload.formData
        );
        Toastify({
          text: "User Data Updated",
          className: "info",
          style: {
            background: "green",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
        commit("SET_SUCCESS_STATUS", true);
        return res;
      } catch (error) {
        Toastify({
          text: "User data not updated",
          className: "info",
          style: {
            background: "red",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
      } finally {
        commit("SET_LOADING_STATUS", false);
        dispatch("getUserById", payload.id);
      }
    },

    // Add User to plan
    async addPlanToUser({ commit, dispatch }, { id, payload }) {
      commit("SET_LOADING_STATUS", true);
      try {
        const res = await axios.post(`/admin/plans/user/add/${id}`, payload);
        dispatch("getUserById");
        Toastify({
          text: "Plan Updated",
          className: "info",
          style: {
            background: "green",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
        dispatch("getUserById", id);
        return res;
      } catch (error) {
        Toastify({
          text: "Plan not added, retry",
          className: "info",
          style: {
            background: "red",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
      } finally {
        commit("SET_LOADING_STATUS", false);
      }
    },

    // Create New User
    async createUser({ commit, dispatch }, payload) {
      commit("SET_LOADING_STATUS", true);
      try {
        const res = await axios.post(`/auth/register/user`, payload);
        commit("SET_SUCCESS_STATUS", true);
        dispatch("getUsers");
        Toastify({
          text: "User Created",
          className: "info",
          style: {
            background: "green",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
        return res
      } catch (error) {
        console.log(error.response.data.errors);
          if(error.response.data.errors) {
            let errPayload = error.response.data.errors
            commit("SET_VALIDATION_ERRORS", errPayload)
          }
        Toastify({
          text: "Error, retry",
          className: "info",
          style: {
            background: "red",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
        
        return error;
      } finally {
        commit("SET_LOADING_STATUS", false);
      }
    },

    // Activate User
    async activateUser({ dispatch }, id) {
      const res = await axios.get(`/admin/users/activate/${id}`);
      dispatch("getUserById", id);
      return res
    },

    // Remove Success Modal
    removeSuccessModal({ commit }) {
      commit("SET_SUCCESS_STATUS", false);
    },
  },
};
