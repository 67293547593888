var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.busy)?_c('div',[_c('span',[_vm._v("Retrieving data...")])]):_c('div',[_c('user-header',{attrs:{"title":_vm.title,"subTitle":_vm.subTitle,"picture":_vm.picture,"displayKey":[
        'unique_id',
        'phone_number',
        'wallet_address',
        'referral_code',
        'lga',
        'state',
        'country',
        'address' ],"data":_vm.userData,"hasStatus":true,"item":_vm.user,"countdown":_vm.countdown},on:{"activateFunc":_vm.activateFunc},scopedSlots:_vm._u([{key:"card-icons",fn:function(){return [_c('div',{staticClass:"tw-flex tw-gap-2 tw-items-center"},[_c('span',{staticClass:"tw-flex tw-flex-col"},[_c('span',{staticClass:"tw-font-light tw-text-[11px] tw-underline"},[_vm._v("Wallet Balance:")]),_c('span',{staticClass:"tw-font-semibold",class:{ 'tw-text-red-600': _vm.user.wallet.balance === 0 }},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.user.wallet.balance)))])]),_c('div',{staticClass:"tw-mx-1"},[_c('el-divider',{attrs:{"direction":"vertical"}})],1),_c('button',{staticClass:"veo-btn veo-primary",class:{
              'tw-bg-gray-600 tw-text-gray-200': _vm.user.wallet.balance === 0,
            },attrs:{"disabled":_vm.user.wallet.balance === 0},on:{"click":_vm.withdraw}},[_vm._v(" Withdraw ")]),_c('button',{staticClass:"veo-btn veo-primary",on:{"click":function($event){_vm.depositVisible = !_vm.depositVisible}}},[_vm._v(" Deposit ")])])]},proxy:true},{key:"rightBody",fn:function(){return [_c('div',[_c('h6',{staticClass:"tw-font-semibold tw-text-sm tw-underline"},[_vm._v(" Bank Details ")]),(_vm.bankDetails === null)?_c('div',[_c('span',{staticClass:"tw-text-[12px] tw-text-red-600 tw-block"},[_vm._v(" Oops! 🤔 "),_c('br'),_vm._v(" Bank details do not exist for this user ")])]):_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-3"},_vm._l((_vm.bankDetails),function(value,name){return _c('span',{key:name,staticClass:"tw-flex tw-flex-col"},[_c('span',{staticClass:"tw-text-[11px] tw-font-medium tw-text-gray-400 tw-uppercase"},[_vm._v(_vm._s(name.split("_").join(" "))+":")]),_c('span',{staticClass:"tw-text-xs tw-font-[13px]"},[_vm._v(_vm._s(value))])])}),0)])]},proxy:true}])}),_c('div',{staticClass:"tw-bg-white tw-rounded-md tw-p-8"},[_c('MainTab',{attrs:{"tabs":_vm.tabs,"currentTab":_vm.activeTab,"currentName":_vm.activeName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var activeTab = ref.activeTab;
return [_c(_vm.tabs[activeTab].component,{tag:"component",attrs:{"items":_vm.items,"referrals":_vm.referrals},on:{"addPlan":function($event){_vm.planVisible = !_vm.planVisible}}})]}}])})],1)],1),_c('withdraw-funds',{attrs:{"visible":_vm.isVisible,"item":_vm.item},on:{"handleClose":function($event){_vm.isVisible = !_vm.isVisible},"completeWithdrawal":_vm.isComplete}}),_c('add-user-plan',{attrs:{"visible":_vm.planVisible},on:{"handleClose":function($event){_vm.planVisible = !_vm.planVisible},"isComplete":_vm.completeAddPlan}}),_c('credit-user',{attrs:{"visible":_vm.depositVisible},on:{"handleClose":function($event){_vm.depositVisible = !_vm.depositVisible},"completeAdd":_vm.completeCreditUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }