// import Vue from 'vue';
import axios from '@/plugins/axios'
import createPersistedState from 'vuex-persistedstate';

// Vue.prototype.$http = http

const getDefaultState = () => {
    return {
        invoices: [],
        invoice: {}
    };
};

export default {
    namespaced: true,
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        getInvoices: state => {
            return state.invoices
        },
        singleInvoice: state => {
            return state.invoice
        }
    },
    mutations: {
        SET_INVOICES: (state, data) => {
            state.invoices = data;
        },
        SET_SINGLE_INVOICE: (state, data) => {
            state.invoice = data;
        },
        SET_LOGGED_IN: (state, token) => {
            state.token = token;
        },
        SET_USER: (state, user) => {
            state.user = user;
        }
    },
    actions: {
        // Get Countries
        async getInvoices({ commit }) {
            const res = await axios.get('/admin/invoice/all')
            commit('SET_INVOICES', res.data.data);
            console.log(res);
        },

        // View single Invoice
        async getSingleInvoice({ commit }, id) {
            axios.get(`/admin/invoice/view/${id}`)
            .then((res)=>{
                console.log(res);
                commit("SET_SINGLE_INVOICE", res.data.data)
            })
            .catch((err)=>{
                console.log(err);
            })
        },
        
        // Pay a hospital 
        async changeInvoiceStatus({ dispatch }, id) {
            const res = await axios.post(`/admin/invoice/change/status/${id}`)
            console.log(res);
            dispatch('getSingleInvoice', id)
            console.log(res);
        },

        // Accept an Invoice
        async acceptInvoice({ dispatch }, id) {
            const res = await axios.get(`/admin/invoice/accept/${id}`)
            dispatch('getSingleInvoice', id)
            console.log(res);
        },

        // Reject and Invoice
        async rejectInvoice({ dispatch }, id) {
            const res = await axios.get(`/admin/invoice/reject/${id}`)
            dispatch('getSingleInvoice', id)
            console.log(res);
        },
    }
};