<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <div v-if="isLoading">
      <span>Retrieving data...</span>
    </div>
    <div v-else>
      <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase">
        Edit {{ dataObj.name }}
      </h5>
      <validation-observer v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="tw-flex tw-flex-col tw-gap-4"
        >
          <span
            v-if="Object.keys(validationErrors).length > 0"
            class="tw-mb-5 tw-block tw-text-xs tw-text-error"
            >{{ validationErrors }}</span
          >
          <div class="tw-w-full">
            <validation-provider
              name="name"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="name">Name of Corporate Body</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="name"
                placeholder="Enter Name of corporate body"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.name"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-flex tw-gap-4">
            <div class="tw-w-full">
              <validation-provider
                name="email"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="username">Email Address</label>
                <input
                  type="text"
                  name="text"
                  id="input"
                  v-model="email"
                  placeholder="email"
                  :class="{
                    error: dirty && invalid,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.email"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div class="tw-w-full">
              <validation-provider
                name="phone number"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="username">Phone Number</label>
                <input
                  type="tel"
                  name="text"
                  id="input"
                  v-model="phone_number"
                  placeholder="Enter Phone Number"
                  :class="{
                    error: dirty && invalid,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.phone_number"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
          </div>

          <div class="tw-flex tw-gap-4">
            <div class="tw-w-full">
              <validation-provider
                name="country"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="username">Country</label>
                <country-select
                  class="tw-w-full tw-block"
                  :countryName="true"
                  v-model="country"
                  :country="country"
                  topCountry="NG"
                  id="select"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.country"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
            <div class="tw-w-full">
              <validation-provider
                name="state"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="username">Select State </label>
                <region-select
                  class="tw-w-full tw-block"
                  id="select"
                  v-model="state"
                  :country="country"
                  :region="state"
                  :countryName="true"
                  :regionName="true"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.state"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div class="tw-w-full">
              <validation-provider
                name="city"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="username">City</label>
                <input
                  type="text"
                  name="text"
                  id="input"
                  v-model="city"
                  placeholder="Enter City"
                  :class="{
                    error: dirty && invalid,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.city"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="address"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Address</label>
              <textarea
                id="input"
                v-model="address"
                placeholder="address"
                cols="30"
                rows="5"
                :class="{
                  error: dirty && invalid,
                }"
              >
              </textarea>
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.address"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="description"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Description</label>
              <textarea
                id="input"
                v-model="description"
                placeholder="description"
                cols="30"
                rows="5"
                :class="{
                  error: dirty && invalid,
                }"
              >
              </textarea>
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.description"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="profile photo"
              rules="photo"
              v-slot="{ errors }"
            >
              <label for="username">Profile Photo</label>
              <input @change="onFileChange" type="file" id="input" />
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.profile_photo"
                :key="error"
                >*{{ error }}</small
              >
            </validation-provider>
          </div>

          <div class="tw-mt-4 tw-text-center">
            <!-- <button
              class="veo-btn veo-primary tw-w-5/12"
            >
              <span>Add Enrollee</span>
            </button> -->

            <button
              class="veo-btn veo-primary tw-w-5/12"
              v-bind:disabled="busy"
              :class="{ 'tw-bg-gray-400': busy }"
            >
              <span>{{ busy ? "..." : "Edit" }}</span>
            </button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataObj: {},
      name: "",
      email: "",
      password: "",
      address: "",
      phone_number: "",
      country: "",
      state: "",
      city: "",
      profile_picture: "",
      description: "",
      dialogVisible: false,
      validationErrors: {},
      busy: false,
      isLoading: false,
      id: this.$route.params.id,
    };
  },

  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("country", this.country);
      formData.append("state", this.state);
      formData.append("lga", this.city);
      formData.append("address", this.address);
      formData.append("profile_picture", this.profile_picture);
      formData.append("description", this.description);
      this.busy = true;
      this.$users
        .updateCorporateBody(formData, this.id)
        .then((res) => {
          console.log(res, "valid");
          this.$toastify({
            text: "Corporate Body updated successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.getCorporateBody();
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.$toastify({
            text: "Corporate body not Updated",
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.validationErrors = err.data.errors;
        });
    },

    onFileChange(e) {
      var val = e.target.files[0];
      this.profile_picture = val;
    },
    
    resetForm() {
      this.handleClose();
    },

    handleClose() {
      this.dialogVisible = false;
    },

    getCorporateBody() {
      this.isLoading = true;
      this.$users
        .getCorporate(this.id)
        .then((res) => {
          this.isLoading = false;
          let resPayload = res.data;
          this.dataObj = resPayload;
          return res;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
  },

  beforeMount() {
    this.getCorporateBody();
  },

  watch: {
    dataObj: {
      handler(val) {
        this.name = val.name;
        this.email = val.email;
        this.password = val.password;
        this.address = val.address;
        this.phone_number = val.phone_number;
        this.country = val.country;
        this.state = val.state;
        this.city = val.lga;
        this.profile_picture = val.profile_picture;
        this.description = val.description;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* El Dialog  */
.el-dialog__header {
  display: none;
}
</style>
