<template>
  <div class="auth-content">
    <div>
      <div class="tw-mb-4">
        <img src="@/assets/img/logo.svg" class="tw-h-24 tw-w-24" alt="" />
        <h5 class="tw-font-bold tw-text-2xl tw-mb-0">Welcome back,</h5>
        <small class="tw-text-light tw-text-xs tw-block"
          >Sign in to continue to VeoHMO administrative panel</small
        >
      </div>
      <validation-observer v-slot="{ invalid, handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <span v-if="error" class="tw-mb-5 tw-block tw-text-xs tw-text-error">{{ error }}</span>
          <div>
            <validation-provider
              name="email"
              rules="required|email"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Email</label>
              <div
                class="input-field"
                :class="{
                  error: dirty && invalid,
                }"
              >
                <input
                  type="text"
                  name="text"
                  id="username"
                  v-model="credentials.email"
                  placeholder="email"
                />
                <span class="email-iccon">
                  <i-icon icon="heroicons:envelope" class="form-icon" />
                </span>
              </div>
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-my-3">
            <validation-provider
              class=""
              name="password"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="password">Password</label>
              <div
                class="input-field"
                :class="{
                  error: dirty && invalid,
                }"
              >
                <input
                  :type="typePassword ? 'password' : 'text'"
                  name="password"
                  id="password"
                  v-model="credentials.password"
                  placeholder="Password"
                />
                <span
                  class="password-iccon"
                  role="button"
                  @click="typePassword = !typePassword"
                >
                  <i-icon
                    :icon="
                      typePassword ? 'tabler:eye' : 'gridicons:not-visible'
                    "
                    class="form-icon"
                  />
                </span>
              </div>
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-mt-4">
            <button
              class="veo-btn veo-primary tw-w-full"
              v-bind:disabled="invalid || loading"
              :class="{ 'tw-bg-gray-400': invalid || loading }"
            >
              <span v-if="!loading">Sign in</span>
              <span v-else ><i-icon icon="line-md:loading-alt-loop" class="tw-text-[20px]" /> </span>
            </button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  data: () => {
    return {
      credentials: {
        email: "",
        password: "",
      },
      typePassword: true,
    };
  },
  methods: {
    ...mapActions("auth", ["userLogin"]),

    onSubmit() {
      let credentials = {
        email: this.credentials.email,
        password: this.credentials.password,
      };
      console.log(credentials);
      this.userLogin(credentials)
    },
  },

  mounted() {
    // this.$store.commit("auth/REMOVE_ERROR_SUCCESS");
  },

  watch: {},

  computed: {
    ...mapState("auth", {
      loading: (state) => state.loading,
      error: (state) => state.error,
      errors: (state) => state.validationErrors,
      user: (state) => state.user,
      success: (state) => state.success,
    }),
  },
};
</script>
