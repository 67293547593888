<template>
  <div>
    <div v-if="busy">
      <span>Retrieving data...</span>
    </div>
    <div v-else>
      <user-header
        :title="title"
        :subTitle="subTitle"
        :picture="picture"
        :displayKey="[
          'unique_id',
          'class',
          'phone_number',
          'lga',
          'state',
          'country',
          'description',
          'address',
        ]"
        :data="hospitalData"
      />
      <div class="tw-flex tw-gap-4">
        <div class="tw-bg-white tw-p-8 tw-w-full">
          <h6 class="tw-font-bold">Drugs price List</h6>
          <table-component
            class="tw-h-80 tw-overflow-auto"
            :items="drugs"
            :fields="drugFields"
            :paginate="false"
            :showBaseCount="false"
          />
        </div>
        <div class="tw-bg-white tw-p-8 tw-w-full">
          <h6 class="tw-font-bold">Services price List</h6>
          <table-component
            class="tw-h-80 tw-overflow-auto"
            :items="services"
            :fields="serviceFields"
            :paginate="false"
            :showBaseCount="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserHeader from "@/components/utils/UserHeader.vue";
import TableComponent from "@/components/TableComponent.vue";
export default {
  components: {
    UserHeader,
    TableComponent,
  },

  data() {
    return {
      drugs: [],
      services: [],
      serviceFields: [
        {
          key: "service.name",
          label: "Name",
        },

        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
      ],
      drugFields: [
        {
          key: "drug.name",
          label: "Name",
        },

        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
      ],
      id: this.$route.params.id,
      data: {},
      hospitalData: {
        unique_id: "",
        class: "",
        phone_number: "",
        lga: "",
        state: "",
        country: "",
        description: "",
        address: "",
      },
      busy: false,
    };
  },

  methods: {
    getData() {
      this.busy = true;
      this.$users
        .getHospital(this.id)
        .then((res) => {
          console.log(res.data, "valid");
          this.data = res.data;
          this.drugs = res.data.drugprice;
          this.services = res.data.serviceprice;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    data: {
      handler(val) {
        this.hospitalData = {
          unique_id: val.unique_id,
          class: val.class,
          phone_number: val.phone_number,
          lga: val.lga,
          state: val.state,
          country: val.country,
          description: val.description,
          address: val.address,
        };
      },
      immediate: true,
    },
  },

  computed: {
    title() {
      return this.data.name;
    },
    subTitle() {
      return this.data.email;
    },
    picture() {
      return this.data.profile_picture;
    },
  },
};
</script>

<style></style>
