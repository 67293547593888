var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"width":"40%","before-close":_vm.handleClose,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"tw-flex tw-flex-col tw-gap-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',[_c('validation-provider',{attrs:{"name":"plan_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('label',{attrs:{"for":""}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.name),expression:"payload.name"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","id":"input","placeholder":"Enter Drug Name"},domProps:{"value":(_vm.payload.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "name", $event.target.value)}}}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"name":"plan_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":""}},[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.description),expression:"payload.description"}],class:{
                error: dirty && invalid,
              },attrs:{"name":"","cols":"30","id":"input","rows":"5","placeholder":"Enter Drug Description"},domProps:{"value":(_vm.payload.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "description", $event.target.value)}}}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('span',{staticClass:"dialog-footer tw-flex tw-gap-2 tw-justify-center",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"veo-btn veo-primary",class:{ 'tw-bg-gray-400': invalid || _vm.busy },attrs:{"disabled":invalid}},[_vm._v(" "+_vm._s(_vm.editMode ? "Update Drug" : "Create New Drug")+" ")])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }