<template>
  <div>
    <el-dialog
      title="Credit User Wallet"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <validation-observer v-slot="{ invalid, handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="tw-flex tw-flex-col tw-gap-4"
        >
          <div>
            <validation-provider
              name="amount"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="">Amount</label>
              <input
                type="text"
                id="input"
                placeholder="Enter amount to credit user"
                v-model="payload.amount"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <span
            slot="footer"
            class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
          >
            <button
              class="veo-btn veo-primary"
              :disabled="invalid"
              :class="{ 'tw-bg-gray-400': invalid || busy }"
            >
              Deposit
            </button>
          </span>
        </form>
      </validation-observer>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {},
    },
    code_type: {
      type: String,
      default: "referral_code",
    },
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      hospitals: [],
      payload: {
        amount: null,
      },
      busy: false,
      id: this.$route.params.id
    };
  },
  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("amount", this.payload.amount);
      this.busy = true;
      this.$users
        .creditUser(this.id, formData)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: "User Account credited Succesfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
          this.$emit("completeAdd");
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.$toastify({
            text: "User Account not credited",
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        });
    },

    handleClose() {
      this.$emit("handleClose");
    },
  },
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
