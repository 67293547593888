import $request from "@/https/axios";

const $finance = {
  //   Claims
  async getClaims() {
    return $request.get(`/admin/claims/all`).then((response) => response.data);
  },

  async getClaim(params) {
    return $request
      .get(`/admin/claims/view/${params}`)
      .then((response) => response.data);
  },

  //   Invoices
  async getInvoices() {
    return $request.get(`/admin/invoice/all`).then((response) => response.data);
  },

  async getInvoice(params) {
    return $request
      .get(`/admin/invoice/view/${params}`)
      .then((response) => response.data);
  },

  // Pay a hospital
  async makePayment(params) {
    return $request
      .post(`/admin/invoice/change/status/${params}`)
      .then((response) => response.data);
  },

  // Accept or reject an Invoice
  async invoiceFunc(params, func) {
    return $request
      .get(`/admin/invoice/${func}/${params}`)
      .then((response) => response.data);
  },

  // withDrawals 
  async getWithdrawals(page) {
    return $request.get(`/admin/withdrawals?page=${page}`).then((response) => response.data);
  },

  async getWithdrawal(params) {
    return $request.get(`/admin/withdrawals/view/${params}`).then((response) => response.data);
  },

  async updateWithdrawal(params, body) {
    return $request.post(`/admin/withdrawals/update/${params}`, body).then((response) => response.data);
  },

  // async getInvoice(params) {
  //   return $request
  //     .get(`/admin/invoice/view/${params}`)
  //     .then((response) => response.data);
  // },
};

export default $finance;
