<template>
  <div>
    <div
      class="tw-p-4 tw-bg-primary-color tw-flex tw-justify-between tw-items-center tw-mb-4 tw-rounded-md"
    >
      <h4 class="tw-text-white tw-font-bold">
        <span class="tw-text-xs">Welcome,</span> {{ name }}
      </h4>
      <img src="@/assets/img/doctors.png" width="100px" height="130px" alt="" />
    </div>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div class="tw-grid tw-grid-cols-4 tw-gap-4">
          <b-skeleton
            v-for="item in 8"
            :key="item"
            width="100%"
            height="80px"
          ></b-skeleton>
        </div>
      </template>
      <div class="tw-grid tw-grid-cols-4 tw-gap-4">
        <div
          class="tw-bg-white tw-p-3 tw-rounded-md tw-shadow-sm"
          v-for="(value, name) in analyticsData"
          :key="name"
        >
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <span>
              <i-icon :icon="getIcon(name)" />
            </span>
            <span>
              <router-link :to="goToLink(name)" class="tw-text-gray-950">
                <i-icon icon="eva:diagonal-arrow-right-up-outline" />
              </router-link>
            </span>
          </div>
          <div class="tw-flex tw-flex-col">
            <span class="tw-capitalize tw-text-xs tw-font-medium">
              {{ name.split("_").join(" ") }}
            </span>
            <h3 class="tw-font-semibold tw-text-2xl tw-mb-0">{{ value }}</h3>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
    <div class="tw-mt-4">
      <h6 class="tw-font-semibold tw-text-[17px]">User Statistics</h6>
      <div class="tw-mt-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="tw-grid tw-grid-cols-2 tw-gap-4">
              <b-skeleton
                v-for="item in 2"
                :key="item"
                width="100%"
                height="120px"
              ></b-skeleton>
            </div>
          </template>
          <div class="tw-grid tw-grid-cols-2 tw-gap-4">
            <div class="tw-bg-white tw-p-3 tw-rounded-md tw-shadow-sm tw-shadow-sm tw-flex tw-flex-col tw-gap-2">
              <div
                class="tw-flex tw-flex-col tw-shadow tw-p-2 tw-rounded-md"
                v-for="(value, name) in regUsersStats"
                :key="name"
              >
                <span
                  role="button"
                  @click="goToQuery(name)"
                  class="tw-capitalize tw-flex tw-items-center tw-gap-1 tw-text-xs tw-font-medium"
                >
                  {{ name.split("_").join(" ") }}
                  <i-icon icon="eva:diagonal-arrow-right-up-outline" />
                </span>

                <h3 class="tw-font-semibold tw-text-2xl tw-mb-0">
                  {{ value }}
                </h3>
              </div>
            </div>

            <div class="tw-bg-white tw-p-3 tw-rounded-md tw-shadow-sm tw-flex tw-flex-col tw-gap-2">
              <div
                class="tw-flex tw-flex-col tw-shadow tw-p-2 tw-rounded-md"
                v-for="(value, name) in enrolleeStats"
                :key="name"
              >
                <span
                  role="button"
                  @click="goToQuery(name)"
                  class="tw-flex tw-items-center tw-gap-1 tw-capitalize tw-text-xs tw-font-medium"
                >
                  {{ name.split("_").join(" ") }}
                  <i-icon icon="eva:diagonal-arrow-right-up-outline" />
                </span>
                <h3 class="tw-font-semibold tw-text-2xl tw-mb-0">
                  {{ value }}
                </h3>
              </div>
            </div>

            <!-- <div
          class="tw-bg-white tw-p-3 tw-rounded-md tw-shadow-sm"
          v-for="(value, name) in analyticsData"
          :key="name"
        >
          <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <span>
              <i-icon :icon="getIcon(name)" />
            </span>
            <span>
              <router-link :to="goToLink(name)" class="tw-text-gray-950">
                <i-icon icon="eva:diagonal-arrow-right-up-outline" />
              </router-link>
            </span>
          </div>
          <div class="tw-flex tw-flex-col">
            <span class="tw-capitalize tw-text-xs tw-font-medium">
              {{ name.split("_").join(" ") }}
            </span>
            <h3 class="tw-font-semibold tw-text-2xl tw-mb-0">{{ value }}</h3>
          </div>
        </div> -->
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      analytics: {},
      user_stats: {},
      loading: false,
    };
  },
  methods: {
    getAnalytics() {
      this.loading = true;
      this.$request
        .get("admin/metrics")
        .then((res) => {
          console.log(res, "valid");
          this.analytics = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err, "ommo");
          this.loading = false;
        });
    },

    goToQuery(value) {
      this.$router.push(
        this.queryValue(value)
          ? `/enrollees?${this.queryValue(value)}=true`
          : "/enrollees"
      );
    },

    getUserAnalytics() {
      this.loading = true;
      this.$request
        .get("admin/users-stats")
        .then((res) => {
          console.log(res, "valid");
          this.user_stats = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err, "ommo");
          this.loading = false;
        });
    },

    getIcon(value) {
      let data = {
        all_users: "ci:users",
        hospitals: "solar:hospital-linear",
        families: "icon-park-outline:family",
        groups: "iconoir:group",
        invoices: "mdi:invoice-outline",
        plans: "fluent-mdl2:diet-plan-notebook",
        secondary_care_codes: "tdesign:barcode-1",
        referral_codes: "healthicons:referral-outline",
      };
      return data[value];
    },

    goToLink(value) {
      let data = {
        all_users: "/enrollees",
        hospitals: "/hospitals",
        families: "/families",
        groups: "/groups",
        invoices: "/invoices",
        plans: "/plans",
        secondary_care_codes: "/secondary-care-codes",
        referral_codes: "/referral-codes",
      };
      return data[value];
    },

    queryValue(value) {
      let data = {
        total_recent_users: "recent_users",
        total_active_enrollees: "active_plan",
        total_users__without_plan: "without_plan",
        total_enrollees: "with_plan",
        total_enrollees_with_expired_plan: "expired_plans",
        total_enrollees_with_plans_expiring_in_90days: "expiring_in_90_days",
      };
      return data[value];
    },
  },

  beforeMount() {
    this.getAnalytics();
    this.getUserAnalytics();
  },

  computed: {
    analyticsData() {
      let data = {
        all_users: this.analytics.users,
        hospitals: this.analytics.hospitals,
        families: this.analytics.families,
        groups: this.analytics.groups,
        invoices: this.analytics.invoice,
        plans: this.analytics.plans,
        secondary_care_codes: this.analytics.secondary_care_codes,
        referral_codes: this.analytics.referral_codes,
      };
      return data;
    },

    name() {
      let user = this.$store.getters["auth/getUser"];
      return `${user.first_name} ${user.last_name}`;
    },

    regUsersStats() {
      let data = {
        total_registered_users: this.user_stats.allUsersCount,
        total_recent_users: this.user_stats.recentUsersCount,
        total_users__without_plan: this.user_stats.usersWithoutPlanCount,
      };
      return data;
    },

    enrolleeStats() {
      let data = {
        total_enrollees: this.user_stats.usersWithPlanCount,
        total_active_enrollees: this.user_stats.usersWithActivePlanCount,
        total_enrollees_with_expired_plan:
          this.user_stats.usersWithExpiredPlansCount,
        total_enrollees_with_plans_expiring_in_90days:
          this.user_stats.usersWithPlansExpiringIn90DaysCount,
      };
      return data;
    },
  },
};
</script>

<style></style>
