<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase">
      Add new Enrollee
    </h5>
    <!-- <validation-observer v-slot="{ handleSubmit }"> -->
    <validation-observer v-slot="{ invalid, handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        class="tw-flex tw-flex-col tw-gap-4"
      >
        <span
          v-if="Object.keys(validationErrors).length > 0"
          class="tw-mb-5 tw-block tw-text-xs tw-text-error"
          >{{ validationErrors }}</span
        >
        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="first name"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="first_name">First Name</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="first_name"
                placeholder="Enter First name"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.first_name"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <div class="tw-w-full">
            <validation-provider
              name="last name"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="last name">Last Name</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="last_name"
                placeholder="Enter Last Name"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.last_name"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="other names"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="other names">Other Names</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="other_name"
                placeholder="Enter Other Names"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.other_name"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="gender"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Gender</label>
              <select
                name=""
                id="select"
                v-model="gender"
                :class="{
                  error: dirty && invalid,
                }"
              >
                <option value="" selected disabled>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.gender"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <div class="tw-w-full">
            <validation-provider
              name="date of birth"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Date of Birth</label>
              <input
                type="date"
                name="text"
                id="input"
                v-model="dob"
                placeholder="Enter Date of Birth"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.dob"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="phone number"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Phone Number</label>
              <input
                type="tel"
                name="text"
                id="input"
                v-model="phone_number"
                placeholder="Enter Phone Number"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.phone_number"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="email"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Email Address</label>
            <input
              type="text"
              name="text"
              id="input"
              v-model="email"
              placeholder="email"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.email"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="country"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="username">Country</label>
              <country-select
                class="tw-w-full tw-block"
                :countryName="true"
                v-model="country"
                :country="country"
                topCountry="NG"
                id="select"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.country"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <div class="tw-w-full">
            <validation-provider
              name="state"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="username">Select State </label>
              <region-select
                class="tw-w-full tw-block"
                id="select"
                v-model="state"
                :country="country"
                :region="state"
                :countryName="true"
                :regionName="true"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.state"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="city"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Lga</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="city"
                placeholder="Enter Lga"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.city"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="address"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Address</label>
            <textarea
              id="input"
              v-model="address"
              placeholder="address"
              cols="30"
              rows="5"
              :class="{
                error: dirty && invalid,
              }"
            >
            </textarea>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.address"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="password"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Password</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="password"
                placeholder="Enter Password"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.password"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <div class="tw-w-full">
            <validation-provider
              name="confirm password"
              rules="required|confirmed:password"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Confirm Password</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="password_confirmation"
                placeholder="Confirm Password"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.password_confirmation"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="profile photo"
            rules="photo"
            v-slot="{ errors }"
          >
            <label for="username">Profile Photo</label>
            <input @change="onFileChange" type="file" id="input" />
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.profile_photo"
              :key="error"
              >*{{ error }}</small
            >
          </validation-provider>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="referral code"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="ref_code">Referral Code</label>
            <input
              type="text"
              name="text"
              id="input"
              v-model="ref_code"
              placeholder="Enter referral code"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.email"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-mt-4 tw-text-center">
          <!-- <button
            class="veo-btn veo-primary tw-w-5/12"
          >
            <span>Add Enrollee</span>
          </button> -->

          <button
            class="veo-btn veo-primary tw-w-5/12"
            v-bind:disabled="invalid || busy"
            :class="{ 'tw-bg-gray-400': invalid || busy }"
          >
            <span>{{ busy ? "..." : "Add Enrollee" }}</span>
          </button>
        </div>
      </form>
    </validation-observer>

    <!-- Success Modal When Created  -->
    <el-dialog
      title="Tips"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :show-title="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="tw-flex tw-flex-col tw-gap-2 tw-items-center">
        <i-icon
          icon="charm:circle-tick"
          class="tw-text-[80px] tw-text-primary-color"
        />
        <span class="tw-text-lg tw-font-bold tw-text-center"
          >Enrollee Created <br />Successfully</span
        >
      </div>

      <span
        slot="footer"
        class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
      >
        <button
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-px-2 tw-bg-blue-500"
          @click="resetForm"
        >
          Create New
        </button>
        <button
          @click="view"
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-info tw-px-2"
        >
          View Enrollee
        </button>
        <button
          @click="$router.go(-1)"
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-success tw-px-2"
        >
          Go Back
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataObj: {},
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      address: "",
      phone_number: "",
      country: "",
      state: "",
      city: "",
      password_confirmation: "",
      profile_picture: "",
      other_name: "",
      dob: "",
      gender: "",
      ref_code: "",
      dialogVisible: false,
      validationErrors: {},
      busy: false,
    };
  },

  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("country", this.country);
      formData.append("state", this.state);
      formData.append("city", this.city);
      formData.append("address", this.address);
      formData.append("profile_picture", this.profile_picture);
      formData.append("other_name", this.other_name);
      formData.append("dob", this.dob);
      formData.append("gender", this.gender);
      formData.append("upline_code", this.ref_code);
      formData.append("password", this.password);
      formData.append("password_confirmation", this.password_confirmation);
      this.busy = true;
      this.$users
        .createEnrollee(formData)
        .then((res) => {
          console.log(res, "valid");
          this.dialogVisible = true;
          this.busy = false;
          this.enrollee = res.user;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.validationErrors = err.data.errors;
        });
    },

    onFileChange(e) {
      var val = e.target.files[0];
      this.profile_picture = val;
    },

    view() {
      this.$router.push(`/enrollee/view/${this.enrollee.id}`);
    },

    resetForm() {
      this.handleClose();
    },

    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
/* El Dialog  */
.el-dialog__header {
  display: none;
}
</style>
