import $request from "@/https/axios";

const $inventory = {
  //   Drugs
  async getDrugs(params) {
    return $request
      .get(`/admin/drugs/all?page=${params}`)
      .then((response) => response.data);
  },

  async updateDrug(params, body){
    return $request
      .post(`admin/drugs/update/${params}`, body)
      .then((response) => response.data);
  },

  async deleteDrug(params){
    return $request
      .post(`admin/drugs/delete/${params}`)
      .then((response) => response.data);
  },

  async addDrug(body){
    return $request
      .post(`admin/drugs/add`, body)
      .then((response) => response.data);
  },

  // Drug Prices 
  async getDrugPrices(params) {
    return $request
      .get(`/admin/drugs/price/all?page=${params}`)
      .then((response) => response.data);
  },

  async searchDrugs(body) {
    return $request
      .post(`admin/drugs/search`, body)
      .then((response) => response.data);
  },

  async createDrugPrice(body) {
    return $request
      .post("/admin/drugs/price/add", body)
      .then((response) => response.data);
  },

  async deleteDrugPrice(params) {
    return $request
      .get(`/admin/drugs/price/delete/${params}`)
      .then((response) => response.data);
  },

  async viewDrugPrice(params) {
    return $request
      .get(`/admin/drugs/price/view/${params}`)
      .then((response) => response.data);
  },

  async drugsUpload(body) {
    return $request
      .post(`/admin/drugs/import`, body)
      .then((response) => response.data);
  },

  // Services 
  async getServices(params) {
    return $request
      .get(`/admin/services?page=${params}`)
      .then((response) => response.data);
  },

  async updateService(params, body){
    return $request
      .post(`admin/services/update/${params}`, body)
      .then((response) => response.data);
  },

  async deleteService(params){
    return $request
      .get(`admin/services/delete/${params}`)
      .then((response) => response.data);
  },

  async addService(body){
    return $request
      .post(`admin/services/add`, body)
      .then((response) => response.data);
  },

  async searchServices(body) {
    return $request
      .post(`admin/services/search`, body)
      .then((response) => response.data);
  },


  // Service Prices 
  async getServicePrices(params) {
    return $request
      .get(`/admin/services/price/all?page=${params}`)
      .then((response) => response.data);
  },

  async viewServicePrice(params) {
    return $request
      .get(`/admin/services/price/view/${params}`)
      .then((response) => response.data);
  },

  async servicesUpload(body) {
    return $request
      .post(`/admin/services/import`, body)
      .then((response) => response.data);
  },


  // Plans
  async getPlans(){
    return $request
      .get(`/admin/plans`)
      .then((response) => response.data);
  },

  async getPlan(params){
    return $request
      .get(`/admin/plans/view/${params}`)
      .then((response) => response.data);
  },

  async updatePlan(params, body){
    return $request
      .post(`admin/plans/update/${params}`, body)
      .then((response) => response.data);
  },

  async addPlan(body){
    return $request
      .post(`admin/plans/add`, body)
      .then((response) => response.data);
  },

  async addPlanBenefit(body){
    return $request
      .post(`admin/plans/benefits/add`, body)
      .then((response) => response.data);
  },

  async deletePlan(params){
    return $request
      .get(`admin/plans/delete/${params}`)
      .then((response) => response.data);
  },

  // Codes 
  async generateCode(body, type){
    let url = type === 'referral_code' ? 'admin/referral/code/create' : 'admin/secondarycare/code/create'
    return $request
      .post(`${url}`, body)
      .then((response) => response.data);
  },
  
  async searchSecondaryCareCode(body){
    return $request
      .post(`admin/secondarycare/code/search`, body)
      .then((response) => response.data);
  },

  async getAllReferralCodes(page){
    return $request
      .get(`/admin/referral/code/all?page=${page}`)
      .then((response) => response.data);
  },

  async getAllSecondaryCareCodes(page){
    return $request
      .get(`/admin/secondarycare/code/all?page=${page}`)
      .then((response) => response.data);
  },
};

export default $inventory;
