<template>
  <div>
    <el-dialog
      title="Code Generated"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
    <!-- <hr class="tw-mb-2"> -->
      <h1 class="tw-text-center">{{ item.code }}</h1>
      <span class="veo-btn veo-primary tw-block tw-w-fit tw-mx-auto" role="button" @click="copyCode">
        {{ copy_action }}
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialogVisible: false,
      busy: false,
      copy_action: "copy",
    };
  },

  methods: {
    handleClose() {
      this.$emit("handleClose");
    },

    copyCode() {
      navigator.clipboard.writeText(this.item.code).then(
        function () {
          /* clipboard successfully set */
          alert("Copied");
          // this.copy_action = "copied";
        },
        function () {
          alert("something Went wrong");
          
        }
      );
    },
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
