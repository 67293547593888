<template>
  <div>
    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
      <span
        v-for="(value, name) in details"
        :key="name"
        class="tw-flex tw-flex-col"
      >
        <span class="tw-capitalize tw-font-light tw-text-xs">{{
          name.split("_").join(" ")
        }}</span>
        <span class="tw-font-semibold tw-text-sm">{{ value === null ? '---' : value }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visit: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    details() {
      let data = {
        area_of_speciality: this.visit.area_of_speciality,
        date_of_admission: this.visit.date_of_admission,
        date_of_discharge: this.visit.date_of_discharge,
        date_of_service: this.visit.date_of_service,
        diagnosis: this.visit.diagnosis,
        doctor_name: this.visit.doctor_name,
        duration_of_treatment: this.visit.duration_of_treatment > 1 ? this.visit.duration_of_treatment + " days" : this.visit.duration_of_treatment + " day",
        referral_code: this.visit.referral_code,
        treatment_code: this.visit.treatment_code,
        treatment_given: this.visit.treatment_given,
      };
      return data;
    },
  },
};
</script>

<style></style>
