<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-semibold tw-mb-3">All Withdrawals</h5>
    <table-component
      :items="items"
      :busy="busy"
      :fields="fields"
      @view="viewRecord"
      :disableDeleteAction="true"
      :disableEditAction="true"
      :perPage="perPage"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :paginationRange="count"
      :totalRecord="totalRows"
      :totalPages="pages"
      @page-changed="list"
    >
      <template #search> </template>
    </table-component>

    <!-- withrawal Details  -->
    <WithdrawalDetails
      :visible="isVisible"
      :id="id"
      @isComplete="list"
      @handleClose="handleClose"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import WithdrawalDetails from "@/components/modals/finance/WithdrawalDetails.vue";
export default {
  components: { TableComponent, WithdrawalDetails },
  data() {
    return {
      items: [],
      busy: false,
      fields: [
        {
          key: "user",
          label: "User",
          formatter: (item) => {
            return `${item.first_name} ${item.last_name}`;
          },
        },
        {
          key: "amount",
          label: "Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
        {
          key: "bank_name",
          label: "Bank Name",
        },
        {
          key: "account_no",
          label: "Bank Account Number",
        },

        {
          key: "account_name",
          label: "Account Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      prevRoute: null,
      isVisible: false,
      item: {},
      id: 0,
      currentPage: 1,
      perPage: 0,
      totalRows: 0,
      count: 1,
      pages: 1,
    };
  },

  methods: {
    list(page = 1) {
      console.log(page, "kkk");
      this.busy = true;
      this.$finance
        .getWithdrawals(page)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.data;
          console.log(resPayload);
          let meta = resPayload;
          this.currentPage = meta.current_page;
          this.totalRows = meta.total;
          this.perPage = meta.per_page;
          this.pages = meta.last_page;
          this.busy = false;
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    viewRecord(value) {
      this.id = value.id;
      this.isVisible = !this.isVisible;
    },

    handleClose() {
      this.isVisible = !this.isVisible;
    },
  },

  beforeMount() {
    this.list();
  },
};
</script>

<style></style>
