// import Vue from 'vue';
import http from "@/plugins/axios";
// import Axios from '@/plugins/axios'
import createPersistedState from "vuex-persistedstate";

// Vue.prototype.$http = http

// import "toastify-js/src/toastify.css"
// import Toastify from 'toastify-js'
// import router from '@/router';

const getDefaultState = () => {
  return {
    // Claims - Created from Visits
    claims: [],

    // Single Claim
    single_claim: {},
  };
};

export default {
  namespaced: true,
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    getClaims: (state) => {
      return state.claims;
    },
    singleClaim: (state) => {
      return state.single_claim;
    },
  },
  mutations: {
    SET_CLAIMS: (state, data) => {
      state.claims = data;
    },
    SET_SINGLE_CLAIM: (state, data) => {
      state.single_claim = data;
    },
  },
  actions: {
    // Get Claims
    async getClaims({ commit }) {
      try {
        const res = await http.get(`/admin/claims/all`);
        commit("SET_CLAIMS", res.data.data);
        console.log(res.data.data);
        return res;
      } catch (error) {
        return error;
      }
    },

    // Get Claims
    async getClaimByID({ commit }, id) {
      try {
        const res = await http.get(`/admin/claims/view/${id}`);
        commit("SET_SINGLE_CLAIM", res.data.data);
        console.log(res.data.data);
        return res;
      } catch (error) {
        return error;
      }
    },
  },
};
