import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";

import "@/assets/css/style.css";

import "toastify-js/src/toastify.css";
import Toastify from "toastify-js";

import instance from "@/plugins/axios.js";

Vue.prototype.$axios = instance;
Vue.prototype.$toastify = Toastify;
// Vue.use(http)

import $http from  'axios'
Vue.prototype.$http = $http

import { Icon } from "@iconify/vue2";

Vue.component("i-icon", Icon);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

Vue.config.ignoredElements = [/^ion-/];
Vue.config.ignoredElements = [/^IconComponent-/];

Vue.config.productionTip = false;

import $request from "@/https/axios";
Vue.prototype.$request = $request;

import config from "@/https/config";
Vue.prototype.config = config;

// Plugins
import veeValidate from "@/plugins/vee-validate";
import bootstrapVue from "@/plugins/bootstrap-vue";

import vueCountryRegionSelect from "vue-country-region-select";
Vue.use(vueCountryRegionSelect);

// vue-sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2, {
  confirmButtonColor: "tw-bg-primary-color",
  cancelButtonColor: "#ff7674",
  reverseButtons: false,
  buttonsStyling: true,
  customClass: {
    confirmButton: "tw-bg-primary-color tw-text-white tw-py-3",
    cancelButton: "tw-bg-red-600 tw-text-white tw-py-3",
  },
});

import VSelect from "vue-select";

Vue.component("v-select", VSelect);
import "vue-select/dist/vue-select.css";

// Filters
Vue.filter("displayDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD MMMM YYYY hh:mm A");
  }
});

Vue.filter("displayTime", function (value) {
  if (value) {
    return moment(String(value)).format("hh:mm A");
  }
});

Vue.filter("strLimit", function (value) {
  if (value) {
    return value.slice(0, 40) + "...";
  }
});

Vue.filter("createRef", function (value) {
  if (value) {
    return ("#" + value.slice(0, 5)).toUpperCase();
  }
});

Vue.filter("currencyFormat", function (value) {
  if (value) {
    return Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
    });
  } else {
    return "NGN 0.00";
  }
});

import services from "@/services/index";
new Vue({
  router,
  store,
  veeValidate,
  bootstrapVue,
  services,
  render: (h) => h(App),
}).$mount("#app");
