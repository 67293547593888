<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <validation-observer v-slot="{ invalid, handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="tw-flex tw-flex-col tw-gap-4"
        >
          <!-- <span
          v-if="Object.keys(validationErrors).length > 0"
          class="tw-mb-5 tw-block tw-text-xs tw-text-error"
          >{{ validationErrors }}</span
        > -->
          <div>
            <validation-provider
              name="drug"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="">Drug</label>
              <v-select
                @search="getItems"
                v-model="payload.drug"
                :options="items"
                label="name"
                :reduce="item => item.id"
                :class="{
                  error: dirty && invalid,
                }"
                placeholder="Select Drug"
              >
                <template slot="no-options"> Type to search.. </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><i-icon
                      icon="radix-icons:caret-down"
                      color="#4F4F4F"
                      width="18px"
                  /></span>
                </template>
              </v-select>
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div>
            <validation-provider
              name="hospital"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="">Hospital</label>
              <v-select
                @search="searchHospitals"
                v-model="payload.hospital"
                :options="hospitals"
                :reduce="item => item.id"
                label="name"
                :class="{
                  error: dirty && invalid,
                }"
                placeholder="Select Hospital"
              >
                <template slot="no-options"> Type to search.. </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><i-icon
                      icon="radix-icons:caret-down"
                      color="#4F4F4F"
                      width="18px"
                  /></span>
                </template>
              </v-select>
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div>
            <validation-provider
              name="price"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="">Price</label>

              <input
                type="text"
                id="input"
                placeholder="Enter Price"
                v-model="payload.price"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <span
            v-if="!viewMode"
            slot="footer"
            class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
          >
            <button
              class="veo-btn veo-primary"
              :disabled="invalid"
              :class="{ 'tw-bg-gray-400': invalid || busy }"
            >
              {{ editMode ? "Edit" : "Create New" }}
            </button>
          </span>
        </form>
      </validation-observer>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Create Drug Price",
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      hospitals: [],
      payload: {
        drug: null,
        hospital: null,
        price: null,
      },
      busy: false,
    };
  },
  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("drug_id", this.payload.drug);
      formData.append("hospital_id", this.payload.hospital);
      formData.append("price", this.payload.price);
      this.busy = true;
      this.$inventory
        .createDrugPrice(formData)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: "Drug Price created successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
          this.$emit("handleClose");
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    handleClose() {
      this.$emit("handleClose");
      this.payload = {
        drug: "",
        hospital: "",
        price: "",
      };
    },

    getItems(search, loading) {
      let payload = { name: search };
      loading(true);
      this.$inventory
        .searchDrugs(payload)
        .then((res) => {
          console.log(res);
          this.items = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading(false);
        });
    },

    searchHospitals(search, loading) {
      let payload = { name: search };
      loading(true);
      this.$users
        .searchHospitals(payload)
        .then((res) => {
          console.log(res);
          this.hospitals = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading(false);
        });
    },
  },
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },

    item: {
      handler(val) {
        console.log(val);
        if (Object.keys(val).length !== 0) {
          this.payload = {
            drug: val.drug_id,
            hospital: val.hospital_id,
            price: val.price,
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
