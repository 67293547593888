<template>
    <div class="tw-bg-white tw-rounded-md tw-p-8">
      <table-component
        :items="items"
        :busy="busy"
        :fields="fields"
        :disableViewAction="true"
        @delete="deleteRecord"
        @edit="editRecord"
        :paginate="false"
      >
        <!-- <template #search>
          <search-filter
            @filter="filter"
            searchPlaceholder="Search Enrollees"
            @refresh="list"
          />
        </template> -->
        <template #button>
          <router-link to="/admins/create">
            <button class="veo-btn veo-primary">Add New Admin</button>
          </router-link>
        </template>
      </table-component>
    </div>
  </template>
  
  <script>
  import TableComponent from "@/components/TableComponent.vue";
  // import SearchFilter from "@/components/filters/SearchFilter.vue";
  export default {
    components: { TableComponent },
    data() {
      return {
        items: [],
        busy: false,
        fields: [
          {
            key: "photo",
            label: "",
          },
          {
            key: "userDetails",
            label: "Name",
          },
          {
            key: "email",
            label: "Email",
          },
          {
            key: "role",
            label: "Role",
          },
          {
            key: "actions",
            label: "",
          },
        ],
        currentPage: 1,
        perPage: 0,
        totalRows: 0,
        count: 1,
        pages: 1,
        prevRoute: null,
      };
    },
  
    methods: {
      list(page = 1) {
        this.busy = true;
        this.$config
          .listAdmins(page)
          .then((res) => {
            let resPayload = res.data;
            this.items = resPayload.data;
            return res;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(()=> {
            this.busy = false
          })
      },

      editRecord(value) {
        this.$router.push(`/admin/${value.id}/edit`);
      },
  
      complete(value) {
        this.$config
          .removeAdminRecord(value.id)
          .then((res) => {
            this.$swal.fire(
              "Deleted!",
              "Admin deleted succesfully.",
              "success"
            );
            this.list();
            return res;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      deleteRecord(value) {
        this.$swal
          .fire({
            title: "Continue?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            console.log(result, "kkk");
            if (result.isConfirmed) {
              this.complete(value);
            }
          });
      },
  
      filter(value) {
        console.log(value);
        this.busy = true;
        let payload = {
          search: value,
        };
        if (value === "") {
          this.list();
        } else {
          this.$users
            .searchEnrollees(payload)
            .then((res) => {
              let resPayload = res.data;
              console.log(resPayload, "ommo");
              this.items = resPayload;
              this.busy = false;
              return res;
            })
            .catch((err) => {
              console.log(err);
              this.busy = false;
            });
        }
      },
    },
  
    beforeMount() {
      this.list();
    },
  };
  </script>
  
  <style></style>
  