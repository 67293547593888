var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"width":"40%","before-close":_vm.handleClose,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"tw-flex tw-flex-col tw-gap-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',[_c('validation-provider',{attrs:{"name":"service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('label',{attrs:{"for":""}},[_vm._v("Service")]),_c('v-select',{class:{
                error: dirty && invalid,
              },attrs:{"options":_vm.items,"reduce":function (item) { return item.id; },"label":"name","placeholder":"Select Service"},on:{"search":_vm.searchServices},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
              var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('i-icon',{attrs:{"icon":"radix-icons:caret-down","color":"#4F4F4F","width":"18px"}})],1)]}}],null,true),model:{value:(_vm.payload.service_id),callback:function ($$v) {_vm.$set(_vm.payload, "service_id", $$v)},expression:"payload.service_id"}},[_c('template',{slot:"no-options"},[_vm._v(" Type to search.. ")])],2),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"name":"quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":""}},[_vm._v("Quantity")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.quantity),expression:"payload.quantity"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","id":"input","placeholder":"Enter Quantity"},domProps:{"value":(_vm.payload.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "quantity", $event.target.value)}}}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(!_vm.viewMode)?_c('span',{staticClass:"dialog-footer tw-flex tw-gap-2 tw-justify-center",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"veo-btn veo-primary",class:{ 'tw-bg-gray-400': invalid || _vm.busy },attrs:{"disabled":invalid}},[_vm._v(" "+_vm._s(_vm.editMode ? "Edit" : "Create New")+" ")])]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }