<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-semibold tw-mb-3">Secondary Care Codes</h5>
    <table-component
      :items="items"
      :fields="fields"
      :busy="busy"
      @view="view"
      :perPage="perPage"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :paginationRange="count"
      :totalRecord="totalRows"
      :totalPages="pages"
      @page-changed="list"
    >
      <template #search>
        <search-filter
          @filter="filter"
          searchPlaceholder="Search secondary Care Code"
        />
      </template>
      <template #button>
        <button class="veo-btn veo-primary" @click="startGenerate">
          Generate
        </button>
      </template>
    </table-component>

    <!-- Generate Referral Code  -->
    <GenerateCode
      :visible="visible"
      code_type="secondary_care_code"
      title="Generate Secondary Care Code"
      @handleClose="startGenerate"
      @endGenerate="endGenerate"
    />

    <!-- View Generated Code  -->
    <view-code
      :visible="Object.keys(codeCreated).length !== 0"
      @handleClose="closeCode"
      :item="codeCreated"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import GenerateCode from "@/components/modals/codes/GenerateCode.vue";
import ViewCode from "@/components/modals/codes/ViewCode.vue";
import SearchFilter from "@/components/filters/SearchFilter.vue";
export default {
  components: { TableComponent, GenerateCode, ViewCode, SearchFilter },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "code",
          label: "Code",
        },
        {
          key: "user",
          label: "User",
          formatter: (val) => {
            return val === null ? "---" : `${val.first_name} ${val.last_name}`;
          },
        },
        {
          key: "hospital",
          label: "Hospital",
          formatter: (val) => {
            return val.name;
          },
        },
        // {
        //   key: "created_at",
        //   label: "Date Created",
        // },
      ],
      busy: false,
      visible: false,
      codeCreated: {},
      currentPage: 1,
      perPage: 0,
      totalRows: 0,
      count: 1,
      pages: 1,
    };
  },
  methods: {
    list(page = 1) {
      this.busy = true;
      this.$inventory
        .getAllSecondaryCareCodes(page)
        .then((res) => {
          console.log(res.data, "valid");
          this.items = res.data.items;
          let meta = res.data.meta.pagination;
          this.currentPage = meta.current_page;
          this.totalRows = meta.total;
          this.perPage = meta.per_page;
          this.count = meta.count;
          this.pages = meta.total_pages;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    startGenerate() {
      this.visible = !this.visible;
    },

    closeCode() {
      this.codeCreated = {};
    },

    endGenerate(e) {
      this.visible = !this.visible;
      this.list();
      this.codeCreated = e;
    },

    view(e) {
      this.$router.push(`/plans/${e.id}`);
    },

    filter(value) {
      console.log(value, "kkkk");
      this.busy = true;
      let payload = {
        search: value,
      };
      if (value === "") {
        this.list();
      } else {
        this.$inventory
          .searchSecondaryCareCode(payload)
          .then((res) => {
            let resPayload = res.data;
            console.log(resPayload, "ommo");
            this.items = resPayload;
            this.busy = false;
            return res;
          })
          .catch((err) => {
            console.log(err);
            this.busy = false;
          });
      }
    },
  },

  beforeMount() {
    this.list();
  },
};
</script>

<style></style>
