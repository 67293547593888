<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <div class="tw-flex tw-gap-4 tw-items-center tw-mb-4">
      <span
        class="tw-text-[13px] tw-px-2 tw-rounded-sm tw-py-[4px] tw-capitalize"
        @click="activateMenu(item)"
        v-for="(item, idx) in tabs"
        :key="idx"
        :class="{
          'tw-bg-primary-color tw-text-white tw-font-semibold': item === active,
        }"
        role="button"
        >{{ item.split("_").join(" ") }}</span
      >
    </div>
    <hr class="tw-my-2" />
    <div>
      <account-removal v-if="active === 'account_removal'" />
      <bonus-settings v-if="active === 'bonus'" />
      <wait-period v-if="active === 'wait_period'" />
    </div>
  </div>
</template>

<script>
import AccountRemoval from "@/components/settings/AccountRemoval.vue";
import BonusSettings from "@/components/settings/BonusSettings.vue";
import WaitPeriod from "@/components/settings/WaitPeriod.vue";

export default {
  components: { AccountRemoval, BonusSettings, WaitPeriod },
  data() {
    return {
      tabs: ["account_removal", "bonus", "wait_period"],
      active: "account_removal",
    };
  },

  methods: {
    activateMenu(item) {
      this.active = item;
    },
  },
};
</script>

<style></style>
