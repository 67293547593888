<template>
  <div>
    <div v-if="busy">
      <span>Retrieving data...</span>
    </div>
    <div v-else>
      <div
        class="tw-bg-[#fff] tw-py-8 tw-px-8 tw-scroll-smooth tw-border-0 tw-rounded-md tw-mb-5"
        style=""
      >
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-flex-col tw-gap-3">
            <div
              class="tw-flex tw-gap-1 tw-items-center"
              v-for="(value, name) in info"
              :key="name"
            >
              <span class="tw-capitalize tw-font-light tw-text-sm"
                >{{ name.split("_").join(" ") }}:</span
              >
              <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                value
              }}</span>
            </div>
            <span
              class="status"
              :class="
                item.remote_status == '0'
                  ? 'pending'
                  : item.remote_status == '1'
                  ? 'completed'
                  : 'canceled'
              "
            >
              {{
                item.remote_status === "0"
                  ? "received"
                  : item.remote_status === "1"
                  ? "accepted"
                  : "rejected"
              }}
            </span>
          </div>
          <div>
            <div v-if="item.payment_status === '0' && item.remote_status !== '2'">
              <div v-if="item.remote_status === '0'" class="tw-flex tw-gap-2">
                <button class="veo-btn veo-primary" @click="invoiceFunc('accept')">Accept Invoice</button>
                <button class="veo-btn veo-primary tw-bg-red-600" @click="invoiceFunc('reject')">Reject Invoice</button>
              </div>
              <div v-else>
                <button class="veo-btn veo-primary" @click="makePayment">Make Payment</button>
              </div>
            </div>
            <span v-else class="tw-px-4 tw-py-1 tw-rounded-md tw-text-[12px] tw-font-medium tw-text-gray-200 tw-w-fit tw-bg-gray-400 tw-block">
              Done
            </span>
          </div>
        </div>
      </div>
      <div class="tw-bg-white tw-rounded-md tw-p-8">
        <h5 class="mb-3">Claims</h5>
        <el-collapse accordion>
          <!-- {{ invoice.claims }} -->
          <el-collapse-item
            v-for="(item, index) in item.claims"
            :key="index"
            :name="index + 1"
          >
            <template slot="title">
              <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                item.created_at | displayDate
              }}</span>
            </template>
            <div>
              <div class="tw-flex tw-flex-col tw-gap-2">
                <div class="tw-flex tw-gap-1 tw-items-center">
                  <span class="tw-capitalize tw-font-light tw-text-sm"
                    >Care Type:</span
                  >
                  <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                    item.care_type
                  }}</span>
                </div>
                <div class="tw-flex tw-gap-1 tw-items-center">
                  <span class="tw-capitalize tw-font-light tw-text-sm"
                    >Amount:</span
                  >
                  <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                    item.total_amount | currencyFormat
                  }}</span>
                </div>
                <div class="tw-flex tw-gap-1 tw-items-center">
                  <span class="tw-capitalize tw-font-light tw-text-sm"
                    >Remarks:</span
                  >
                  <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                    item.remarks
                  }}</span>
                </div>
              </div>
            </div>

            <MainTab
              :tabs="tabs"
              :currentTab="activeTab"
              :currentName="activeName"
            >
              <template #default="{ activeTab }">
                <component
                  :is="tabs[activeTab].component"
                  :visit="item.visits"
                  :items="item.items"
                />
              </template>
            </MainTab>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import MainTab from "@/components/utils/MainTab.vue";

// Components
// import UserDetailsVue from "@/components/claims/UserDetails.vue";
import VisitDetailsVue from "@/components/claims/VisitDetails.vue";
import ItemsListVue from "@/components/claims/ItemsList.vue";
import UserHeader from "@/components/utils/UserHeader.vue";

export default {
  components: {
    MainTab,
    UserHeader,
  },
  data() {
    return {
      activeTab: 0,
      activeName: "visit",
      tabs: [
        {
          id: 1,
          title: "Visit",
          component: VisitDetailsVue,
          name: "visit",
        },
        {
          id: 2,
          title: "Items",
          component: ItemsListVue,
          name: "items",
        },
      ],
      item: {},
      id: this.$route.params.id,
      visit: {},
      items: [],
      busy: false,
    };
  },
  methods: {
    getItem() {
      this.busy = true;
      this.$finance
        .getInvoice(this.id)
        .then((res) => {
          console.log(res);
          this.item = res.data;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    invoiceFunc(value){
      this.busy = true;
      this.$finance
        .invoiceFunc(this.id, value)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: `Invoice ${value}ed`,
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.getItem()
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
          this.$toastify({
            text: `Error`,
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        });
    },

    makePayment(){
      this.busy = true;
      this.$finance
        .makePayment(this.id)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: `Invoice Payment Completed`,
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
          this.getItem()
        })
        .catch((err) => {
          this.busy = false;
          this.$toastify({
            text: `Something went wrong`,
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          console.log(err);
        });
    }
  },

  beforeMount() {
    this.getItem();
  },

  computed: {
    info() {
      let data = {
        hospital: this.item.hospital_name,
        claim_period: this.item.claim_period,
        total_amount: Number(this.item.total_amount).toLocaleString("en-US", {
          style: "currency",
          currency: "NGN",
        }),
      };
      return data;
    },
  },
};
</script>
<style>
.el-collapse-item__header {
  height: unset !important;
  padding: 12px 0;
}
.el-tabs__item {
  color: var(--dark-100) !important;
  font-weight: 400;
  font-size: 12.7px;
}

.el-tabs__item.is-active {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.el-tabs__active-bar {
  background-color: var(--primary-color) !important;
}
</style>
