<template>
  <div>
    <table-component :fields="fields" :items="items" :paginate="false" :showBaseCount="false"/>
  </div>
</template>

<script>
import TableComponent from "../TableComponent.vue";
export default {
  components: { TableComponent },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        {
          key: "itemable.detail.name",
          label: "Item Name",
          formatter: (item) => {
            return item === null ? "---" : item === "" ? "---" : item;
          },
        },
        {
          key: "itemable.detail.description",
          label: "Description",
          formatter: (item) => {
            return item === null ? "---" : item === "" ? "---" : item;
          },
        },
        {
          key: "itemable.rate",
          label: "Rate",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
        {
          key: "itemable.quantity",
          label: "Quantity",
          formatter: (item) => {
            return item === null ? "---" : item === "" ? "---" : item;
          },
        },
        {
          key: "itemable.duration",
          label: "Duration",
          formatter: (item) => {
            return item === null ? "---" : item === "" ? "---" : item;
          },
        },
        {
          key: "itemable.amount",
          label: "Total Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
      ],
    };
  },
};
</script>

<style></style>
