<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase">
      Add New Corporate Body
    </h5>
    <validation-observer v-slot="{ invalid, handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        class="tw-flex tw-flex-col tw-gap-4"
      >
        <span
          v-if="Object.keys(validationErrors).length > 0"
          class="tw-mb-5 tw-block tw-text-xs tw-text-error"
          >{{ validationErrors }}</span
        >
        <div class="tw-w-full">
          <validation-provider
            name="name"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="name">Name of Corporate Body</label>
            <input
              type="text"
              name="text"
              id="input"
              v-model="name"
              placeholder="Enter Name of corporate body"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.name"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="email"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Email Address</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="email"
                placeholder="email"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.email"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="phone number"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Phone Number</label>
              <input
                type="tel"
                name="text"
                id="input"
                v-model="phone_number"
                placeholder="Enter Phone Number"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.phone_number"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="country"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="username">Country</label>
              <country-select
                class="tw-w-full tw-block"
                :countryName="true"
                v-model="country"
                :country="country"
                topCountry="NG"
                id="select"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.country"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <div class="tw-w-full">
            <validation-provider
              name="state"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="username">Select State </label>
              <region-select
                class="tw-w-full tw-block"
                id="select"
                v-model="state"
                :country="country"
                :region="state"
                :countryName="true"
                :regionName="true"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.state"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="city"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">City</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="city"
                placeholder="Enter City"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.city"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="address"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Address</label>
            <textarea
              id="input"
              v-model="address"
              placeholder="address"
              cols="30"
              rows="5"
              :class="{
                error: dirty && invalid,
              }"
            >
            </textarea>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.address"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="description"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Description</label>
            <textarea
              id="input"
              v-model="description"
              placeholder="description"
              cols="30"
              rows="5"
              :class="{
                error: dirty && invalid,
              }"
            >
            </textarea>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.description"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="password"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Password</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="password"
                placeholder="Enter Password"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.password"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <!-- <div class="tw-w-full">
            <validation-provider
              name="confirm password"
              rules="required|confirmed:password"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Confirm Password</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="password_confirmation"
                placeholder="Confirm Password"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.password_confirmation"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div> -->
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="profile photo"
            rules="photo"
            v-slot="{ errors }"
          >
            <label for="username">Profile Photo</label>
            <input @change="onFileChange" type="file" id="input" />
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.profile_photo"
              :key="error"
              >*{{ error }}</small
            >
          </validation-provider>
        </div>

        <div class="tw-mt-4 tw-text-center">
          <!-- <button
            class="veo-btn veo-primary tw-w-5/12"
          >
            <span>Add Enrollee</span>
          </button> -->

          <button
            class="veo-btn veo-primary tw-w-5/12"
            v-bind:disabled="invalid || busy"
            :class="{ 'tw-bg-gray-400': invalid || busy }"
          >
            <span>{{ busy ? "..." : "Create Corporate Body" }}</span>
          </button>
        </div>
      </form>
    </validation-observer>

    <!-- Success Modal When Created  -->
    <el-dialog
      title="Tips"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :show-title="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="tw-flex tw-flex-col tw-gap-2 tw-items-center">
        <i-icon
          icon="charm:circle-tick"
          class="tw-text-[80px] tw-text-primary-color"
        />
        <span class="tw-text-lg tw-font-bold tw-text-center"
          >Corporate Body Created <br />Successfully</span
        >
      </div>

      <span
        slot="footer"
        class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
      >
        <button
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-px-2 tw-bg-blue-500"
          @click="resetForm"
        >
          Create New
        </button>
        <button
          @click="view"
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-info tw-px-2"
        >
          View Corporate Body
        </button>
        <button
          @click="$router.go(-1)"
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-success tw-px-2"
        >
          Go Back
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataObj: {},
      name: "",
      email: "",
      password: "",
      address: "",
      phone_number: "",
      country: "",
      state: "",
      city: "",
      profile_picture: "",
      description: "",
      dialogVisible: false,
      validationErrors: {},
      busy: false,
      query: this.$route.query,
    };
  },

  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("country", this.country);
      formData.append("state", this.state);
      formData.append("lga", this.city);
      formData.append("address", this.address);
      formData.append("profile_picture", this.profile_picture);
      formData.append("password", this.password);
      formData.append("description", this.description);
      this.busy = true;
      (Object.keys(this.query).length > 0) ? this.updateCorporate(formData) : this.createCorporate(formData)
    },
    
    createCorporate(val){
      this.$users
        .createCorporateBody(val)
        .then((res) => {
          console.log(res, "valid");
          this.dialogVisible = true;
          this.busy = false;
          this.enrollee = res.user;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.validationErrors = err.data.errors;
        });
    },

    updateCorporate(val){
      this.$users
        .updateCorporateCopy(val, this.$query.ref)
        .then((res) => {
          console.log(res, "valid");
          this.$toastify({
            text: "Corporate Body updated successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.$toastify({
            text: "Corporate body not Updated",
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.validationErrors = err.data.errors;
        });
    },

    onFileChange(e) {
      var val = e.target.files[0];
      this.profile_picture = val;
    },

    view() {
      this.$router.push(`/enrollee/view/${this.enrollee.id}`);
    },

    resetForm() {
      this.handleClose();
    },

    handleClose() {
      this.dialogVisible = false;
    },

    getCorporateBody(e) {
      this.$users
        .getCorporate(e)
        .then((res) => {
          let resPayload = res.data;
          this.dataObj = resPayload;
          console.log(resPayload);
          // this.busy = false;
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    query: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          console.log(val, "ommmo");
          this.getCorporateBody(val.ref);
        }
      },
      immediate: true,
    },

    dataObj: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.name = val.name;
          this.email = val.email;
          this.password = val.password;
          this.address = val.address;
          this.phone_number = val.phone_number;
          this.country = val.country;
          this.state = val.state;
          this.city = val.city;
          this.profile_picture = val.profile_photo;
          this.description = val.description;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* El Dialog  */
.el-dialog__header {
  display: none;
}
</style>
