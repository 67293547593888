<template>
  <div>
    <label class="tw-text-sm" for="">Enter Wait Period (In days)</label>
    <input id="input" type="text" v-model="form.duration" />
    <div class="tw-mx-auto tw-text-center">
      <button
        class="veo-btn veo-primary tw-w-5/12 tw-mt-3 tw-mx-auto"
        :class="{ 'tw-bg-gray-400': busy }"
        @click="updateWaitPeriod"
      >
        <span>{{ busy ? "..." : "Update Wait Period" }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      busy: false,
      form: {
        duration: "",
      },
    };
  },
  methods: {
    fetchRecords() {
      this.$config.fetchWaitPeriodRecords().then((res) => {
        console.log(res);
        this.form = res.data[0];
      });
    },

    updateWaitPeriod() {
      this.busy = true;
      let payload = {
        duration: this.form.duration,
      };
      this.$config
        .updateWaitPeriodRecords(payload, this.form.id)
        .then((res) => {
          console.log(res);
          this.$swal.fire("Done!", "Wait Period Updated.", "success");
        })
        .catch(()=> {
            this.$swal.fire("Oops!", "Wait Period Not updated.", "error");
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },

  beforeMount() {
    this.fetchRecords();
  },
};
</script>

<style></style>
