// import Vue from 'vue';
import axios from '@/plugins/axios'
import createPersistedState from 'vuex-persistedstate';


import "toastify-js/src/toastify.css"
import Toastify from 'toastify-js'

// Vue.prototype.$http = http

const getDefaultState = () => {
    return {
        allCorporate: [],
        corporate: {},
        loading: false,
        isSuccessful: false
    };
};

export default {
    namespaced: true,
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        getAllCorporate: state => {
            return state.allCorporate
        },
        getCorporate: state => {
            return state.corporate
        },
        isLoading: state => {
            return state.loading
        },
        isSuccessful: state => {
            return state.isSuccessful
        }
    },

    mutations: {
        SET_ALL_CORPORATE: (state, allCorporate) => {
            state.allCorporate = allCorporate;
        },
        SET_CORPORATE: (state, corporate) => {
            state.corporate = corporate;
        },
        SET_LOADING_STATUS: (state, data) => {
            state.loading = data
        },
        SET_SUCCESS_STATUS: (state, data) => {
            state.isSuccessful = data
        },
    },

    actions: {
        // Get Hospitals
        async getAllCorporate({ commit }) {
            const res = await axios.get('/admin/corporate')
            commit('SET_ALL_CORPORATE', res.data);
            console.log(res);
        },

        // Create New Hospital 
        createCorporateBody({ commit, dispatch }, payload) {
            commit('SET_LOADING_STATUS', true)
            axios.post('/admin/corporate/add', payload)
                .then((res) => {
                    dispatch('getAllCorporate')
                    console.log(res);
                    commit('SET_SUCCESS_STATUS', true)
                    Toastify({
                        text: "Corporare body Created",
                        className: "info",
                        style: {
                            background: "green",
                            fontSize: '12px',
                            borderRadius: '3px'
                        }
                    }).showToast();
                })
                .catch((err) => {
                    console.log(err);
                    Toastify({
                        text: "Something ent wrong",
                        className: "info",
                        style: {
                            background: "red",
                            fontSize: '12px',
                            borderRadius: '3px'
                        }
                    }).showToast();
                })
                .finally(() => {
                    commit('SET_LOADING_STATUS', false)
                })

        },

        // Delete corporate Body

        deleteCorporateBody({ commit, dispatch }, id) {
            commit('SET_LOADING_STATUS', true)
            axios.get(`/admin/corporate/delete/${id}`)
                .then((res) => {
                    dispatch('getAllCorporate')
                    console.log(res);
                    Toastify({
                        text: "Corporare body Deleted",
                        className: "info",
                        style: {
                            background: "green",
                            fontSize: '12px',
                            borderRadius: '3px'
                        }
                    }).showToast();
                })
                .catch((err) => {
                    console.log(err);
                    Toastify({
                        text: "Something went wrong",
                        className: "info",
                        style: {
                            background: "red",
                            fontSize: '12px',
                            borderRadius: '3px'
                        }
                    }).showToast();
                })
                .finally(() => {
                    commit('SET_LOADING_STATUS', false)
                })

        },

        // Edit Corporate Body
        async editCorporateBody({ dispatch, commit }, payload) {
            commit('SET_LOADING_STATUS', true)
            axios.post(`/admin/corporate/update/${payload.id}`, payload.data)
                .then((res) => {
                    Toastify({
                        text: "Corporate Body Updated",
                        className: "info",
                        style: {
                            background: "green",
                            fontSize: "12px",
                            borderRadius: "5px"
                        }
                    }).showToast();
                    commit('SET_SUCCESS_STATUS', true)
                    return res;
                })
                .catch((err) => {
                    Toastify({
                        text: "Something went wrong",
                        className: "info",
                        style: {
                            background: "red",
                            fontSize: "12px",
                            borderRadius: "5px"
                        }
                    }).showToast();
                    return err
                })
                .finally(() => {
                    commit('SET_LOADING_STATUS', false)
                    dispatch('getCorporate', payload.id)
                })

        },

        // Get Corporate Body
        async getCorporate({ commit }, id) {
            const res = await axios.get(`/admin/corporate/view/${id}`)
            commit('SET_CORPORATE', res.data);
            console.log(res);
        },

        // Add Staff
        async addStaff({ dispatch }, payload) {
            try {
                const res = await axios.post(`/corporate/staff/add`, payload.formData)
                dispatch('getCorporate', payload.id);
                Toastify({
                    text: "User Created",
                    className: "info",
                    style: {
                        background: "green",
                    }
                }).showToast();
                console.log(res)
            } catch (error) {
                Toastify({
                    text: "Error, retry",
                    className: "info",
                    style: {
                        background: "red",
                    }
                }).showToast();
                return error
            }
        },

        // Remove Success Modal  
        removeSuccessModal({ commit }) {
            commit("SET_SUCCESS_STATUS", false)
        }
    }
};