<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-semibold tw-mb-3">Referral Codes</h5>
    <table-component
      :items="items"
      :fields="fields"
      :busy="busy"
      @view="view"
      :paginate="false"
      :showBaseCount="false"
    >
      <template #button>
        <button class="veo-btn veo-primary" @click="startGenerate">
          Generate
        </button>
      </template>
    </table-component>

    <!-- Generate Referral Code  -->
    <GenerateCode
      :visible="visible"
      code_type="refferal_code"
      title="Generate Referral Code"
      @handleClose="startGenerate"
      @endGenerate="endGenerate"
    />

    <!-- View Generated Code  -->
    <view-code
      :visible="Object.keys(codeCreated).length !== 0"
      @handleClose="closeCode"
      :item="codeCreated"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import GenerateCode from "@/components/modals/codes/GenerateCode.vue";
import ViewCode from "@/components/modals/codes/ViewCode.vue";
export default {
  components: { TableComponent, GenerateCode, ViewCode },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "code",
          label: "Code",
        },
        {
          key: "user",
          label: "User",
          formatter: (val) => {
            return val === null ? "---" : `${val.first_name} ${val.last_name}`;
          },
        },
        {
          key: "hospital",
          label: "Hospital",
          formatter: (val) => {
            return val.name;
          },
        },
        {
          key: "created_at",
          label: "Date Created",
        },
      ],
      busy: false,
      visible: false,
      codeCreated: {},
    };
  },
  methods: {
    list(page = 1) {
      this.busy = true;
      this.$inventory
        .getAllReferralCodes(page)
        .then((res) => {
          console.log(res.data, "valid");
          this.items = res.data;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    startGenerate() {
      this.visible = !this.visible;
    },

    closeCode() {
      this.codeCreated = {};
    },

    endGenerate(e) {
      this.visible = !this.visible;
      this.list();
      this.codeCreated = e;
    },

    view(e) {
      this.$router.push(`/plans/${e.id}`);
    },
  },

  beforeMount() {
    this.list();
  },
};
</script>

<style></style>
