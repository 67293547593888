<template>
    <div class="tw-bg-white tw-rounded-md tw-p-8">
      <table-component
        :items="items"
        :busy="busy"
        :fields="fields"
        @delete="deleteRecord"
        @edit="recordFunc($event, 'edit')"
        :disableViewAction="true"
        :perPage="perPage"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :paginationRange="count"
      :totalRecord="totalRows"
      :totalPages="pages"
      @page-changed="list"
      >
        <template #search>
          <search-filter
            @filter="filter"
            searchPlaceholder="Search Services"
            @refresh="list"
          />
        </template>
        <template #button>
          <button class="veo-btn veo-primary" @click="recordFunc('create')">
            Add New Service
          </button>
        </template>
      </table-component>
  
      <!-- Add Drug Price -->
      <add-service
        :visible="visible"
        @handleClose="closeModal"
        :editMode="editMode"
        :title="title"
        :item="item"
        @completeAdd="completeAdd"
      />
    </div>
  </template>
  
  <script>
  import TableComponent from "@/components/TableComponent.vue";
  import AddService from "@/components/modals/inventory/ServicesModal.vue";
  import SearchFilter from "@/components/filters/SearchFilter.vue";
  export default {
    components: { TableComponent, AddService, SearchFilter },
    data() {
      return {
        items: [],
        busy: false,
        fields: [
          {
            key: "name",
            label: "Service Name",
          },
          {
            key: "description",
            label: "Description",
          },
          {
            key: "actions",
            label: "",
          },
        ],
        visible: false,
        viewMode: false,
        editMode: false,
        title: null,
        item: {},
        currentPage: 1,
        perPage: 0,
        totalRows: 0,
        count: 1,
        pages: 1,
      };
    },
  
    methods: {
      list(page = 1) {
        this.busy = true;
        this.$inventory
          .getServices(page)
          .then((res) => {
            let resPayload = res.data;
            this.items = resPayload.items;
            console.log(resPayload);
            let meta = resPayload.meta.pagination;
          this.currentPage = meta.current_page;
          this.totalRows = meta.total;
          this.perPage = meta.per_page;
          this.count = meta.count
          this.pages = meta.total_pages
            this.busy = false;
            return res;
          })
          .catch((err) => {
            console.log(err);
            this.busy = false;
          });
      },
  
      completeAdd() {
        this.visible = !this.visible;
        this.list();
      },
  
      closeModal() {
        this.visible = !this.visible;
        this.item = {};
        this.editMode = false;
        this.title = null;
      },
  
      recordFunc(e, value) {
        console.log(value, e, "kkkk");
        if (value === "create") {
          this.visible = !this.visible;
          this.title = "Create New Service";
          this.editMode = false;
        } else if (value === "edit") {
          this.visible = !this.visible;
          this.item = e;
          this.editMode = true;
          this.title = `Update ${e.name}`;
        } else {
          this.visible = !this.visible;
          this.editMode = false;
          this.title = "Create New Service";
        }
      },
  
      complete(value) {
        this.$inventory
          .deleteService(value.id)
          .then((res) => {
            this.$swal.fire("Deleted!", "Service deleted succesfully.", "success");
            this.list();
            return res;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      deleteRecord(value) {
        this.$swal
          .fire({
            title: "Are you Sure? 🤔",
            text: "This action is irreversible!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            console.log(result, "kkk");
            if (result.isConfirmed) {
              this.complete(value);
            }
          });
      },
  
      filter(value) {
        console.log(value, "kkkk");
        this.busy = true;
        let payload = {
          name: value,
        };
        if (value === "") {
          this.list();
        } else {
          this.$inventory
            .searchServices(payload)
            .then((res) => {
              let resPayload = res.data;
              console.log(resPayload, "ommo");
              this.items = resPayload;
              this.busy = false;
              return res;
            })
            .catch((err) => {
              console.log(err);
              this.busy = false;
            });
        }
      },
    },
  
    beforeMount() {
      this.list();
    },
  };
  </script>
  
  <style></style>
  