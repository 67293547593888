<template>
  <div>
    <div v-if="busy">
      <span>Retrieving data...</span>
    </div>
    <div v-else>
      <user-header
        :title="title"
        :subTitle="subTitle"
        :picture="picture"
        :displayKey="[
          'unique_id',
          'phone_number',
          'wallet_address',
          'referral_code',
          'lga',
          'state',
          'country',
          'address',
        ]"
        :data="userData"
        :hasStatus="true"
        :item="user"
        :countdown="countdown"
        @activateFunc="activateFunc"
      >
      <template #card-icons>
          <div class="tw-flex tw-gap-2 tw-items-center">
            <span class="tw-flex tw-flex-col">
              <span class="tw-font-light tw-text-[11px] tw-underline"
                >Wallet Balance:</span
              >
              <span
                class="tw-font-semibold"
                :class="{ 'tw-text-red-600': user.wallet.balance === 0 }"
                >{{ user.wallet.balance | currencyFormat }}</span
              >
            </span>
            <div class="tw-mx-1">
              <el-divider direction="vertical"></el-divider>
            </div>
            <button
              @click="withdraw"
              class="veo-btn veo-primary"
              :disabled="user.wallet.balance === 0"
              :class="{
                'tw-bg-gray-600 tw-text-gray-200': user.wallet.balance === 0,
              }"
            >
              Withdraw
            </button>
            <button @click="depositVisible = !depositVisible" class="veo-btn veo-primary">
              Deposit
            </button>
          </div>
        </template>

        <template #rightBody>
          <div>
            <h6 class="tw-font-semibold tw-text-sm tw-underline">
              Bank Details
            </h6>
            <div v-if="bankDetails === null">
              <span class="tw-text-[12px] tw-text-red-600 tw-block">
                Oops! 🤔 <br />
                Bank details do not exist for this user
              </span>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-3" v-else>
              <span
                class="tw-flex tw-flex-col"
                v-for="(value, name) in bankDetails"
                :key="name"
              >
                <span
                  class="tw-text-[11px] tw-font-medium tw-text-gray-400 tw-uppercase"
                  >{{ name.split("_").join(" ") }}:</span
                >
                <span class="tw-text-xs tw-font-[13px]">{{ value }}</span>
              </span>
            </div>
          </div>
        </template>
      </user-header>
      <div class="tw-bg-white tw-rounded-md tw-p-8">
        <MainTab :tabs="tabs" :currentTab="activeTab" :currentName="activeName">
          <template #default="{ activeTab }">
            <component
              :is="tabs[activeTab].component"
              :items="items"
              :referrals="referrals"
              @addPlan="planVisible = !planVisible"
            />
          </template>
        </MainTab>
      </div>
    </div>

    <!-- Withdraw Funds  -->
    <withdraw-funds
      :visible="isVisible"
      @handleClose="isVisible = !isVisible"
      :item="item"
      @completeWithdrawal="isComplete"
    />

    <!-- Add Plan to User  -->
    <add-user-plan
      :visible="planVisible"
      @handleClose="planVisible = !planVisible"
      @isComplete="completeAddPlan"
    />

    <!-- Credit User  -->
    <credit-user :visible="depositVisible"
      @handleClose="depositVisible = !depositVisible"
      @completeAdd="completeCreditUser"/>
  </div>
</template>

<script>
import UserHeader from "@/components/utils/UserHeader.vue";

import WithdrawFunds from "@/components/modals/users/WithdrawFunds.vue";
import AddUserPlan from "@/components/modals/users/AddUserPlan.vue";

import MainTab from "@/components/utils/MainTab.vue";
import EnrolleePlansVue from '@/components/users/EnrolleePlans.vue';
import EnrolleeReferralsVue from '@/components/users/EnrolleeReferrals.vue';
import CreditUser from '@/components/modals/users/CreditUser.vue';
export default {
  components: {
    UserHeader,
    WithdrawFunds,
    AddUserPlan,

    MainTab,
    CreditUser
  },

  data() {
    return {
  activeTab: 0,
      items: [],
      id: this.$route.params.id,
      user: {},
      busy: false,
      bank: null,
      userData: {
        unique_id: "",
        phone_number: "",
        wallet_address: "",
        referral_code: "",
        lga: "",
        state: "",
        country: "",
        address: "",
      },
      isVisible: false,
      item: {},

      planVisible: false,
      countdown: "",

      activeName: "plan-details",
      tabs: [
        {
          id: 1,
          title: "Plan Details",
          component: EnrolleePlansVue,
          name: "plan-details",
        },
        {
          id: 2,
          title: "Referrals",
          component: EnrolleeReferralsVue,
          name: "referrals",
        },
      ],

      referrals: [],

      depositVisible: false
    };
  },

  methods: {
    getData() {
      this.busy = true;
      this.$users
        .getEnrollee(this.id)
        .then((res) => {
          console.log(res.data, "valid");
          this.user = res.data;
          this.bank = res.data.bank_detail;
          this.items = res.data.plan;
          this.referrals = res.data.referrals
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    withdraw() {
      this.isVisible = !this.isVisible;
      this.item = this.user;
    },

    completeAddPlan() {
      this.getData();
      this.planVisible = !this.planVisible;
    },

    isComplete() {
      this.getData();
      this.isVisible = !this.isVisible;
    },

    completeCreditUser(){
      this.getData();
      this.depositVisible = !this.depositVisible
    },

    activateFunc() {
      this.busy = true;
      this.$users
        .activateUser(this.id)
        .then((res) => {
          this.busy = false;
          this.getData();
          this.$toastify({
            text: "User Activated Successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },
  },

  watch: {
    user: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.userData = {
            unique_id: val.unique_id,
            phone_number: val.phone_number,
            wallet_address: val.wallet.wallet_address,
            referral_code: val.ref_code,
            lga: val.city,
            state: val.state,
            country: val.country,
            address: val.address,
          };
          // countdown() {
          var start = new Date();
          var end = new Date(this.user.due_date);
          var time_difference = end.getTime() - start.getTime();
          var days_left = time_difference / (3600 * 1000 * 24);
          var total_days_left = Math.floor(days_left);
          this.countdown = total_days_left <= 0 ? "0" : Number(total_days_left);
          console.log(this.countdown);
        }
      },
      immediate: true,
    },
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    title() {
      return this.user.first_name + " " + this.user.last_name;
    },
    subTitle() {
      return this.user.email;
    },
    picture() {
      return this.user.profile_picture;
    },
    bankDetails() {
      if (this.bank !== null) {
        let data = {
          account_number: this.bank.account_no,
          bank_name: this.bank.bank_name,
          account_name: this.bank.account_name,
        };
        return data;
      } else {
        return null;
      }
    },
  },
};
</script>

<style></style>
