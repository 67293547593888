// import Vue from 'vue';
import axios from "@/plugins/axios";
import createPersistedState from "vuex-persistedstate";

// Vue.prototype.$http = http

const getDefaultState = () => {
  return {
    refferalCodes: [],
    secondaryCare: {},
    metaData: {},
  };
};

export default {
  namespaced: true,
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    getReferralCodes: (state) => {
      return state.referralCodes;
    },
    getSecondaryCodes: (state) => {
      return state.secondaryCare;
    },
  },
  mutations: {
    SET_REFERRAL: (state, payload) => {
      state.referralCodes = payload;
      // state.metaData = payload.meta.pagination
    },

    SET_SECONDARY: (state, payload) => {
      state.secondaryCare = payload.items;
      state.metaData = payload.meta.pagination;
    },

    SET_SECONDARY_FILTER: (state, payload) => {
      state.secondaryCare = payload;
    },
  },
  actions: {
    // Get Referral Codes
    async getReferral({ commit }, page) {
      const res = await axios.get("/admin/referral/code/all?page=" + page);
      let resPayload = res.data.data;

      // console.log(resPayload);
      commit("SET_REFERRAL", resPayload);
    },

    // Get Secondary Codes
    async getSecondary({ commit }, page) {
      const res = await axios.get("/admin/secondarycare/code/all?page=" + page);
      let resPayload = res.data.data;
      commit("SET_SECONDARY", resPayload);
      console.log(res);
    },

    // Search Secondary Care Code
    async searchSecondary({ commit }, payload) {
      const res = await axios.post(
        "/admin/secondarycare/code/search",
        payload.search
      );
      let resPayload = res.data.data;
      commit("SET_SECONDARY_FILTER", resPayload);
      console.log(res);
    },

    // Create New Hospital
    async createCorporateBody({ dispatch }, payload) {
      const res = await axios.post("/admin/corporate/add", payload);
      dispatch("getAllCorporate");
      console.log(res);
    },

    // Get Single Hospital
    async getCorporate({ commit }, id) {
      const res = await axios.get(`/admin/corporate/view/${id}`);
      commit("SET_CORPORATE", res.data);
      console.log(res);
    },
  },
};
