<template>
    <div>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <validation-observer v-slot="{ invalid, handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            class="tw-flex tw-flex-col tw-gap-4"
          >
            <div>
              <validation-provider
                name="group_name"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Group Name</label>
                <input
                type="text"
                id="input"
                placeholder="Enter Group Name"
                v-model="payload.name"
                :class="{
                  error: dirty && invalid,
                }"
              />
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
  
            
            <span
              v-if="!viewMode"
              slot="footer"
              class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
            >
              <button
                class="veo-btn veo-primary"
                :disabled="invalid"
                :class="{ 'tw-bg-gray-400': invalid || busy }"
              >
                {{ editMode ? "Edit" : "Create New" }}
              </button>
            </span>
          </form>
        </validation-observer>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      viewMode: {
        type: Boolean,
        default: false,
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: "",
      },
      item: {
        type: Object,
        default: () => {},
      },
      code_type : {
        type: String,
        default: "referral_code"
      }
    },
    data() {
      return {
        dialogVisible: false,
        items: [],
        hospitals: [],
        payload: {
          name: null,
        },
        busy: false,
      };
    },
    methods: {
      onSubmit() {
        let formData = new FormData();
        formData.append("name", this.payload.name);
        this.busy = true;
        this.editMode ? this.updateGroups(formData) : this.addGroups(formData)
      },

      addGroups(value){
      this.$users
        .addGroup(value)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: "Group created successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
          this.$emit("completeAdd");
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.$toastify({
            text: "Group not created",
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        });
    },

    updateGroups(value){
      this.$users
        .updateGroup(this.item.id, value)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: "Group Updated successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
          this.$emit("completeAdd");
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.$toastify({
            text: "Group not updated",
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        });
    },

      
      handleClose() {
        this.$emit("handleClose");
      },

    },
    watch: {
      visible: {
        handler(val) {
          this.dialogVisible = val;
        },
        immediate: true,
      },

      item: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.payload = {
            name: val.name
          };
        }
      },
      immediate: true,
    },
    },
  };
  </script>
  
  <style></style>
  