<template>
  <div>
    <el-dialog
      title="Withdraw for User"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <validation-observer v-slot="{ invalid, handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="tw-flex tw-flex-col tw-gap-4"
        >
          <div>
            <validation-provider
              name="bank_name"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="">Bank Name</label>
              <v-select
                v-model="bank"
                :options="banks"
                label="name"
                :class="{
                  error: dirty && invalid,
                }"
                placeholder="Select Bank"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><i-icon
                      icon="radix-icons:caret-down"
                      color="#4F4F4F"
                      width="18px"
                  /></span>
                </template>
              </v-select>
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div>
            <validation-provider
              name="account number"
              rules="required|digits:10|max:10"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="">Account Number</label>
              <input
                type="text"
                id="input"
                placeholder="Enter Account Number"
                v-model="account_no"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div>
            <validation-provider
              name="Account_name"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <div class="tw-flex tw-justify-between">
                <label for="">Account Name</label>
                <span v-if="isChecking" class="tw-text-primary-color">
                  <i-icon icon="line-md:loading-loop" />
                </span>
              </div>
              <input
                type="text"
                id="input"
                placeholder="Enter Account Name"
                v-model="account_name"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div>
            <validation-provider
              name="amount"
              :rules="`required|max_value:${item.wallet.balance}`"
              v-slot="{ dirty, invalid, errors }"
            >
              <div class="tw-flex tw-justify-between">
                <label for="">Amount</label>
                <span
                  v-if="isMore"
                  class="tw-text-red-600 tw-flex tw-items-center"
                >
                  <i-icon icon="bx:error" />
                  <span class="tw-text-[9px]">Insufficient Funds</span>
                </span>
              </div>
              <input
                type="text"
                id="input"
                placeholder="Amount"
                v-model="amount"
                :class="{
                  error: dirty && invalid,
                  'tw-border-red-600': isMore,
                }"
              />
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <span
            v-if="!viewMode"
            slot="footer"
            class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
          >
            <button
              class="veo-btn veo-primary"
              :disabled="invalid"
              :class="{ 'tw-bg-gray-400': invalid || busy }"
            >
              Withdraw
            </button>
          </span>
        </form>
      </validation-observer>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {},
    },
    code_type: {
      type: String,
      default: "referral_code",
    },
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      hospitals: [],
      payload: {
        name: null,
      },
      busy: false,
      banks: [],
      bank: null,
      account_no: null,
      account_name: null,
      isChecking: false,
      amount: null,
      isMore: false,
      id: this.$route.params.id
    };
  },
  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("account_name", this.account_name);
      formData.append("account_no", this.account_no);
      formData.append("amount", this.amount);
      formData.append("bank_name", this.bank.name);
      this.busy = true;
      this.$users
        .withdraw(this.id, formData)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: res.data,
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
          this.$emit("completeWithdrawal");
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.$toastify({
            text: err.data.message,
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        });
    },

    getBanks() {
      this.$http.get("https://api.paystack.co/bank").then((res) => {
        console.log(res, "mmmm");
        this.banks = res.data.data;
      });
    },

    resolveAccountNumber(val) {
      this.isChecking = true;
      const token = "sk_live_bd5a44ffe02dbf993919572d731c8aeab16fca97";
      this.$http
        .get(
          `https://api.paystack.co/bank/resolve?account_number=${val}&bank_code=${this.bank.code}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          this.isChecking = false;
          this.account_name = res.data.data.account_name;
        })
        .catch((err) => {
          this.isChecking = false;
          if (this.account_no.length === 10) {
            console.log(err.response.data.message);
            this.$toastify({
              text: err.response.data.message,
              className: "info",
              style: {
                background: "red",
                fontSize: "12px",
                borderRadius: "5px",
              },
            }).showToast();
          }
          this.account_name = null;
        });
    },

    handleClose() {
      this.$emit("handleClose");
    },
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },

    account_no: {
      handler: debounce(function () {
        this.resolveAccountNumber(this.account_no);
      }, 500),
    },

    bank: {
      handler: debounce(function () {
        this.resolveAccountNumber(this.account_no);
      }, 500),
    },

    amount: {
      handler: debounce(function () {
        this.isMore = this.item.wallet.balance < this.amount;
      }, 500),
    },

    item: {
      handler(val) {
        this.account_name = val.bank_detail.account_name;
        this.account_no = val.bank_detail.account_no;
        this.bank = val.bank_detail.bank_name;
        console.log(val, "ommmo");
      },
    },
  },

  beforeMount() {
    this.getBanks();
  },
};
</script>

<style></style>
