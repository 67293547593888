<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <div v-if="isLoading">
      <span>Retrieving data...</span>
    </div>
    <div>
      <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase">
        Update Enrollee
      </h5>
      <!-- <validation-observer v-slot="{ handleSubmit }"> -->
      <validation-observer v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="tw-flex tw-flex-col tw-gap-4"
        >
          <span
            v-if="Object.keys(validationErrors).length > 0"
            class="tw-mb-5 tw-block tw-text-xs tw-text-error"
            >{{ validationErrors }}</span
          >
          <div class="tw-flex tw-gap-4">
            <div class="tw-w-full">
              <validation-provider
                name="first name"
                rules="required"
                v-slot="{ dirty, errors }"
              >
                <label for="first_name">First Name</label>
                <input
                  type="text"
                  name="text"
                  id="input"
                  v-model="first_name"
                  placeholder="Enter First name"
                  :class="{
                    error: dirty,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.first_name"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
            <div class="tw-w-full">
              <validation-provider
                name="last name"
                rules="required"
                v-slot="{ dirty, errors }"
              >
                <label for="last name">Last Name</label>
                <input
                  type="text"
                  name="text"
                  id="input"
                  v-model="last_name"
                  placeholder="Enter Last Name"
                  :class="{
                    error: dirty,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.last_name"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div class="tw-w-full">
              <validation-provider
                name="other names"
                v-slot="{ dirty, errors }"
              >
                <label for="other names">Other Names</label>
                <input
                  type="text"
                  name="text"
                  id="input"
                  v-model="other_name"
                  placeholder="Enter Other Names"
                  :class="{
                    error: dirty,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.other_name"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
          </div>

          <div class="tw-flex tw-gap-4">
            <div class="tw-w-full">
              <validation-provider
                name="gender"
                rules="required"
                v-slot="{ dirty, errors }"
              >
                <label for="username">Gender</label>
                <select
                  name=""
                  id="select"
                  v-model="gender"
                  :class="{
                    error: dirty,
                  }"
                >
                  <option value="" selected disabled>Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.gender"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
            <div class="tw-w-full">
              <validation-provider
                name="date of birth"
                rules="required"
                v-slot="{ dirty, errors }"
              >
                <label for="username">Date of Birth</label>
                <input
                  type="date"
                  name="text"
                  id="input"
                  v-model="dob"
                  placeholder="Enter Date of Birth"
                  :class="{
                    error: dirty,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.dob"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div class="tw-w-full">
              <validation-provider
                name="phone number"
                rules="required"
                v-slot="{ dirty, errors }"
              >
                <label for="username">Phone Number</label>
                <input
                  type="tel"
                  name="text"
                  id="input"
                  v-model="phone_number"
                  placeholder="Enter Phone Number"
                  :class="{
                    error: dirty,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.phone_number"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="email"
              rules="required"
              v-slot="{ dirty, errors }"
            >
              <label for="username">Email Address</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="email"
                placeholder="email"
                :class="{
                  error: dirty,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.email"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-flex tw-gap-4">
            <div class="tw-w-full">
              <validation-provider
                name="country"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="username">Country</label>
                <country-select
                  class="tw-w-full tw-block"
                  :countryName="true"
                  v-model="country"
                  :country="country"
                  topCountry="NG"
                  id="select"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.country"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
            <div class="tw-w-full">
              <validation-provider
                name="state"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="username">Select State </label>
                <region-select
                  class="tw-w-full tw-block"
                  id="select"
                  v-model="state"
                  :country="country"
                  :region="state"
                  :countryName="true"
                  :regionName="true"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.state"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div class="tw-w-full">
              <validation-provider
                name="lga"
                rules="required"
                v-slot="{ dirty, errors }"
              >
                <label for="username">Lga</label>
                <input
                  type="text"
                  name="text"
                  id="input"
                  v-model="city"
                  placeholder="Enter Lga"
                  :class="{
                    error: dirty,
                  }"
                />
                <small
                  class="tw-text-error tw-block tw-text-[10px]"
                  v-for="error in validationErrors.city"
                  :key="error"
                  >*{{ error }}</small
                >
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="address"
              rules="required"
              v-slot="{ dirty, errors }"
            >
              <label for="username">Address</label>
              <textarea
                id="input"
                v-model="address"
                placeholder="address"
                cols="30"
                rows="5"
                :class="{
                  error: dirty,
                }"
              >
              </textarea>
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.address"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-text-center">
            <button
              class="veo-btn veo-primary tw-w-5/12"
              v-bind:disabled="busy"
              :class="{ 'tw-bg-gray-400': busy }"
            >
              <span>{{ busy ? "..." : "Update Enrollee" }}</span>
            </button>
          </div>
        </form>
      </validation-observer>
    </div>

    <div>
      <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase tw-mt-4">
        Update Profile Photo
      </h5>
      <div class="tw-w-full">
        <label for="photo">Profile Photo</label>
        <input @change="onFileChange" type="file" id="input" />
      </div>
      <button
      @click="onSubmit('profilePhoto')"
        class="veo-btn veo-primary tw-w-5/12 tw-mt-3 tw-text-center"
        v-bind:disabled="busy"
        :class="{ 'tw-bg-gray-400': busy }"
      >
        <span>{{ busy ? "..." : "Update Profile Photo" }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataObj: {},
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      address: "",
      phone_number: "",
      country: "",
      state: "",
      city: "",
      password_confirmation: "",
      profile_picture: "",
      other_name: "",
      dob: "",
      gender: "",
      ref_code: "",
      dialogVisible: false,
      validationErrors: {},
      busy: false,
      isLoading: false,
      id: this.$route.params.id,
      imageUrl: null,
      loading: false
    };
  },

  methods: {
    onSubmit(e) {
      this.busy = true;
      let formData = new FormData();
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("country", this.country);
      formData.append("state", this.state);
      formData.append("city", this.city);
      formData.append("address", this.address);
      if(e) {
        formData.append("profile_picture", this.profile_picture);
      }
      formData.append("other_name", this.other_name);
      formData.append("dob", this.dob);
      formData.append("gender", this.gender);

      this.$users
        .updateEnrollee(this.id, formData)
        .then((res) => {
          console.log(res, "valid");
          this.getData();
          this.$swal.fire("Done!", "Enrollee edited succesfully.", "success");
          // this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.$swal.fire("Error!", err.data.message, "error");
          // this.validationErrors = err.data.errors;
        })
        .finally(() => {
          this.busy = false;
        });
    },

    getData() {
      this.isLoading = true;
      this.$users
        .getEnrollee(this.id)
        .then((res) => {
          console.log(res.data, "valid");
          this.user = res.data;
          let resPayload = res.data;
          this.first_name = resPayload.first_name;
          this.last_name = resPayload.last_name;
          this.email = resPayload.email;
          this.address = resPayload.address;
          this.phone_number = resPayload.phone_number;
          this.country = resPayload.country;
          this.state = resPayload.state;
          this.city = resPayload.city;
          this.other_name = resPayload.other_name;
          this.dob = resPayload.dob;
          this.gender = resPayload.gender;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    onFileChange(e) {
      var val = e.target.files[0];
      this.profile_picture = val;
    },

    handleClose() {
      this.dialogVisible = false;
    },
  },

  beforeMount() {
    this.getData();
  },
};
</script>

<style scoped>
/* El Dialog  */
.el-dialog__header {
  display: none;
}
</style>
