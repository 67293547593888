<template>
  <div>
    <el-dialog
      title="Withdrawal Details"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div v-if="busy">Retreiving Data</div>
      <div v-else>
        <div v-for="(value, name) in info" :key="value">
          <span class="tw-grid tw-grid-cols-2">
            <span class="tw-capitalize tw-font-light">{{
              name.split("_").join(" ")
            }}</span>
            <span
              class="tw-capitalize tw-font-semibold tw-flex tw-items-center tw-gap-1"
              >{{ value }}
              <span
                v-if="name === 'account_number'"
                @click="copyCode"
                role="button"
              >
                <i-icon icon="mynaui:copy" class="tw-text-[18px]" />
              </span>
            </span>
          </span>
          <hr class="tw-my-2" />
        </div>
        <span class="tw-grid tw-grid-cols-2">
          <span class="tw-capitalize tw-font-light">Status</span>
          <span :class="item.status" class="status">{{ item.status }}</span>
        </span>
        <hr class="tw-my-2" />

        <div
          class="tw-flex tw-gap-3 tw-justify-end tw-mt-5"
          v-if="item.status === 'pending'"
        >
          <button class="veo-btn veo-primary" @click="withdrawFunc('approved')">
            Approve
          </button>
          <button
            class="veo-btn veo-primary tw-bg-red-600"
            @click="withdrawFunc('rejected')"
          >
            Reject
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      hospitals: [],
      payload: {
        name: null,
      },
      busy: false,
      item: {},
    };
  },
  methods: {
    withdrawFunc(value) {
      let formData = new FormData();
      formData.append("status", value);
      this.busy = true;
      this.$finance
        .updateWithdrawal(this.item.id, formData)
        .then((res) => {
          console.log(res);
          this.busy = false;
          this.viewRecord();
          this.$emit('isComplete')
          this.$toastify({
            text: `${res.data}`,
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
          this.$toastify({
            text: "Operation Not completed",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        });
    },

    copyCode() {
      navigator.clipboard.writeText(this.item.account_no).then(
        function () {
          alert("Copied");
        },
        function () {
          alert("something Went wrong");
        }
      );
    },

    handleClose() {
      this.$emit("handleClose");
    },

    viewRecord() {
      this.busy = true;
      this.$finance
        .getWithdrawal(this.id)
        .then((res) => {
          console.log(res);
          this.item = res.data;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    mounted() {
        this.viewRecord()
    }
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
        this.viewRecord()
      },
      immediate: true,
    },
  },

  computed: {
    info() {
      if (Object.keys(this.item).length > 0) {
        const data = {
          date: this.item.created_at,
          amount: this.item.amount.toLocaleString("en-US", {
            style: "currency",
            currency: "NGN",
          }),
          user: `${this.item.user.first_name} ${this.item.user.last_name}`,
          account_number: this.item.account_no,
          bank_name: this.item.bank_name,
          account_name: this.item.account_name,
        };
        return data;
      } else {
        return {};
      }
    },
  },
};
</script>

<style></style>
