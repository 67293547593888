<template>
  <div>
    <div v-if="busy">
      <span>Retrieving data...</span>
    </div>
    <div v-else>
      <user-header
        :title="title"
        :subTitle="subTitle"
        :picture="picture"
        :displayKey="[
          'unique_id',
          'phone_number',
          'lga',
          'state',
          'country',
          'description',
          'address',
        ]"
        :data="dataList"
      />
      <div class="tw-bg-white tw-p-8 tw-w-full">
        <table-component
          class=""
          :items="items"
          :fields="fields"
          :paginate="false"
          :showBaseCount="false"
          :disableDeleteAction="true"
          :disableEditAction="true"
          @view="viewRecord"
        >
        <template #search>
          <h6 class="tw-font-bold">List of Registered Staffs</h6> 
        </template>
        <template #button>
          <button class="veo-btn veo-primary" @click="isVisible = !isVisible">Add Staff</button>
        </template>
        </table-component>
      </div>

      <!-- Add Stafff Modal  -->
      <add-staff :visible="isVisible" @isComplete="isComplete" @handleClose="isVisible = !isVisible"/>
    </div>
  </div>
</template>

<script>
import UserHeader from "@/components/utils/UserHeader.vue";
import TableComponent from "@/components/TableComponent.vue";
import AddStaff from '@/components/modals/users/AddStaff.vue';
export default {
  components: {
    UserHeader,
    TableComponent,
    AddStaff,
  },

  data() {
    return {
      items: [],
      fields: [
        {
          key: "fullName",
          label: "Name"
        },

        {
          key: "unique_id",
          label: "Unique ID"
        },

        {
          key: "due_date",
          label: "Due Date"
        },

        {
          key: "actions",
          label: ""
        }

        // {
        //   key: "drug.name",
        //   label: "Name",
        // },

        // {
        //   key: "price",
        //   label: "Price",
        //   formatter: (item) => {
        //     return item
        //       ? Number(item).toLocaleString("en-US", {
        //           style: "currency",
        //           currency: "NGN",
        //         })
        //       : "NGN 0.00";
        //   },
        // },
      ],
      id: this.$route.params.id,
      data: {},
      dataList: {
        unique_id: "",
        phone_number: "",
        lga: "",
        state: "",
        country: "",
        description: "",
        address: "",
      },
      busy: false,
      isVisible: false
    };
  },

  methods: {
    getData() {
      this.busy = true
      this.$users
        .getCorporate(this.id)
        .then((res) => {
          this.busy = false
          console.log(res.data, "valid");
          this.items = res.data.user
          this.data = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false
        });
    },

    isComplete(){
      this.getData()
      this.isVisible = !this.isVisible
    },

    viewRecord(value) {
      return this.$router.push(`/enrollee/view/${value.id}`)
    }
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    data: {
      handler(val) {
        this.dataList = {
          unique_id: val.unique_id,
          phone_number: val.phone_number,
          lga: val.lga,
          state: val.state,
          country: val.country,
          description: val.description,
          address: val.address,
        };
      },
      immediate: true,
    },
  },

  computed: {
    title() {
      return this.data.name;
    },
    subTitle() {
      return this.data.email;
    },
    picture() {
      return this.data.profile_picture;
    },
  },
};
</script>

<style></style>
