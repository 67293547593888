<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <table-component
      :items="items"
      :busy="busy"
      :fields="fields"
      @view="viewRecord"
      @delete="deleteRecord"
      @edit="editRecord"
      :perPage="perPage"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :paginationRange="count"
      :totalRecord="totalRows"
      :totalPages="pages"
      @page-changed="list"
    >
      <template #search>
        <search-filter @filter="filter" searchPlaceholder="Search Hospitals" @refresh="list"/>
      </template>
      <template #button>
        <router-link to="/hospital/create">
          <button class="veo-btn veo-primary">Add New Hospital</button>
        </router-link>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import SearchFilter from "@/components/filters/SearchFilter.vue";
export default {
  components: { TableComponent, SearchFilter },
  data() {
    return {
      items: [],
      busy: false,
      fields: [
        {
          key: "photo",
          label: "",
        },
        {
          key: "hospitalDetails",
          label: "Name",
        },
        {
          key: "address",
          label: "Address",
        },
        {
          key: "unique_id",
          label: "Unique ID",
        },
        {
          key: "class",
          label: "Class",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      currentPage: 1,
        perPage: 0,
        totalRows: 0,
        count: 1,
        pages: 1,
      prevRoute: null
    };
  },

  methods: {
    list(page = 1) {
      this.busy = true;
      this.$users
        .getHospitals(page)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.items;
          let meta = resPayload.meta.pagination;
          this.currentPage = meta.current_page;
          this.totalRows = meta.total;
          this.perPage = meta.per_page;
          this.count = meta.count
          this.pages = meta.total_pages
          this.busy = false;
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    viewRecord(value) {
      this.$router.push(`/hospital/view/${value.id}`)
    },

    editRecord(value) {
      this.$router.push(`/hospital/${value.id}/edit`)
    },

    complete(value) {
      this.$users.deleteHospital(value.id)
        .then((res) => {
          this.$swal.fire(
            "Deleted!",
            "Hospital deleted succesfully.",
            "success"
          );
          this.list();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          console.log(result, "kkk");
          if (result.isConfirmed) {
            this.complete(value);
          }
        });
    },

    filter(value) {
      console.log(value);
      this.busy = true;
      let payload = {
        search: value,
      };
      if(value === "") {
        this.list()
      } 
      else {
        this.$users
        .searchHospitals(payload)
        .then((res) => {
          let resPayload = res.data;
          console.log(resPayload, "ommo");
          this.items = resPayload;
          this.busy = false;
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
      }
    },
  },

  beforeMount() {
    this.list();
  },

};
</script>

<style></style>
