<template>
  <div>
    <div class="input-field">
      <input
        type="text"
        name="text"
        id="username"
        v-model="search"
        :placeholder="searchPlaceholder"
      />
      <span class="email-iccon">
        <i-icon icon="ion:search-outline" class="form-icon" />
      </span>
    </div>
  </div>
</template>

<script>
// import _ from "lodash";
import { debounce } from "lodash";
export default {
  props: {
    searchPlaceholder: {
      type: String,
      default: "search",
    },
  },
  components: {},
  data: () => {
    return {
      search: "",
    };
  },

  methods: {
    filter(value) {
      this.$emit("filter", value);
    },
  },

  watch: {
    search: {
      handler: debounce(function () {
        this.$emit("filter", this.search);
      }, 500)
    },
  },
};
</script>
