<template>
  <div class="">
    <b-overlay :show="isBusy" rounded="sm">
      <table-component
        :items="items"
        :busy="busy"
        :fields="fields"
        @view="getRecord($event, 'view')"
        @delete="deleteRecord"
        @edit="getRecord($event, 'edit')"
        :perPage="perPage"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :paginationRange="count"
        :totalRecord="totalRows"
        :totalPages="pages"
        @page-changed="list"
      >
        <template #search>
          <h5 class="tw-font-semibold">Drug Prices</h5>
        </template>
        <template #button>
          <div class="tw-flex tw-gap-3">
            <button class="veo-btn veo-primary" @click="addDrugPrice">
              Add Drug Price
            </button>

            <!-- <button class="veo-btn veo-primary" @click="addDrugPrice">
            Bulk Upload
          </button> -->
            <!--default html file upload button-->
            <input type="file" id="actual-btn" @change="onFileChange" hidden />

            <!--our custom file upload button-->
            <label for="actual-btn">Bulk Upload</label>
          </div>
        </template>
      </table-component>
    </b-overlay>

    <!-- Add Drug Price -->
    <add-drug-price
      :visible="dialogVisible"
      @handleClose="addDrugPrice"
      :viewMode="viewMode"
      :editMode="editMode"
      :title="title"
      :item="item"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import AddDrugPrice from "../modals/inventory/AddDrugPrice.vue";
export default {
  components: { TableComponent, AddDrugPrice },
  data() {
    return {
      items: [],
      busy: false,
      fields: [
        {
          key: "drug.name",
          label: "Drug Name",
        },
        {
          key: "hospital.name",
          label: "Hospital Name",
        },
        {
          key: "price",
          label: "Price",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
        {
          key: "actions",
          label: "",
        },
      ],
      prevRoute: null,
      dialogVisible: false,
      isVisible: true,
      viewMode: false,
      editMode: false,
      title: null,
      item: {},
      isBusy: false,
      currentPage: 1,
      perPage: 0,
      totalRows: 0,
      count: 1,
      pages: 1,
    };
  },

  methods: {
    list(page = 1) {
      this.busy = true;
      this.$inventory
        .getDrugPrices(page)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload.items;
          let meta = resPayload.meta.pagination;
          this.currentPage = meta.current_page;
          this.totalRows = meta.total;
          this.perPage = meta.per_page;
          this.count = meta.count;
          this.pages = meta.total_pages;
          this.busy = false;
          return res;
        })
        .catch((err) => {
          this.busy = false;
          return err
        });
    },

    addDrugPrice() {
      this.dialogVisible = !this.dialogVisible;
      this.viewMode = false;
      this.editMode = false;
      this.title = "Create Drug Price";
    },

    complete(value) {
      this.$inventory
        .deleteDrugPrice(value.id)
        .then((res) => {
          this.$swal.fire(
            "Deleted!",
            "Drug price deleted succesfully.",
            "success"
          );
          this.list();
          return res;
        })
        .catch((err) => {
          return err;
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Are you Sure? 🤔",
          text: "This action is irreversible!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.complete(value);
          }
        });
    },

    getRecord(e, value) {
      this.$inventory
        .viewDrugPrice(e.id)
        .then((res) => {
          let title;
          switch (value) {
            case "edit":
              title = "Edit Drug Price";
              this.editMode = true;
              break;
            case "view":
              title = "View Drug Price";
              this.viewMode = true;
              break;
            default:
              title = "Looking forward to the Weekend";
          }
          this.dialogVisible = true;
          this.title = title;
          this.item = res.data;
          return res;
        })
        .catch((err) => {
          return err
        });
    },

    onFileChange(e) {
      var val = e.target.files[0];
      console.log(val, "kkkk");
      let formData = new FormData();
      formData.append("file", val);
      this.isBusy = true;
      this.$inventory
        .drugsUpload(formData)
        .then((res) => {
          this.isBusy = false;
          this.$toastify({
            text: "Drugs uploaded successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          return res
        })
        .catch((err) => {
          this.isBusy = false;
          this.$toastify({
            text: "Drugs not uploaded",
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          return err
        });
    },
  },

  beforeMount() {
    this.list();
  },
};
</script>

<style scoped>
label {
  cursor: pointer;
  border: none;
  padding: 9px 12px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 4px;
  font-weight: 500;
  background-color: var(--primary-color);
  color: var(--white);
  margin-bottom: 0;
}
</style>
