import Vue from 'vue'

// Users
import $users from "./users.js";
Vue.prototype.$users = $users 

// Finance
import $finance from "./finance.js";
Vue.prototype.$finance = $finance


// Inventory
import $inventory from "./inventory.js";
Vue.prototype.$inventory = $inventory

// Admin
import $config from "./config.js";
Vue.prototype.$config = $config