<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <table-component
      :items="items"
      :busy="busy"
      :fields="fields"
      @view="viewRecord"
      @delete="deleteRecord"
      @edit="editRecord"
      :paginate="false"
      :showBaseCount="false"
    >
      <!-- <template #search>
        <search-filter
          @filter="filter"
          searchPlaceholder="Search Corporate Body"
          @refresh="list"
        />
      </template> -->
      <template #button>
        <router-link to="/corporate-body/create">
          <button class="veo-btn veo-primary">Add Corporate Body</button>
        </router-link>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
// import SearchFilter from "@/components/filters/SearchFilter.vue";
export default {
  components: { TableComponent },
  data() {
    return {
      items: [],
      busy: false,
      fields: [
        {
          key: "photo",
          label: "",
        },
        {
          key: "hospitalDetails",
          label: "Name",
        },
        {
          key: "address",
          label: "Address",
        },
        {
          key: "unique_id",
          label: "Unique ID",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      prevRoute: null,
    };
  },

  methods: {
    list(page = 1) {
      this.busy = true;
      this.$users
        .getCorporateBodies(page)
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload;
          this.busy = false;
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    viewRecord(value) {
      this.$router.push(`/corporate-body/view/${value.id}`);
    },

    editRecord(value) {
      this.$router.push(`/corporate-body/${value.id}/edit`);
    },

    complete(value) {
      this.$users
        .deleteCorporate(value.id)
        .then((res) => {
          this.$swal.fire(
            "Deleted!",
            "Corporate Body deleted succesfully.",
            "success"
          );
          this.list();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Continue?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          console.log(result, "kkk");
          if (result.isConfirmed) {
            this.complete(value);
          }
        });
    },

    filter(value) {
      console.log(value);
      this.busy = true;
      let payload = {
        search: value,
      };
      if (value === "") {
        this.list();
      } else {
        this.$users
          .searchEnrollees(payload)
          .then((res) => {
            let resPayload = res.data;
            console.log(resPayload, "ommo");
            this.items = resPayload;
            this.busy = false;
            return res;
          })
          .catch((err) => {
            console.log(err);
            this.busy = false;
          });
      }
    },
  },

  beforeMount() {
    this.list();
  },
};
</script>

<style></style>
