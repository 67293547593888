// Enrollees 
import Enrollees from "@/pages/users/enrollees/IndexPage.vue";
import CreateEnrollee from "@/pages/users/enrollees/CreateNew.vue";
import ViewEnrollee from "@/pages/users/enrollees/uuid/_uuid.vue";
import EditEnrollee from "@/pages/users/enrollees/uuid/edit_uuid.vue";

// Hospitals 
import Hospitals from "@/pages/users/hospitals/IndexPage.vue";
import CreateHospital from "@/pages/users/hospitals/CreateNew.vue";
import ViewHospital from "@/pages/users/hospitals/uuid/_uuid.vue";
import EditHospital from "@/pages/users/hospitals/uuid/edit_uuid.vue";

// Families 
import Families from "@/pages/users/families/IndexPage.vue";

// Groups 
import Groups from "@/pages/users/groups/IndexPage.vue";
import GroupDetails from "@/pages/users/groups/uuid/_uuid.vue";

// Enrollees 
import CorporateBodies from "@/pages/users/corporate_bodies/IndexPage.vue";
import CreateCorporateBody from "@/pages/users/corporate_bodies/CreateNew.vue";
import ViewCorporateBody from "@/pages/users/corporate_bodies/uuid/_uuid.vue";
import EditCorporateBody from "@/pages/users/corporate_bodies/uuid/edit_uuid.vue";

const router = [
  // Enrollees 
  {
    path: "/enrollees",
    name: "enrollees",
    component: Enrollees,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "enrollees",
      name: "enrollees",
      requiresAuth: true,
    },
  },

  {
    path: "/enrollee/create",
    name: "create-enrollee",
    component: CreateEnrollee,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "enrollees",
      name: "enrollees",
      requiresAuth: true,
      isSubPage: true,
      desc: 'Create New Enrollee'
    },
  },

  {
    path: "/enrollee/view/:id",
    name: "view-enrollee",
    component: ViewEnrollee,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "enrollees",
      name: "enrollees",
      requiresAuth: true,
      isSubPage: true,
      desc: 'View Enrollee'
    },
  },

  {
    path: "/enrollee/:id/edit",
    name: "edit-enrollee",
    component: EditEnrollee,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "enrollees",
      name: "edit-enrollee",
      requiresAuth: true,
      isSubPage: true,
    },
  },


  // Hospitals 

  {
    path: "/hospitals",
    name: "hospitals",
    component: Hospitals,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "hospitals",
      name: "hospitals",
      requiresAuth: true,
    },
  },

  {
    path: "/hospital/create",
    name: "create-hospital",
    component: CreateHospital,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "hospitals",
      name: "hospitals",
      requiresAuth: true,
      isSubPage: true,
      desc: 'Create New Hospital'
    },
  },

  {
    path: "/hospital/view/:id",
    name: "view-hospital",
    component: ViewHospital,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "hospitals",
      name: "hospitals",
      requiresAuth: true,
      isSubPage: true,
      desc: 'View Hospital'
    },
  },

  {
    path: "/hospital/:id/edit",
    name: "edit-hospital",
    component: EditHospital,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "corporate-bodies",
      name: "edit-hospital",
      requiresAuth: true,
      isSubPage: true,
    },
  },

  // Families 
  {
    path: "/families",
    name: "families",
    component: Families,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "families",
      name: "families",
      requiresAuth: true,
    },
  },

  // Groups
  {
    path: "/groups",
    name: "groups",
    component: Groups,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "groups",
      name: "groups",
      requiresAuth: true,
    },
  },

  {
    path: "/group/view/:id",
    name: "group-details",
    component: GroupDetails,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "groups",
      name: "group-details",
      requiresAuth: true,
      isSubPage: true,
    },
  },

   // Corporate Bodies
   {
    path: "/corporate-bodies",
    name: "corporate-bodies",
    component: CorporateBodies,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "corporate-bodies",
      name: "corporate-bodies",
      requiresAuth: true,
    },
  },

  {
    path: "/corporate-body/create",
    name: "create-corporate-body",
    component: CreateCorporateBody,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "corporate-bodies",
      name: "create-corporate-body",
      requiresAuth: true,
      isSubPage: true,
    },
  },

  {
    path: "/corporate-body/:id/edit",
    name: "edit-corporate-body",
    component: EditCorporateBody,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "corporate-bodies",
      name: "edit-corporate-body",
      requiresAuth: true,
      isSubPage: true,
    },
  },

  {
    path: "/corporate-body/view/:id",
    name: "view-corporate-body",
    component: ViewCorporateBody,
    meta: {
      layout: "AppDashboardLayout",
      parent: "users",
      subName: "corporate-body",
      name: "corporate-body",
      requiresAuth: true,
      isSubPage: true,
      desc: 'View corporate-body'
    },
  },
];

export default router;
