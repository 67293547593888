<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-semibold tw-mb-3">View All Claims</h5>
    <table-component
      :items="items"
      :busy="busy"
      :fields="fields"
      @view="viewRecord"
      :disableDeleteAction="true"
      :disableEditAction="true"
      :paginate="false"
      :showBaseCount="false"
    >
    </table-component>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
export default {
  components: { TableComponent },
  data() {
    return {
      items: [],
      busy: false,
      fields: [
        {
          key: "claimsUser",
          label: "Name",
        },
        {
          key: "claimsAddress",
          label: "Address",
        },
        {
          key: "claims_uniqueID",
          label: "Unique ID",
        },
        {
          key: "total_amount",
          label: "Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
        {
          key: "claim_status",
          label: "Status",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      prevRoute: null,
    };
  },

  methods: {
    list() {
      this.busy = true;
      this.$finance
        .getClaims()
        .then((res) => {
          let resPayload = res.data;
          this.items = resPayload;
          console.log(resPayload);
          this.busy = false;
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    viewRecord(value) {
      this.$router.push(`/claim/view/${value.id}`);
    },
  },

  beforeMount() {
    this.list();
  },
};
</script>

<style></style>
