<template>
  <div>
    
    <div v-if="busy">
      <span>Retrieving data...</span>
    </div>

    <div v-else>
      <div
        class="tw-bg-[#fff] tw-py-8 tw-px-8 tw-scroll-smooth tw-border-0 tw-rounded-md tw-mb-5"
        style=""
      >
        <div class="tw-flex tw-flex-col tw-gap-2">
          <div
            v-for="(value, name) in info"
            :key="value"
            class="tw-flex tw-gap-1 tw-items-center"
          >
            <span class="tw-capitalize tw-font-light tw-text-[14px]"
              >{{ name }}:</span
            >
            <span class="tw-capitalize tw-font-medium tw-text-[18px]">{{
              value
            }}</span>
          </div>
        </div>
      </div>
      <div class="tw-bg-white tw-rounded-md tw-p-8">
        <table-component :items="members" :busy="busy" :fields="fields" :paginate="false" :showBaseCount="false">
          <template #search>
            <h6 class="tw-font-semibold">Group Members</h6>
          </template>
          <template #button>
            <button class="veo-btn veo-primary" @click="addGroupMember">Add Group Member</button>
          </template>
        </table-component>
      </div>
    </div>

    <!-- Add Plan Benefit  -->
    <add-group-member :visible="isVisible" @isComplete="isComplete" @handleClose="addGroupMember"/>

  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import AddGroupMember from '@/components/modals/users/AddGroupMember.vue';
export default {
  components: {
    TableComponent,
    AddGroupMember,
  },

  data() {
    return {
      item: {},
      id: this.$route.params.id,
      members: [],
      busy: false,
      fields: [
        {
          key: 'user',
          label: 'User Name',
          formatter: (item) => {
            return `${item.first_name} ${item.last_name}`
          }
        },
        {
          key: 'plan.name',
          label: 'Plan'
        }
      ],
      isVisible: false
    };
  },

  methods: {
    getItem() {
      this.busy = true;
      this.$users
        .getGroup(this.id)
        .then((res) => {
          console.log(res);
          this.item = res.data;
          this.members = res.data.members;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    addGroupMember(){
      this.isVisible = !this.isVisible
    },

    isComplete(){
      this.getItem()
      this.isVisible = !this.isVisible
    }
  },

  beforeMount() {
    this.getItem();
  },

  computed: {
    info() {
      let info = {
        name: this.item.name,
      };
      return info;
    },
  },
};
</script>

<style>
.el-tabs__item {
  color: var(--dark-100) !important;
  font-weight: 400;
  font-size: 12.7px;
}

.el-tabs__item.is-active {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.el-tabs__active-bar {
  background-color: var(--primary-color) !important;
}
</style>
