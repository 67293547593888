<template>
    <div>
      
      <div v-if="busy">
        <span>Retrieving data...</span>
      </div>
  
      <div v-else>
        <div
          class="tw-bg-[#fff] tw-py-8 tw-px-8 tw-scroll-smooth tw-border-0 tw-rounded-md tw-mb-5"
          style=""
        >
          <div class="tw-flex tw-flex-col tw-gap-2">
            <div
              v-for="(value, name) in info"
              :key="value"
              class="tw-flex tw-gap-1 tw-items-center"
            >
              <span class="tw-capitalize tw-font-light tw-text-sm"
                >{{ name }}:</span
              >
              <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                value
              }}</span>
            </div>
          </div>
        </div>
        <div class="tw-bg-white tw-rounded-md tw-p-8">
          <table-component :items="benefits" :busy="busy" :fields="fields" :paginate="false" :showBaseCount="false">
            <template #search>
              <h6 class="tw-font-semibold">Plan Benefits</h6>
            </template>
            <template #button>
              <button class="veo-btn veo-primary" @click="addPlanBenefit">Add Plan Benefit</button>
            </template>
          </table-component>
        </div>
      </div>
  
      <!-- Add Plan Benefit  -->
      <add-plan-benefit :visible="isVisible" @isComplete="isComplete" @handleClose="addPlanBenefit"/>
  
    </div>
  </template>
  
  <script>
  import TableComponent from "@/components/TableComponent.vue";
  import AddPlanBenefit from '@/components/modals/plans/AddPlanBenefit.vue';
  export default {
    components: {
      TableComponent,
      AddPlanBenefit,
    },
  
    data() {
      return {
        item: {},
        id: this.$route.params.id,
        benefits: [],
        busy: false,
        fields: [
          {
            key: 'service.name',
            label: 'Service'
          },
          {
            key: 'quantity',
            label: 'Quantity'
          }
        ],
        isVisible: false
      };
    },
  
    methods: {
      getItem() {
        this.busy = true;
        this.$finance
          .getWithdrawal(this.id)
          .then((res) => {
            console.log(res);
            this.item = res.data;
            // this.benefits = res.data.benefits;
            this.busy = false;
          })
          .catch((err) => {
            this.busy = false;
            console.log(err);
          });
      },
  
      addPlanBenefit(){
        this.isVisible = !this.isVisible
      },
  
      isComplete(){
        this.getItem()
        this.isVisible = !this.isVisible
      }
    },
  
    beforeMount() {
      this.getItem();
    },
  
    computed: {
      info() {
        let info = {
          name: this.item.name,
          description: this.item.description,
          price: Number(this.item.price).toLocaleString("en-US", {
            style: "currency",
            currency: "NGN",
          }),
        };
        return info;
      },
    },
  };
  </script>
  
  <style>
  .el-tabs__item {
    color: var(--dark-100) !important;
    font-weight: 400;
    font-size: 12.7px;
  }
  
  .el-tabs__item.is-active {
    color: var(--primary-color) !important;
    font-weight: 600;
  }
  
  .el-tabs__active-bar {
    background-color: var(--primary-color) !important;
  }
  </style>
  