<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase">
      Add New Hospital
    </h5>
    <validation-observer v-slot="{ invalid, handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        class="tw-flex tw-flex-col tw-gap-4"
      >
        <span
          v-if="Object.keys(validationErrors).length > 0"
          class="tw-mb-5 tw-block tw-text-xs tw-text-error"
          >{{ validationErrors }}</span
        >
        <div class="tw-w-full">
          <validation-provider
            name="name"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="name">Name of Hospital</label>
            <input
              type="text"
              name="text"
              id="input"
              v-model="name"
              placeholder="Enter Name of Hospital"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.name"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="email"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Email Address</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="email"
                placeholder="email"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.email"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="phone number"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Phone Number</label>
              <input
                type="tel"
                name="text"
                id="input"
                v-model="phone_number"
                placeholder="Enter Phone Number"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.phone_number"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="class"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Class</label>
              <!-- <input
                type="tel"
                name="text"
                id="input"
                v-model="phone_number"
                placeholder="Enter Phone Number"
                :class="{
                  error: dirty && invalid,
                }"
              /> -->

              <select
                name=""
                id="input"
                v-model="hospital_class"
                :class="{
                  error: dirty && invalid,
                }"
              >
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
              </select>
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.class"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="country"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="username">Country</label>
              <country-select
                class="tw-w-full tw-block"
                :countryName="true"
                v-model="country"
                :country="country"
                topCountry="NG"
                id="select"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.country"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <div class="tw-w-full">
            <validation-provider
              name="state"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="username">Select State </label>
              <region-select
                class="tw-w-full tw-block"
                id="select"
                v-model="state"
                :country="country"
                :region="state"
                :countryName="true"
                :regionName="true"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.state"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="city"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">LGA</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="city"
                placeholder="Enter LGA"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.city"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="address"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Address</label>
            <textarea
              id="input"
              v-model="address"
              placeholder="address"
              cols="30"
              rows="5"
              :class="{
                error: dirty && invalid,
              }"
            >
            </textarea>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.address"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="description"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Description</label>
            <textarea
              id="input"
              v-model="description"
              placeholder="description"
              cols="30"
              rows="5"
              :class="{
                error: dirty && invalid,
              }"
            >
            </textarea>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.description"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="password"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Password</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="password"
                placeholder="Enter Password"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.password"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <!-- <div class="tw-w-full">
            <validation-provider
              name="confirm password"
              rules="required|confirmed:password"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Confirm Password</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="password_confirmation"
                placeholder="Confirm Password"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.password_confirmation"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div> -->
        </div>

        <div class="tw-w-full">
          <!-- <validation-provider
            name="profile photo"
            rules=""
            v-slot="{ errors }"
          > -->
            <label for="username">Profile Photo</label>
            <input @change="onFileChange" type="file" id="input" />
            <!-- <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span> -->
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.profile_photo"
              :key="error"
              >*{{ error }}</small
            >
          <!-- </validation-provider> -->
        </div>

        <div class="tw-mt-4 tw-text-center">
          <!-- <button
            class="veo-btn veo-primary tw-w-5/12"
          >
            <span>Add Enrollee</span>
          </button> -->

          <button
            class="veo-btn veo-primary tw-w-5/12"
            v-bind:disabled="invalid || busy"
            :class="{ 'tw-bg-gray-400': invalid || busy }"
          >
            <span>{{ busy ? "..." : "Create Hospital" }}</span>
          </button>
        </div>
      </form>
    </validation-observer>

    <!-- Success Modal When Created  -->
    <el-dialog
      title="Tips"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :show-title="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="tw-flex tw-flex-col tw-gap-2 tw-items-center">
        <i-icon
          icon="charm:circle-tick"
          class="tw-text-[80px] tw-text-primary-color"
        />
        <span class="tw-text-lg tw-font-bold tw-text-center"
          >Hospital Created <br />Successfully</span
        >
      </div>

      <span
        slot="footer"
        class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
      >
        <button
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-px-2 tw-bg-blue-500"
          @click="resetForm"
        >
          Create New
        </button>
        <button
          @click="view"
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-info tw-px-2"
        >
          View Hospital
        </button>
        <button
          @click="$router.go(-1)"
          class="veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-success tw-px-2"
        >
          Go Back
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataObj: {},
      name: "",
      email: "",
      password: "",
      address: "",
      phone_number: "",
      country: "",
      state: "",
      city: "",
      hospital_class: "",
      profile_picture: "",
      description: "",
      dialogVisible: false,
      validationErrors: {},
      busy: false,
      query: this.$route.query,
      resPay: {},
    };
  },

  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("country", this.country);
      formData.append("state", this.state);
      formData.append("lga", this.city);
      formData.append("class", this.hospital_class);
      formData.append("address", this.address);
      formData.append("profile_picture", this.profile_picture);
      formData.append("password", this.password);
      formData.append("description", this.description);
      this.busy = true;
      this.$users
        .createHospital(formData)
        .then((res) => {
          console.log(res, "valid");
          this.dialogVisible = true;
          this.busy = false;
          this.resPay = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.validationErrors = err.data.errors;
        });
    },

    onFileChange(e) {
      var val = e.target.files[0];
      this.profile_picture = val;
    },

    view() {
      this.$router.push(`/hospital/view/${this.resPay.id}`);
    },

    resetForm() {
      this.handleClose();
    },

    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
/* El Dialog  */
.el-dialog__header {
  display: none;
}
</style>
