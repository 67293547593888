<template>
  <div>
    <el-dialog
      title="Add Plan"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <validation-observer v-slot="{ invalid, handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" class="">
          <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-mb-5">
            <div>
              <validation-provider
                name="plan"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Select Plan</label>
                <v-select
                  v-model="plan"
                  :options="plans"
                  label="name"
                  :class="{
                    error: dirty && invalid,
                  }"
                  placeholder="Select Plan"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes"
                      ><i-icon
                        icon="radix-icons:caret-down"
                        color="#4F4F4F"
                        width="18px"
                    /></span>
                  </template>
                </v-select>
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="hospital"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Hospital</label>
                <v-select
                  @search="searchHospitals"
                  v-model="hospital"
                  :options="hospitals"
                  :reduce="(item) => item.id"
                  label="name"
                  :class="{
                    error: dirty && invalid,
                  }"
                  placeholder="Select Hospital"
                >
                  <template slot="no-options"> Type to search.. </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes"
                      ><i-icon
                        icon="radix-icons:caret-down"
                        color="#4F4F4F"
                        width="18px"
                    /></span>
                  </template>
                </v-select>
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="amount"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Amount(₦)</label>
                <input
                  type="text"
                  id="input"
                  placeholder="Enter Amount"
                  v-model="amount"
                  :class="{
                    error: dirty && invalid,
                  }"
                  readonly
                />
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="amount"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Duration(In Months)</label>
                <input
                  type="text"
                  id="input"
                  placeholder="Duration"
                  v-model="duration"
                  :class="{
                    error: dirty && invalid,
                  }"
                  readonly
                />
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="date of payment"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Date of Payment</label>
                <input
                  type="date"
                  id="input"
                  placeholder="Date of payment"
                  v-model="date_of_payment"
                  @change="setEndDate()"
                  :class="{
                    error: dirty && invalid,
                  }"
                />
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="expiry date"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Expiry Date</label>
                <input
                  type="date"
                  id="input"
                  placeholder="Expiry Date"
                  v-model="expiry_date"
                  :class="{
                    error: dirty && invalid,
                  }"
                  readonly
                />
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="method of payment"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Method of Payment</label>
                <v-select
                  v-model="method_of_payment"
                  :options="payment_methods"
                  label="name"
                  :class="{
                    error: dirty && invalid,
                  }"
                  placeholder="Select Method of Payment"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes"
                      ><i-icon
                        icon="radix-icons:caret-down"
                        color="#4F4F4F"
                        width="18px"
                    /></span>
                  </template>
                </v-select>
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="Payment Reference"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Payment Reference</label>
                <input
                  type="text"
                  id="input"
                  placeholder="Payment Reference"
                  v-model="payment_reference"
                  :class="{
                    error: dirty && invalid,
                  }"
                />
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
          </div>

          <span
            slot="footer"
            class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
          >
            <button
              class="veo-btn veo-primary tw-w-6/12"
              :disabled="invalid"
              :class="{ 'tw-bg-gray-400': invalid || busy }"
            >
              Add Plan
            </button>
          </span>
        </form>
      </validation-observer>
    </el-dialog>
  </div>
</template>

<script>
import { timeStamp2 } from "@/plugins/filter";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      plans: [],
      busy: false,
      id: this.$route.params.id,
      hospitals: [],
      payment_methods: [
        {
          name: "Cash",
        },
        {
          name: "Transfer",
        },
      ],

      expiry_date: null,
      date_of_payment: null,
      hospital: null,
      amount: null,
      plan: null,
      duration: 12,
      method_of_payment: null,
      payment_reference: null,
    };
  },
  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("hospital_id", this.hospital);
      formData.append("plan_id", this.plan.id);
      formData.append(
        "amount",
        this.amount.toLocaleString().replace(/\D/g, "")
      );
      formData.append("duration", this.duration);
      formData.append("date_of_payment", this.date_of_payment);
      formData.append("expiry_date", this.expiry_date);
      formData.append("method_of_payment", this.method_of_payment.name);
      formData.append("payment_reference", this.payment_reference);
      //   number.toLocaleString().replace(/\D/g,'')
      this.busy = true;
      this.$users
        .addPlan(this.id, formData)
        .then((res) => {
          console.log(res, "ommmo");
          this.$toastify({
            text: "Plan Added Succesfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
          this.$emit("isComplete");
        })
        .catch((err) => {
          this.busy = false;
          this.$toastify({
            text: "Plan not added",
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          return err;
        });
    },

    setEndDate() {
      var start = new Date(this.date_of_payment);
      let startTime = start.getTime();
      var end = start.setTime(startTime + 3600 * 1000 * 24 * 365);
      this.expiry_date = timeStamp2(new Date(end));
    },

    handleClose() {
      this.$emit("handleClose");
    },

    searchHospitals(search, loading) {
      let payload = { name: search };
      loading(true);
      this.$users
        .searchHospitals(payload)
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          loading(false);
        });
    },

    getPlans() {
      this.$inventory
        .getPlans()
        .then((res) => {
          console.log(res.data, "ommmo");
          this.plans = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  beforeMount() {
    this.getPlans();
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },

    plan: {
      handler(val) {
        if (val !== null) {
          console.log(val);
          this.amount = Number(val.price).toLocaleString();
        } else {
          this.amount = null;
        }
      },
    },
  },
};
</script>

<style></style>
