<template>
  <div>
    <table-component
      :items="items"
      :fields="fields"
      :paginate="false"
      :showBaseCount="false"
    >
      <template #search>
        <h6 class="tw-font-bold">User Plan Details</h6>
      </template>
      <template #button>
        <button class="veo-btn veo-primary" @click="$emit('addPlan')">
          Add Plan
        </button>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TableComponent,
  },
  data() {
    return {
      fields: [
        {
          key: "plan.name",
          label: "Plan Name",
        },

        {
          key: "expiry_date",
          label: "Due Date",
        },

        {
          key: "date_of_payment",
          label: "Date of Payment",
        },

        {
          key: "hospital.name",
          label: "Hospital",
        },

        {
          key: "method_of_payment",
          label: "Payment Method",
        },

        {
          key: "payment_reference",
          label: "Payment Reference",
        },
      ],
    };
  },
};
</script>

<style></style>
