<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="">
    <div class="tw-flex tw-items-center tw-justify-between">
      <div class="tw-w-full">
        <div v-if="$slots.search">
          <slot name="search"></slot>
        </div>
      </div>
      <div class="tw-flex tw-w-full tw-justify-end">
        <div v-if="$slots.button">
          <slot name="button"></slot>
        </div>
      </div>
    </div>
    <div v-if="$slots.tabs">
      <slot name="tabs"></slot>
    </div>
    <b-table
      id="globalTable"
      :items="items"
      :fields="fields"
      :filter="filter"
      :scrollable="scrollable"
      hover
      responsive
      show-empty
      sort-icon-left
      :busy="busy"
      :per-page="perPage"
      class="custom-table table-responsive mt-2"
      :class="classCustom"
      :table-class="tableClass"
      @row-clicked="rowClickAction($event)"
      :no-local-sorting="false"
      @row-hovered="sortEvent"
      @row-unhovered="onRowUnhovered"
    >

      <template #cell(fullName)="data">
        <span class="text-capitalize"
          >{{ data.item.first_name }} {{ data.item.last_name }}</span
        >
      </template>

      <template #head()="{ label, field: { key, sortable } }">
        {{ label }}
        <template v-if="sortable">
          <!-- Custom icons -->
          <template v-if="useCustomIcons">
            <b-icon
              v-if="sortBy !== key"
              icon="arrows-collapse"
              class="bold-icon"
            ></b-icon>
            <b-icon
              v-else-if="sortDesc"
              icon="arrow-down"
              class="bold-icon"
            ></b-icon>
            <b-icon v-else icon="arrow-up" class="bold-icon"></b-icon>
          </template>
          <!-- Normal icons -->
          <template v-else>
            <b-img
              v-if="sortBy !== key"
              :src="sortIconNeutral"
              style="width: 0.65rem; height: 1rem"
            ></b-img>
            <b-img
              v-else-if="sortDesc"
              :src="sortIconAsc"
              style="width: 0.65rem; height: 1rem"
            ></b-img>
            <b-img
              v-else
              :src="sortIconDesc"
              style="width: 0.65rem; height: 1rem"
            ></b-img>
          </template>
        </template>
      </template>

      <template #empty>
        <div class="p-4">
          <div
            class="d-flex align-items-center flex-column justify-content-center"
          >
            <h1 class="no-data">NO DATA RECORDED</h1>
            <!-- <img src="@/assets/img/empty.svg" alt="empty-list" class="empty" /> -->
          </div>
        </div>
      </template>

      <template #table-busy>
        <b-skeleton-table
          :rows="10"
          :columns="fields.length"
          :table-props="{ bordered: false, striped: false }"
        ></b-skeleton-table>
      </template>

      <template #cell(edit)="item">
        <slot name="edit" :data="item"></slot>
      </template>

      <template #cell(category_name)="data">
        <span class="tw-capitalize">{{ data.item.category_name }}</span>
      </template>

      <template #cell(photo)="data">
        <!-- <span class="tw-capitalize">{{ data.item }}</span> -->
        <img
          class="table-photo"
          :src="data.item.profile_picture ? config.apiUrl+data.item.profile_picture : require('@/assets/img/no_user.png')"
          @error="handleImageError"
          alt=""
        />
      </template>

      <template #cell(userDetails)="data">
        <div class="tw-flex tw-flex-col">
          <span
            class="tw-capitalize tw-text-[13px] tw-font-bold tw-block"
            v-if="data.item.firstname"
            >{{ `${data.item.firstname} ${data.item.lastname}` }}</span
          >
          <span class="tw-capitalize tw-text-[13px] tw-font-bold tw-block" v-else>{{
            `${data.item.first_name} ${data.item.last_name}`
          }}</span>
          <span class="tw-font-light">{{ data.item.phone_number }}</span>
        </div>
      </template>

      <template #cell(claimsUser)="data">
        <div class="tw-flex tw-flex-col">
          <span class="tw-capitalize tw-text-[13px] tw-font-bold">
            {{
              data.item.items.length === 0
                ? "No User"
                : data.item.items[0].itemable === null
                ? "No User"
                : data.item.items[0].itemable.user === null
                ? "No User"
                : `${data.item.items[0].itemable.user.first_name} ${data.item.items[0].itemable.user.last_name}`
            }}
          </span>
          <span class="tw-font-light">
            {{
              data.item.items.length === 0
                ? "No User"
                : data.item.items[0].itemable === null
                ? "No User"
                : data.item.items[0].itemable.user === null
                ? "No User"
                : `${data.item.items[0].itemable.user.phone_number}`
            }}
          </span>
        </div>
      </template>

      <template #cell(claimsAddress)="data">
        <span>
          {{
            data.item.items.length === 0
              ? "No User"
              : data.item.items[0].itemable === null
              ? "No User"
              : data.item.items[0].itemable.user === null
              ? "No User"
              : data.item.items[0].itemable.user.address
          }}
        </span>
      </template>

      <template #cell(claims_uniqueID)="data">
        <span>
          {{
            data.item.items.length === 0
              ? "No User"
              : data.item.items[0].itemable === null
              ? "No User"
              : data.item.items[0].itemable.user === null
              ? "No User"
              : data.item.items[0].itemable.user.unique_id
          }}
        </span>
      </template>

      <template #cell(hospitalDetails)="data">
        <div class="tw-flex tw-flex-col">
          <span class="tw-capitalize tw-text-[13px] tw-block tw-font-bold">{{
            `${data.item.name}`
          }}</span>
          <span class="tw-font-light">{{ data.item.phone_number }}</span>
        </div>
      </template>

      <template #cell(item-status)="data">
        <span class="tw-flex tw-items-center tw-gap-1">
          <span class="status" :class="data.item.status"></span>
          <span class="tw-text-[12px] tw-capitalize">{{
            data.item.status
          }}</span>
        </span>
      </template>

      <template #cell(availability)="data">
        <span class="tw-flex tw-items-center tw-gap-1">
          <span
            class="tw-text-8 status"
            :class="data.item.availability === 'yes' ? 'active' : 'inactive'"
          ></span>
          <span class="tw-text-[12px] tw-capitalize">{{
            data.item.availability === "yes" ? "In Stock" : "Out of Stock"
          }}</span>
        </span>
      </template>

      <template #cell(encounter_datetime)="data">
        <slot name="encounter_datetime" :data="data">
          <div>
            <!-- {{ formatDate(data.item.encounter_datetime) }} -->
          </div>
        </slot>
      </template>

      <template #cell(created_at)="data">
        <slot name="created_at" :data="data">
          <div>
            {{ data.item.created_at|displayDate }}
          </div>
        </slot>
      </template>

      <template #cell(date_of_payment)="data">
        <slot name="date_of_payment" :data="data">
          <div>
            {{ data.item.date_of_payment|displayDate }}
          </div>
        </slot>
      </template>

      <template #cell(expiry_date)="data">
        <slot name="expiry_date" :data="data">
          <div>
            {{ data.item.expiry_date|displayDate }}
          </div>
        </slot>
      </template>

      <template #cell(updated_at)="data">
        <slot name="updated_at" :data="data">
          <div>
            <!-- {{ formatDate(data.item.updated_at) }} -->
          </div>
        </slot>
      </template>

      <template #cell(ordered_datetime)="data">
        <slot name="ordered_datetime" :data="data">
          <div>
            <!-- {{ formatDate(data.item.ordered_datetime) }} -->
          </div>
        </slot>
      </template>

      <template #cell(date)="data">
        <slot name="date" :data="data">
          <div>
            <!-- {{ formatDate(data.item.date) }} -->
          </div>
        </slot>
      </template>

      <template #cell(time)="data">
        <slot name="time" :data="data">
          <div>
            <!-- {{ formatDate(data.item.time) }} -->
          </div>
        </slot>
      </template>

      <template #cell(triple_actions)="data">
        <slot name="triple_actions" :data="data">
          {{ data.value }}
        </slot>
      </template>

      <template #cell(claim_status)="data">
        <span
          class="status"
          :class="data.item.is_submitted == '0' ? 'pending' : 'completed'"
          >{{
            data.item.is_submitted === "0" ? "not submitted" : "submitted"
          }}</span
        >
      </template>

      <template #cell(status)="data">
        <span class="status" :class="data.item.status">{{
          data.item.status
        }}</span>
      </template>

      <template #cell(remote_status)="data">
        <span
          class="status"
          :class="
            data.item.remote_status == '0'
              ? 'pending'
              : data.item.remote_status == '1'
              ? 'completed'
              : 'canceled'
          "
          >{{
            data.item.remote_status == "0"
              ? "received"
              : data.item.remote_status == "1"
              ? "accepted"
              : "rejected"
          }}</span
        >
      </template>

      <template #cell(payment_status)="data">
        <span
          class="status"
          :class="
            data.item.payment_status === '0'
              ? 'pending'
              : data.item.payment_status == '1'
              ? 'completed'
              : 'canceled'
          "
          >{{
            data.item.payment_status === "0"
              ? "received"
              : data.item.payment_status == "1"
              ? "paid"
              : "cancelled"
          }}</span
        >
      </template>

      <template #cell(dots)="row">
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none tw-text-dark100"
          no-caret
        >
          <!-- <b-dropdown
            variant="link"
            toggle-class="text-decoration-none tw-text-dark100"
            no-caret
            v-if="row.item.status === 'pending'"
          > -->
          <template #button-content>
            <!-- <b-icon icon="three-dots-vertical" class="dot"></b-icon> -->
            <span class="tw-text-dark100 tw-font-bold">
              <i-icon icon="iconamoon:menu-kebab-vertical-bold" />
            </span>
          </template>
          <template v-if="dropdownItem.length > 0">
            <b-dropdown-item
              v-for="(dropdown, index) in dropdownItem"
              :key="index"
              class="text-capitalize"
              @click="$emit(dropdown, row.item)"
              >{{ dropdown.split("_").join(" ") }}</b-dropdown-item
            >
          </template>
        </b-dropdown>
      </template>

      <template #cell(type)="item">
        <slot name="type" :data="item"></slot>
      </template>

      <template #cell(uom)="data">
        <span name="uom"> {{ data.item.uom }}</span>
      </template>

      <template #cell(role)="data">
        <span name="role"> {{ roleName(data.item.role_id).name }}</span>
      </template>

      <template #cell(reference_range)="item">
        <slot name="reference_range" :data="item"></slot>
      </template>

      <template #cell(color)="data">
        <slot name="color" :data="data">
          <div
            class="rounded-circle"
            style="height: 25px; width: 25px"
            :style="{ backgroundColor: data.value }"
          ></div>
        </slot>
      </template>

      <template #cell(action)="data">
        <slot name="action" :data="data">{{ data.value }}</slot>
      </template>

      <template #cell(table_img)="data">
        <img
          class="tw-w-[30px] tw-h-[30px] tw-p-[2px] tw-border tw-border-primary tw-object-cover tw-object-center tw-rounded-full"
          :src="`${data.item.icon_image}`"
          alt=""
        />
      </template>

      <template #cell(actions)="row">
        <div class="text-right tw-flex tw-items-center tw-gap-2">
          <button
            v-if="!disableEditAction"
            @click="$emit('edit', row.item)"
            class="tw-text-amber-700"
          >
            <i-icon icon="iconamoon:edit" width="14px" />
          </button>
          <button
            v-if="!disableDeleteAction"
            @click="$emit('delete', row.item)"
            class="tw-text-red-600"
          >
            <i-icon icon="fluent:delete-12-regular" width="14px" />
          </button>
          <button
            v-if="!disableViewAction"
            @click="$emit('view', row.item)"
            class="tw-text-green-600"
          >
            <i-icon icon="grommet-icons:view" width="14px" />
          </button>
        </div>
      </template>
    </b-table>

    <div class="tw-flex tw-items-center tw-justify-center">
      <div v-if="showBaseCount && items.length > 0" class="">
        <div
          class="tw-mr-1 text-center tw-font-semibold tw-text-xs tw-text-light"
        >
          Showing page {{ page }} / {{ totalPages }}
        </div>
      </div>
      <b-pagination
        v-if="paginate"
        v-model="page"
        :total-rows="totalRows"
        :per-page="perPage"
        align="right"
        size="sm"
        class="my-0"
        @change="$emit('page-changed', $event)"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import roles from '@/api/roles.json'
export default {
  props: {
    showFrom: {
      type: Number,
      default: 0,
    },
    showTo: {
      type: Number,
      default: 0,
    },
    paginationRange: {
      type: Number,
      default: 0,
    },
    totalRecord: {
      type: Number,
      default: 0,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    totalRows: {
      type: Number,
      default: 0,
    },

    isClientPagination: {
      type: Boolean,
      default: false,
    },
    showTableSearch: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    currentPage: {
    },

    classCustom: {
      type: String,
      default: "custom-class",
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
      required: false,
    },
    perPage: {
      type: Number,
    },
    /**
     * @description
     * The number of pages coming from the server(API)
     */
    pages: {
      type: Number,
      default: 0,
    },
    showBaseCount: {
      type: Boolean,
      default: true,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    /**
     * @description
     * If true, pagination will be displayed
     */
    paginate: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: String,
      required: false,
      default: "",
    },
    searchFilters: {
      type: String,
      required: false,
      default: "",
    },
    // this is for the three dots dropdown
    dropdownItem: {
      type: Array,
      default: () => [],
    },
    tableClass: {
      type: String,
      default: "custom-table pointer",
    },
    disableEditAction: {
      type: Boolean,
      default: false,
    },
    disableDeleteAction: {
      type: Boolean,
      default: false,
    },
    disableViewAction: {
      type: Boolean,
      default: false,
    },
    filterbank: {
      type: Array,
      default: () => [],
    },
  },

  components: {
  },

  data: () => {
    return {
      page: "",
      roles
    };
  },

  methods: {
    rowClickAction(item) {
      this.$emit("row-clicked", item);
    },
    roleName(e) {
      console.log(e, this.roles);
      let roleData = this.roles.find((elem)=> e === elem.id)
      console.log(roleData);
      return roleData
    },
    onRowUnhovered(e) {
      this.$emit("unhoverData", e);
    },
    sortEvent(e) {
      this.$emit("sortData", e);
    },
    handleImageError(event) {
      // Handle the error, e.g., set a default image or log the error
      event.target.src = require('@/assets/img/no_user.png');
    },
  },

  mounted() {
  },

  watch: {
    currentPage: {
      handler(val) {
        this.page = val
      },
      immediate: true
    }
  }
};
</script>

<style>
.no-data {
  font-size: 16px;
}

.empty {
  width: 150px;
  height: 150px;
}

.dropdown-item {
  font-size: 13px;
}

.table-photo {
  height: 35px;
  width: 35px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}
</style>
