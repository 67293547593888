var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.busy)?_c('div',[_c('span',[_vm._v("Retrieving data...")])]):_c('div',[_c('user-header',{attrs:{"title":_vm.title,"subTitle":_vm.subTitle,"picture":_vm.picture,"displayKey":[
        'unique_id',
        'class',
        'phone_number',
        'lga',
        'state',
        'country',
        'description',
        'address' ],"data":_vm.hospitalData}}),_c('div',{staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-bg-white tw-p-8 tw-w-full"},[_c('h6',{staticClass:"tw-font-bold"},[_vm._v("Drugs price List")]),_c('table-component',{staticClass:"tw-h-80 tw-overflow-auto",attrs:{"items":_vm.drugs,"fields":_vm.drugFields,"paginate":false,"showBaseCount":false}})],1),_c('div',{staticClass:"tw-bg-white tw-p-8 tw-w-full"},[_c('h6',{staticClass:"tw-font-bold"},[_vm._v("Services price List")]),_c('table-component',{staticClass:"tw-h-80 tw-overflow-auto",attrs:{"items":_vm.services,"fields":_vm.serviceFields,"paginate":false,"showBaseCount":false}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }