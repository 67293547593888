<template>
    <div>
      <el-dialog
        title="Add Staff"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <validation-observer v-slot="{ invalid, handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            class="tw-flex tw-flex-col tw-gap-4"
          >
            <div>
              <validation-provider
                name="drug"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">User</label>
                <v-select
                  @search="getItems"
                  v-model="payload.user"
                  :options="items"
                  label="first_name"
                  :reduce="(item) => item.id"
                  :class="{
                    error: dirty && invalid,
                  }"
                  placeholder="Select User"
                >
                  <template slot="no-options"> Type to search.. </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes"
                      ><i-icon
                        icon="radix-icons:caret-down"
                        color="#4F4F4F"
                        width="18px"
                    /></span>
                  </template>
                  <template #option="{ first_name, last_name }">
                    <span>{{ first_name }} {{ last_name }}</span>
                  </template>
                  <template #selected-option="{ first_name, last_name }">
                    <span>{{ first_name }} {{ last_name }}</span>
                  </template>
                </v-select>
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>

            <div>
              <validation-provider
                name="plan"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Select Plan</label>
                <v-select
                  v-model="payload.plan"
                  :options="plans"
                  label="name"
                  :reduce="(item) => item.id"
                  :class="{
                    error: dirty && invalid,
                  }"
                  placeholder="Select Plan"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes"
                      ><i-icon
                        icon="radix-icons:caret-down"
                        color="#4F4F4F"
                        width="18px"
                    /></span>
                  </template>
                  
                </v-select>
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
  
            
            <span
              v-if="!viewMode"
              slot="footer"
              class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
            >
              <button
                class="veo-btn veo-primary"
                :disabled="invalid"
                :class="{ 'tw-bg-gray-400': invalid || busy }"
              >
                Add Staff
              </button>
            </span>
          </form>
        </validation-observer>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      viewMode: {
        type: Boolean,
        default: false,
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: "",
      },
      item: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        dialogVisible: false,
        items: [],
        plans: [],
        payload: {
          user: null,
          hospital: null,
        },
        busy: false,
        id: this.$route.params.id
      };
    },
    methods: {
      onSubmit() {
        let formData = new FormData();
        formData.append("user_id", this.payload.user);
        formData.append("plan_id", this.payload.plan);
        formData.append("corporate_body_id", this.id);
        this.busy = true;
        this.$users
        .addStaff(formData)
        .then((res) => {
          console.log(res);
          this.$toastify({
            text: "Staff added successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.busy = false;
          this.$emit("isComplete");
        })
        .catch((err) => {
          this.busy = false;
          this.$toastify({
            text: err.data.message,
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          return err
        });
      },
      
      handleClose() {
        this.$emit("handleClose");
      },
  
      getItems(search, loading) {
        let payload = { search: search };
        loading(true);
        this.$users
          .searchEnrollees(payload)
          .then((res) => {
            console.log(res.data, "ommmo");
            this.items = res.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            loading(false);
          });
      },

      getPlans() {
        this.$inventory
          .getPlans()
          .then((res) => {
            console.log(res.data, "ommmo");
            this.plans = res.data;
          })
          .catch((err) => {
            console.log(err);
          })
      },
    },

    beforeMount(){
        this.getPlans()
    },

    watch: {
      visible: {
        handler(val) {
          this.dialogVisible = val;
        },
        immediate: true,
      },
    },
  };
  </script>
  
  <style></style>
  