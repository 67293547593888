import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from './auth.js'
import Home from './home.js'
import Users from './users.js'
import Inventory from './inventory.js'

Vue.use(VueRouter)

const routes = []

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes.concat(Auth, Home, Users, Inventory)
})


router.beforeEach((to, from, next) => {
    var isLoggedIn = localStorage.getItem("veo-token");
    // let user = store.getters["auth/getUser"];
    // console.log(user, "kkk");
    // Check if the route requires authentication
    if (to.matched.some((record) => record.meta.requiresAuth)) {
    //   Check if the user is authenticated
      if (!isLoggedIn) {
        // Redirect to the login page
        next({ path: "/sign-in", query: { redirectFrom: to.fullPath } });
      } else {
        next();
      }
    } else {
      next();
    }
  });

export default router