var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-bg-white tw-rounded-md tw-p-8"},[_c('h5',{staticClass:"tw-font-bold tw-text-center tw-mb-6 tw-uppercase"},[_vm._v(" Add new Enrollee ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"tw-flex tw-flex-col tw-gap-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(Object.keys(_vm.validationErrors).length > 0)?_c('span',{staticClass:"tw-mb-5 tw-block tw-text-xs tw-text-error"},[_vm._v(_vm._s(_vm.validationErrors))]):_vm._e(),_c('div',{staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"first_name"}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter First name"},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name=$event.target.value}}}),_vm._l((_vm.validationErrors.first_name),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"last name"}},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter Last Name"},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name=$event.target.value}}}),_vm._l((_vm.validationErrors.last_name),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"other names"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"other names"}},[_vm._v("Other Names")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.other_name),expression:"other_name"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter Other Names"},domProps:{"value":(_vm.other_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.other_name=$event.target.value}}}),_vm._l((_vm.validationErrors.other_name),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Gender")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],class:{
                error: dirty && invalid,
              },attrs:{"name":"","id":"select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.gender=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":"","disabled":""}},[_vm._v("Select Gender")]),_c('option',{attrs:{"value":"male"}},[_vm._v("Male")]),_c('option',{attrs:{"value":"female"}},[_vm._v("Female")])]),_vm._l((_vm.validationErrors.gender),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Date of Birth")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dob),expression:"dob"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"date","name":"text","id":"input","placeholder":"Enter Date of Birth"},domProps:{"value":(_vm.dob)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dob=$event.target.value}}}),_vm._l((_vm.validationErrors.dob),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"phone number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Phone Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone_number),expression:"phone_number"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"tel","name":"text","id":"input","placeholder":"Enter Phone Number"},domProps:{"value":(_vm.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone_number=$event.target.value}}}),_vm._l((_vm.validationErrors.phone_number),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:{
              error: dirty && invalid,
            },attrs:{"type":"text","name":"text","id":"input","placeholder":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._l((_vm.validationErrors.email),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Country")]),_c('country-select',{staticClass:"tw-w-full tw-block",attrs:{"countryName":true,"country":_vm.country,"topCountry":"NG","id":"select"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_vm._l((_vm.validationErrors.country),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Select State ")]),_c('region-select',{staticClass:"tw-w-full tw-block",attrs:{"id":"select","country":_vm.country,"region":_vm.state,"countryName":true,"regionName":true},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_vm._l((_vm.validationErrors.state),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var dirty = ref.dirty;
            var invalid = ref.invalid;
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Lga")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter Lga"},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.city=$event.target.value}}}),_vm._l((_vm.validationErrors.city),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Address")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],class:{
              error: dirty && invalid,
            },attrs:{"id":"input","placeholder":"address","cols":"30","rows":"5"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),_vm._l((_vm.validationErrors.address),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var dirty = ref.dirty;
            var invalid = ref.invalid;
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_vm._l((_vm.validationErrors.password),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"confirm password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"Confirm Password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_vm._l((_vm.validationErrors.password_confirmation),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"profile photo","rules":"photo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Profile Photo")]),_c('input',{attrs:{"type":"file","id":"input"},on:{"change":_vm.onFileChange}}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._l((_vm.validationErrors.profile_photo),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])})]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"referral code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"ref_code"}},[_vm._v("Referral Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ref_code),expression:"ref_code"}],class:{
              error: dirty && invalid,
            },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter referral code"},domProps:{"value":(_vm.ref_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ref_code=$event.target.value}}}),_vm._l((_vm.validationErrors.email),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-mt-4 tw-text-center"},[_c('button',{staticClass:"veo-btn veo-primary tw-w-5/12",class:{ 'tw-bg-gray-400': invalid || _vm.busy },attrs:{"disabled":invalid || _vm.busy}},[_c('span',[_vm._v(_vm._s(_vm.busy ? "..." : "Add Enrollee"))])])])])]}}])}),_c('el-dialog',{attrs:{"title":"Tips","visible":_vm.dialogVisible,"width":"30%","before-close":_vm.handleClose,"show-title":false,"show-close":false,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2 tw-items-center"},[_c('i-icon',{staticClass:"tw-text-[80px] tw-text-primary-color",attrs:{"icon":"charm:circle-tick"}}),_c('span',{staticClass:"tw-text-lg tw-font-bold tw-text-center"},[_vm._v("Enrollee Created "),_c('br'),_vm._v("Successfully")])],1),_c('span',{staticClass:"dialog-footer tw-flex tw-gap-2 tw-justify-center",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"veo-btn veo-primary tw-py-2 tw-text-[10px] tw-px-2 tw-bg-blue-500",on:{"click":_vm.resetForm}},[_vm._v(" Create New ")]),_c('button',{staticClass:"veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-info tw-px-2",on:{"click":_vm.view}},[_vm._v(" View Enrollee ")]),_c('button',{staticClass:"veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-success tw-px-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Go Back ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }