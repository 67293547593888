<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-semibold tw-mb-3">All Payments</h5>
    <table-component
      :items="items"
      :busy="busy"
      :fields="fields"
      @view="viewRecord"
      :disableDeleteAction="true"
      :disableEditAction="true"
      :paginate="false"
      :showBaseCount="false"
    >
    </table-component>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
export default {
  components: { TableComponent },
  data() {
    return {
      items: [],
      busy: false,
      fields: [
        {
          key: "hospital_name",
          label: "Provider",
        },
        {
          key: "claim_period",
          label: "Claims Period",
        },
        {
          key: "no_of_claims",
          label: "No of Claims",
        },
        {
          key: "total_amount",
          label: "Amount",
          formatter: (item) => {
            return item
              ? Number(item).toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })
              : "NGN 0.00";
          },
        },
      ],
      prevRoute: null,
    };
  },

  methods: {
    list() {
      this.busy = true;
      this.$finance
        .getInvoices()
        .then((res) => {
          let resPayload = res.data;
          let items = resPayload.filter((elem) => elem.payment_status === "1");
          this.items = items;
          console.log(resPayload);
          this.busy = false;
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    viewRecord(value) {
      this.$router.push(`/invoice/view/${value.id}`);
    },
  },

  beforeMount() {
    this.list();
  },
};
</script>

<style></style>
