import $request from "@/https/axios";

const $users = {
  //   Enrollees
  async getEnrollees(params) {
    return $request
      .get(`/admin/users`, {params})
      .then((response) => response.data);
  },

  async createEnrollee(body) {
    return $request
      .post("/auth/register/user", body)
      .then((response) => response.data);
  },

  async getEnrollee(params) {
    return $request
      .get(`/admin/users/get/${params}`)
      .then((response) => response.data);
  },

  async searchEnrollees(params) {
    return $request
      .post("admin/users/search", params)
      .then((response) => response.data);
  },

  async updateEnrollee(params, body) {
    return $request
      .post(`admin/users/update/${params}`, body)
      .then((response) => response.data);
  },

  async activateUser(params) {
    return $request.get(`/admin/users/activate/${params}`)
    .then((response)=> response.data)
  },

  async deleteEnrollee(params) {
    return $request
      .post(`admin/users/delete/${params}`)
      .then((response) => response.data);
  },

  async withdraw(params, body) {
    return $request
    .post(`admin/withdrawals/${params}`, body)
    .then((response)=> response.data)
  },

  async creditUser(params, body) {
    return $request
    .post(`admin/users/credit-wallet/${params}`, body)
    .then((response)=> response.data)
  },

  async addPlan(params, body) {
    return $request
    .post(`/admin/plans/user/add/${params}`, body)
    .then((response)=> response.data)
  },

  //   Hospitals
  async getHospitals(page) {
    return $request
      .get(`/admin/hospitals?page=${page}`)
      .then((response) => response.data);
  },

  async createHospital(body) {
    return $request
      .post("/admin/hospitals/add", body)
      .then((response) => response.data);
  },

  async getHospital(params) {
    return $request
      .get(`/admin/hospitals/get/${params}`)
      .then((response) => response.data);
  },

  async searchHospitals(params) {
    return $request
      .post("/admin/hospitals/search", params)
      .then((response) => response.data);
  },

  async updateHospital(params, body) {
    return $request
      .post(`admin/hospitals/update/${params}`, body)
      .then((response) => response.data);
  },

  async deleteHospital(params) {
    return $request
      .get(`admin/hospitals/delete/${params}`)
      .then((response) => response.data);
  },

  // Families
  async getFamilies() {
    return $request.get(`/admin/family/all`).then((response) => response.data);
  },

  // Groups
  async getGroups() {
    return $request.get(`/admin/groups`).then((response) => response.data);
  },

  async addGroup(body) {
    return $request
      .post("admin/groups/add", body)
      .then((response) => response.data);
  },

  async updateGroup(params, body) {
    return $request
      .post(`admin/groups/update/${params}`, body)
      .then((response) => response.data);
  },

  async deleteGroup(params) {
    return $request
      .get(`admin/groups/delete/${params}`)
      .then((response) => response.data);
  },

  async getGroup(params) {
    return $request
      .get(`/admin/groups/get/${params}`)
      .then((response) => response.data);
  },

  async addGroupMember(body) {
    return $request
      .post("/admin/groups/members/add", body)
      .then((response) => response.data);
  },

  // Corporate Bodies

  async getCorporateBodies() {
    return $request.get(`admin/corporate`).then((response) => response.data);
  },

  async createCorporateBody(body) {
    return $request
      .post("/admin/corporate/add", body)
      .then((response) => response.data);
  },

  async deleteCorporate(params) {
    return $request
      .get(`/admin/corporate/delete/${params}`)
      .then((response) => response.data);
  },

  async getCorporate(params) {
    return $request
      .get(`/admin/corporate/view/${params}`)
      .then((response) => response.data);
  },

  async updateCorporateBody(body, params) {
    return $request
      .post(`/admin/corporate/update/${params}`, body)
      .then((response) => response.data);
  },

  async addStaff(body) {
    return $request
      .post("/corporate/staff/add", body)
      .then((response) => response.data);
  },

};

export default $users;
