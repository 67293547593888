var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-bg-white tw-rounded-md tw-p-8"},[_c('h5',{staticClass:"tw-font-bold tw-text-center tw-mb-6 tw-uppercase"},[_vm._v(" Add New Corporate Body ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"tw-flex tw-flex-col tw-gap-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(Object.keys(_vm.validationErrors).length > 0)?_c('span',{staticClass:"tw-mb-5 tw-block tw-text-xs tw-text-error"},[_vm._v(_vm._s(_vm.validationErrors))]):_vm._e(),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Name of Corporate Body")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:{
              error: dirty && invalid,
            },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter Name of corporate body"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_vm._l((_vm.validationErrors.name),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var dirty = ref.dirty;
            var invalid = ref.invalid;
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._l((_vm.validationErrors.email),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"phone number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Phone Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone_number),expression:"phone_number"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"tel","name":"text","id":"input","placeholder":"Enter Phone Number"},domProps:{"value":(_vm.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone_number=$event.target.value}}}),_vm._l((_vm.validationErrors.phone_number),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Country")]),_c('country-select',{staticClass:"tw-w-full tw-block",attrs:{"countryName":true,"country":_vm.country,"topCountry":"NG","id":"select"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_vm._l((_vm.validationErrors.country),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Select State ")]),_c('region-select',{staticClass:"tw-w-full tw-block",attrs:{"id":"select","country":_vm.country,"region":_vm.state,"countryName":true,"regionName":true},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_vm._l((_vm.validationErrors.state),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("City")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter City"},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.city=$event.target.value}}}),_vm._l((_vm.validationErrors.city),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var dirty = ref.dirty;
              var invalid = ref.invalid;
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Address")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],class:{
              error: dirty && invalid,
            },attrs:{"id":"input","placeholder":"address","cols":"30","rows":"5"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),_vm._l((_vm.validationErrors.address),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var dirty = ref.dirty;
            var invalid = ref.invalid;
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],class:{
              error: dirty && invalid,
            },attrs:{"id":"input","placeholder":"description","cols":"30","rows":"5"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}}),_vm._l((_vm.validationErrors.description),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"tw-flex tw-gap-4"},[_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var dirty = ref.dirty;
            var invalid = ref.invalid;
            var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
                error: dirty && invalid,
              },attrs:{"type":"text","name":"text","id":"input","placeholder":"Enter Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_vm._l((_vm.validationErrors.password),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"tw-w-full"},[_c('validation-provider',{attrs:{"name":"profile photo","rules":"photo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v("Profile Photo")]),_c('input',{attrs:{"type":"file","id":"input"},on:{"change":_vm.onFileChange}}),(errors)?_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._l((_vm.validationErrors.profile_photo),function(error){return _c('small',{key:error,staticClass:"tw-text-error tw-block tw-text-[10px]"},[_vm._v("*"+_vm._s(error))])})]}}],null,true)})],1),_c('div',{staticClass:"tw-mt-4 tw-text-center"},[_c('button',{staticClass:"veo-btn veo-primary tw-w-5/12",class:{ 'tw-bg-gray-400': invalid || _vm.busy },attrs:{"disabled":invalid || _vm.busy}},[_c('span',[_vm._v(_vm._s(_vm.busy ? "..." : "Create Corporate Body"))])])])])]}}])}),_c('el-dialog',{attrs:{"title":"Tips","visible":_vm.dialogVisible,"width":"30%","before-close":_vm.handleClose,"show-title":false,"show-close":false,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2 tw-items-center"},[_c('i-icon',{staticClass:"tw-text-[80px] tw-text-primary-color",attrs:{"icon":"charm:circle-tick"}}),_c('span',{staticClass:"tw-text-lg tw-font-bold tw-text-center"},[_vm._v("Corporate Body Created "),_c('br'),_vm._v("Successfully")])],1),_c('span',{staticClass:"dialog-footer tw-flex tw-gap-2 tw-justify-center",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"veo-btn veo-primary tw-py-2 tw-text-[10px] tw-px-2 tw-bg-blue-500",on:{"click":_vm.resetForm}},[_vm._v(" Create New ")]),_c('button',{staticClass:"veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-info tw-px-2",on:{"click":_vm.view}},[_vm._v(" View Corporate Body ")]),_c('button',{staticClass:"veo-btn veo-primary tw-py-2 tw-text-[10px] tw-bg-success tw-px-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Go Back ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }