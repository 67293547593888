<template>
    <div>
      <table-component
        :items="referrals"
        :fields="fields"
        :paginate="false"
        :showBaseCount="false"
        :disableDeleteAction="true"
        :disableEditAction="true"
        :disableViewAction="true"
      >
        <template #search>
          <h6 class="tw-font-bold">Referrals</h6>
        </template>
        
      </table-component>
    </div>
  </template>
  
  <script>
  import TableComponent from "@/components/TableComponent.vue";
  export default {
    props: {
      referrals: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      TableComponent,
    },
    data() {
      return {
        fields: [
        {
          key: "fullName",
          label: "Name"
        },

        {
          key: "unique_id",
          label: "Unique ID"
        },

        {
          key: "created_at",
          label: "Date Created"
        },

        {
          key: "actions",
          label: ""
        }

        // {
        //   key: "drug.name",
        //   label: "Name",
        // },

        // {
        //   key: "price",
        //   label: "Price",
        //   formatter: (item) => {
        //     return item
        //       ? Number(item).toLocaleString("en-US", {
        //           style: "currency",
        //           currency: "NGN",
        //         })
        //       : "NGN 0.00";
        //   },
        // },
      ],
      };
    },
  };
  </script>
  
  <style></style>
  