// import Vue from 'vue';
import axios from '@/plugins/axios'
import createPersistedState from 'vuex-persistedstate';

// Vue.prototype.$http = http


import "toastify-js/src/toastify.css"
import Toastify from 'toastify-js'

const getDefaultState = () => {
    return {
        allFamily: [],
        family: {}
    };
};

export default {
    namespaced: true,
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        getAllFamilies: state => {
            return state.allFamily
        },
        getFamily: state => {
            return state.family
        }
    },
    mutations: {
        SET_ALL_FAMILY: (state, data) => {
            state.allFamily = data;
        },
        SET_FAMILY: (state, family) => {
            state.family = family;
        }
    },
    actions: {
        // Get Hospitals
        async getAllFamilies({ commit }) {
            const res = await axios.get('/admin/family/all')
            commit('SET_ALL_FAMILY', res.data.data);
            console.log(res.data.data);
        },

        // Create New Hospital 
        async createFamily({ dispatch }, payload) {
            const res = await axios.post('/admin/family/add', payload)
            dispatch('getAllFamilies')
            console.log(res);
        },

        // Create New Hospital 
        async addDependent({ dispatch }, { id, payload }) {
            try {
                const res = await axios.post(`admin/family/dependent/add/${id}`, payload)
                dispatch('getAllFamilies')
                Toastify({
                    text: "Dependent Added",
                    className: "info",
                    style: {
                        background: "green",
                        fontSize: "13px",
                        broderRadius: "5px"
                    }
                }).showToast();
                console.log(res);
            } catch (error) {
                return error
            }
        },

        // Get Single Hospital
        async getFamily({ commit }, id) {
            const res = await axios.get(`/admin/family/view/${id}`)
            commit('SET_FAMILY', res.data.data);
            console.log(res);
        },
    }
};