<template>
  <div>
    <!-- User Details -->
    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
      <span
        v-for="(value, name) in details"
        :key="name"
        class="tw-flex tw-flex-col"
      >
        <span class="tw-capitalize tw-font-light tw-text-xs">{{
          name.split("_").join(" ")
        }}</span>
        <span class="tw-font-semibold tw-text-sm">{{
          value === null ? "---" : value
        }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hospital_item: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    details() {
      let data = {
        name: this.hospital_item.name,
        email: this.hospital_item.email,
        phone_number: this.hospital_item.phone_number,
        class: this.hospital_item.class,
        country: this.hospital_item.country,
        description: this.hospital_item.description,
        lga: this.hospital_item.lga,
        state: this.hospital_item.state,
        address: this.hospital_item.address,
      };
      return data;
    },
  },
};
</script>

<style></style>
