<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase">
      Edit {{ dataObj.name }}
    </h5>
    <validation-observer v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        class="tw-flex tw-flex-col tw-gap-4"
      >
        <span
          v-if="Object.keys(validationErrors).length > 0"
          class="tw-mb-5 tw-block tw-text-xs tw-text-error"
          >{{ validationErrors }}</span
        >
        <div class="tw-w-full">
          <validation-provider
            name="name"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="name">Name of Hospital</label>
            <input
              type="text"
              name="text"
              id="input"
              v-model="name"
              placeholder="Enter Name of Hospital"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.name"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="email"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Email Address</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="email"
                placeholder="Email"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.email"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="phone number"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Phone Number</label>
              <input
                type="tel"
                name="text"
                id="input"
                v-model="phone_number"
                placeholder="Enter Phone Number"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.phone_number"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="class"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Class</label>
              <select
                name=""
                id="input"
                v-model="hospital_class"
                :class="{
                  error: dirty && invalid,
                }"
              >
                <option
                  v-for="item in ['A', 'B', 'C', 'D']"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </option>
                <!-- <option value="A">B</option>
                <option value="A">C</option>
                <option value="A">D</option> -->
              </select>
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.class"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="country"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="username">Country</label>
              <country-select
                class="tw-w-full tw-block"
                :countryName="true"
                v-model="country"
                :country="country"
                topCountry="NG"
                id="select"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.country"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <div class="tw-w-full">
            <validation-provider
              name="state"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="username">Select State </label>
              <region-select
                class="tw-w-full tw-block"
                id="select"
                v-model="state"
                :country="country"
                :region="state"
                :countryName="true"
                :regionName="true"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.state"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>

          <div class="tw-w-full">
            <validation-provider
              name="lga"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="username">Lga</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="city"
                placeholder="Enter Lga"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.city"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="address"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Address</label>
            <textarea
              id="input"
              v-model="address"
              placeholder="Address"
              cols="30"
              rows="5"
              :class="{
                error: dirty && invalid,
              }"
            >
            </textarea>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.address"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="description"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Description</label>
            <textarea
              id="input"
              v-model="description"
              placeholder="description"
              cols="30"
              rows="5"
              :class="{
                error: dirty && invalid,
              }"
            >
            </textarea>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.description"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-text-center">
          <!-- <button
              class="veo-btn veo-primary tw-w-5/12"
            >
              <span>Add Enrollee</span>
            </button> -->

          <button
            class="veo-btn veo-primary tw-w-5/12"
            v-bind:disabled="busy"
            :class="{ 'tw-bg-gray-400': busy }"
          >
            <span>{{ busy ? "..." : "Update Hospital" }}</span>
          </button>
        </div>
      </form>
    </validation-observer>
    <div>
      <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase tw-mt-4">
        Update Profile Photo
      </h5>
      <div class="tw-w-full">
        <label for="photo">Profile Photo</label>
        <input @change="onFileChange" type="file" id="input" />
      </div>
      <button
      @click="onSubmit('profilePhoto')"
        class="veo-btn veo-primary tw-w-5/12 tw-mt-3 tw-text-center"
        v-bind:disabled="busy"
        :class="{ 'tw-bg-gray-400': busy }"
      >
        <span>{{ busy ? "..." : "Update Profile Photo" }}</span>
      </button>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import $request from "@/https/axios";
export default {
  data() {
    return {
      dataObj: {},
      name: "",
      email: "",
      password: "",
      address: "",
      phone_number: "",
      country: "",
      state: "",
      city: "",
      hospital_class: "",
      profile_picture: null,
      description: "",
      dialogVisible: false,
      validationErrors: {},
      busy: false,
      id: this.$route.params.id,
      imageUrl: null,
      isValid: false,
    };
  },

  methods: {
    async onSubmit(e) {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("country", this.country);
      formData.append("state", this.state);
      formData.append("lga", this.city);
      formData.append("class", this.hospital_class);
      formData.append("address", this.address);
      if(e) {
        formData.append("profile_picture", this.profile_picture);
      }
      formData.append("description", this.description);

      console.log(this.profile_picture);

      // application/json

      this.busy = true;
      this.$users
        .updateHospital(this.id, formData)
        .then((res) => {
          console.log(res, "valid");
          this.$toastify({
            text: "Hospital updated successfully",
            className: "info",
            style: {
              background: "#333",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.$toastify({
            text: "Hospital not Updated",
            className: "info",
            style: {
              background: "red",
              fontSize: "12px",
              borderRadius: "5px",
            },
          }).showToast();
          this.validationErrors = err.data.errors;
        })
        .finally(() => {
          this.busy = false;
        });
    },

    handleClose() {
      this.dialogVisible = false;
    },

    onFileChange(e) {
      var val = e.target.files[0];
      this.profile_picture = val;
    },

    getHospital() {
      this.$users
        .getHospital(this.id)
        .then((res) => {
          let resPayload = res.data;
          this.dataObj = resPayload;
          console.log(resPayload);
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },

  beforeMount() {
    this.getHospital();
    // this.convertImage()
  },

  watch: {
    dataObj: {
      handler(val) {
        this.name = val.name;
        this.email = val.email;
        this.password = val.password;
        this.address = val.address;
        this.phone_number = val.phone_number;
        this.country = val.country;
        this.state = val.state;
        this.hospital_class = val.class;
        this.city = val.lga;
        this.description = val.description;
      },
      immediate: false,
    },
  },
};
</script>

<style scoped>
/* El Dialog  */
.el-dialog__header {
  display: none;
}
</style>
