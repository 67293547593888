<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <MainTab :tabs="tabs" :currentTab="activeTab" :currentName="activeName">
      <template #default="{ activeTab }">
        <component :is="tabs[activeTab].component" />
      </template>
    </MainTab>
  </div>
</template>

<script>
import MainTab from "@/components/utils/MainTab.vue";

// Components
import DrugPricesVue from "@/components/inventory/DrugPrices.vue";
import ServicePricesVue from "@/components/inventory/ServicePrices.vue";

export default {
  components: {
    MainTab,
  },
  data() {
    return {
      activeTab: 0,
      activeName: "drug-prices",
      tabs: [
        {
          id: 1,
          title: "Drug Prices",
          component: DrugPricesVue,
          name: "drug-prices",
        },
        {
          id: 2,
          title: "Service Prices",
          component: ServicePricesVue,
          name: "service-prices",
        },
      ],
    };
  },
  methods: {},

  beforeMount() {},

  computed: {
    title() {
      return this.user.first_name + " " + this.user.last_name;
    },
    subTitle() {
      return this.user.unique_id;
    },
    picture() {
      return this.user.profile_picture;
    },
  },
};
</script>

<style>
.el-tabs__item {
  color: var(--dark-100) !important;
  font-weight: 400;
  font-size: 12.7px;
}

.el-tabs__item.is-active {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.el-tabs__active-bar {
  background-color: var(--primary-color) !important;
}
</style>
