<template>
  <div
    class="tw-bg-[#fff] tw-pt-4 tw-scroll-smooth tw-border-0 tw-rounded-md tw-mb-5"
    style=""
  >
    <div>
      <div class="tw-px-6 tw-flex tw-items-center tw-justify-between">
        <div class="tw-flex tw-gap-3 tw-items-center">
          <img
            class="tw-h-[60px] tw-w-[60px] tw-object-cover tw-object-center tw-rounded-full"
            :src="`${config.apiUrl}${picture}`"
          />
          <div>
            <h2
              class="tw-text-xl text-capitalize tw-mb-0 tw-items-center tw-font-bold tw-text-primary tw-flex tw-gap-1"
            >
              <span>{{ title }}</span>
              <span
                v-if="hasStatus"
                class="tw-block tw-text-xs tw-font-semibold tw-py-1 tw-px-2 tw-rounded-[3px]"
                :class="[
                  item.can_access_healthcare === '0'
                    ? 'tw-bg-red-600 tw-text-white'
                    : countdown < 10  ? 'tw-text-white tw-bg-red-600' : 'tw-text-white tw-bg-green-600',
                ]"
              >
                {{
                  item.can_access_healthcare === '0'
                    ? "not activated"
                    : `${countdown} days left`
                }}
              </span>
            </h2>
            <span class="tw-text-gray4 tw-block tw-font-[400] tw-text-[12px]">{{
              subTitle
            }}</span>
            <button class="veo-btn veo-primary tw-py-[6px]" @click="$emit('activateFunc')" v-if="item.can_access_healthcare == 0">Activate</button>
          </div>
        </div>
        <div class="tw-flex">
          <slot name="card-icons"></slot>
        </div>
      </div>
      <hr class="tw-ml-[100px] tw-mt-2 tw-mb-0" />
      <div class="card-body tw-flex tw-px-6">
        <div class="tw-w-full">
          <slot name="main-body">
            <div class="tw-w-full">
              <div class="tw-grid tw-grid-cols-3 tw-gap-3">
                <div v-for="(value, name) in filterData" :key="value" class="">
                  <span
                    class="tw-text-xs tw-text-light tw-capitalize tw-font-xs tw-block"
                    >{{ name.split("_").join(" ") }}:</span
                  >
                  <span
                    v-if="typeof value !== 'object'"
                    class="tw-font-semibold tw-text-xs"
                  >
                    <slot :name="name" :value="value">
                      {{ value !== null ? value : "No data available" }}
                    </slot>
                  </span>
                </div>
              </div>
            </div>
          </slot>
        </div>

        <div v-if="$slots.rightBody" class="tw-w-full col-lg-3">
          <div class="tw-font-medium">
            <div class="tw-flex tw-justify-end">
              <div class="tw-border-l tw-border-gray4 tw-px-3">
                <slot name="rightBody"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from "lodash";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      required: true,
    },
    picture: {
      type: String,
      required: false,
    },
    displayKey: {
      type: Array,
      default: () => [],
      required: false,
    },
    hasStatus: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    countdown: {
      type: Number,
      default: 0,
    },
    
  },
  data() {
    return {
      format: [".jpg", ".jpeg", ".png"],
      profile_picture: this.data.profile_picture,
      isSticky: false,
      scrollPosition: null,
      months: "",
    };
  },
  computed: {
    getInitials() {
      return this.title.charAt(0);
    },

    filterData() {
      return pick(this.data, this.displayKey);
    },

    // picture() {
    //   if (this.profile_picture) {
    //     return this.profile_picture;
    //   } else if (!this.profile_picture && this.data.gender == "Female") {
    //     return require("@/assets/img/female-avatar.svg");
    //   } else if (!this.profile_picture && this.data.gender == "Male") {
    //     return require("@/assets/img/male-avatar.svg");
    //   }
    // },
  },
  methods: {},
};
</script>

<style scoped>
.transition {
  transition: opacity 2s ease;
  animation: slide-fade 0.3s ease-in-out 0s;
  /* opacity: 0; */
}
</style>
