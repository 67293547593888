// import Vue from 'vue';
import axios from '@/plugins/axios'
import createPersistedState from 'vuex-persistedstate';

import router from '@/router'
// Vue.prototype.$http = http

import "toastify-js/src/toastify.css"
import Toastify from 'toastify-js'

const getDefaultState = () => {
    return {
        plans: [],
        plan: {},
        plan_benefits: [],
        loading: false
    };
};

export default {
    namespaced: true,
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        getPlans: state => {
            return state.plans
        },
        getPlan: state => {
            return state.plan
        },
        isLoading: state => {
            return state.loading
        },
        getPlanBenefits: state => {
            return state.plan_benefits
        }
    },
    mutations: {
        SET_PLANS: (state, plans) => {
            state.plans = plans;
        },
        SET_PLAN: (state, plan) => {
            state.plan = plan;
        },
        SET_LOADING_STATUS: (state, data) => {
            state.plan = data;
        },
        SET_PLAN_BENEFITS: (state, plan_benefits) => {
            state.plan_benefits = plan_benefits;
        },

    },
    actions: {
        // Get Plans
        async getPlans({ commit }) {
            const res = await axios.get('/admin/plans')
            commit('SET_PLANS', res.data);
            console.log(res);
        },

        // Create Plan 
        async createPlan({ dispatch }, payload) {
            const res = await axios.post('/admin/plans/add', payload)
            dispatch('getPlans')
            Toastify({
                text: "Plan Created Succesfully",
                className: "info",
                style: {
                    background: "green",
                    fontSize: "12px",
                    borderRadius: "5px"
                }
            }).showToast();
            console.log(res);
        },

        // View Single Plan
        async getPlan({ commit }, id) {
            const res = await axios.get(`/admin/plans/view/${id}`)
            commit('SET_PLAN', res.data.data);
            console.log(res.data.data);
            return res
        },

        // Delete Plans
        async deletePlan({ commit }, id) {
            commit('SET_LOADING_STATUS', true);
            axios.get(`/admin/plans/delete/${id}`)
                .then((res) => {
                    Toastify({
                        text: "Plan Deleted Succesfully",
                        className: "info",
                        style: {
                            background: "green",
                            fontSize: "12px",
                            broderRadius: "5px"
                        }
                    }).showToast();
                    router.go(-1)
                    return res;
                })
                .catch((err) => {
                    return err
                })
                .finally(() => {
                    commit('SET_LOADING_STATUS', false);
                })

        },

        // Delete Plans
        async editPlan({ commit, dispatch }, payload) {
            commit('SET_LOADING_STATUS', true);
            console.log(payload);
            axios.post(`/admin/plans/update/${payload.id}`, payload.data)
                .then((res) => {
                    Toastify({
                        text: "Plan Updated Succesfully",
                        className: "info",
                        style: {
                            background: "green",
                            fontSize: "12px",
                            broderRadius: "5px"
                        }
                    }).showToast();
                    return res;
                })
                .catch((err) => {
                    Toastify({
                        text: "Something went wrong",
                        className: "info",
                        style: {
                            background: "red",
                            fontSize: "12px",
                            broderRadius: "5px"
                        }
                    }).showToast();
                    return err
                })
                .finally(() => {
                    dispatch("getPlan", payload.id)
                    commit('SET_LOADING_STATUS', false);
                })
        },

        // Create Plan Benefit 
        async createPlanBenefit({ commit, dispatch }, payload) {
            const res = await axios.post(`/admin/plans/benefits/add`, payload)
            commit('SET_PLAN_BENEFITS', res.data);
            dispatch('getPlans')
            Toastify({
                text: "Plan Benefit Added",
                className: "info",
                style: {
                    background: "green",
                    fontSize: "12px",
                    borderRadius: "5px"
                }
            }).showToast();
            return res
        }
    }
};