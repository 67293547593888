<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <!-- <h5 class="tw-font-semibold tw-mb-3">Plans</h5> -->
    <table-component
      :items="items"
      :fields="fields"
      @edit="groupFunc($event, 'edit')"
      @delete="deleteRecord"
      :busy="busy"
      @view="view"
      :paginate="false"
      :showBaseCount="false"
    >
      <template #button>
        <!-- <router-link to="/enrollee/create"> -->
        <button class="veo-btn veo-primary" @click="groupFunc('create')">
          Create Group
        </button>
        <!-- </router-link> -->
      </template>
    </table-component>

    <add-new-group
      :visible="visible"
      @completeAdd="completeAdd"
      @handleClose="closeModal"
      :item="item"
      :editMode="editMode"
      :title="title"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import AddNewGroup from "@/components/modals/users/GroupModal.vue";
export default {
  components: { TableComponent, AddNewGroup },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "name",
          label: "Name",
        },

        {
          key: "members",
          label: "Number of members",
          formatter: (item) => {
            return item.length
          }
        },
        // {
        //   key: "price",
        //   label: "Price",
        //   formatter: (item) => {
        //     return item
        //       ? Number(item).toLocaleString("en-US", {
        //           style: "currency",
        //           currency: "NGN",
        //         })
        //       : "NGN 0.00";
        //   },
        // },
        {
          key: "actions",
          label: "",
        },
      ],
      busy: false,
      visible: false,
      mode: null,
      item: {},
      editMode: false,
      title: null,
    };
  },
  methods: {
    list() {
      this.busy = true;
      this.$users
        .getGroups()
        .then((res) => {
          console.log(res.data, "valid");
          this.items = res.data;
          this.busy = false;
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
        });
    },

    groupFunc(e, value) {
      this.mode = value;
      console.log(value, e, "kkkk");
      if (value === "create") {
        this.visible = !this.visible;
        this.title = "Create New Group";
        this.editMode = false;
      } else if (value === "edit") {
        this.visible = !this.visible;
        this.item = e;
        this.editMode = true;
        this.title = `Update Group`;
      } else {
        this.visible = !this.visible;
        this.editMode = false;
        this.title = "Create New Family";
      }
    },

    complete(value) {
      this.$users
        .deleteGroup(value.id)
        .then((res) => {
          this.$swal.fire("Deleted!", "Group deleted succesfully.", "success");
          this.list();
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteRecord(value) {
      this.$swal
        .fire({
          title: "Are you Sure? 🤔",
          text: "This action is irreversible!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          console.log(result, "kkk");
          if (result.isConfirmed) {
            this.complete(value);
          }
        });
    },

    closeModal() {
      this.visible = !this.visible;
      this.item = {};
      this.editMode = false;
      this.title = null;
    },

    completeAdd() {
      this.visible = !this.visible;
      this.list();
    },

    view(e) {
      this.$router.push(`/group/view/${e.id}`);
    },
  },

  beforeMount() {
    this.list();
  },
};
</script>

<style></style>
