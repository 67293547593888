<template>
  <div>
    <div v-if="busy">
      <span>Retrieving data...</span>
    </div>
    <div v-else>
      <div
        class="tw-bg-[#fff] tw-py-8 tw-px-8 tw-scroll-smooth tw-border-0 tw-rounded-md tw-mb-5"
      >
        <span class="tw-capitalize tw-font-medium tw-text-[16px]">{{
          item.created_at | displayDate
        }}</span>
        <hr class="tw-my-2">
        <div>
          <div class="tw-flex tw-flex-col tw-gap-2">
            <div class="tw-flex tw-gap-1 tw-items-center">
              <span class="tw-capitalize tw-font-light tw-text-sm"
                >Care Type:</span
              >
              <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                item.care_type
              }}</span>
            </div>
            <div class="tw-flex tw-gap-1 tw-items-center">
              <span class="tw-capitalize tw-font-light tw-text-sm"
                >Amount:</span
              >
              <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                item.total_amount | currencyFormat
              }}</span>
            </div>
            <div class="tw-flex tw-gap-1 tw-items-center">
              <span class="tw-capitalize tw-font-light tw-text-sm"
                >Remarks:</span
              >
              <span class="tw-capitalize tw-font-medium tw-text-sm">{{
                item.remarks
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-bg-white tw-rounded-md tw-p-8">
        <MainTab :tabs="tabs" :currentTab="activeTab" :currentName="activeName">
          <template #default="{ activeTab }">
            <component
              :is="tabs[activeTab].component"
              :hospital_item="hospital"
              :user_item="user"
              :visit="visit"
              :items="items"
            />
          </template>
        </MainTab>
      </div>
    </div>
  </div>
</template>

<script>
import MainTab from "@/components/utils/MainTab.vue";

// Components
import HospitalDetails from "@/components/claims/HospitalDetails.vue";
import UserDetailsVue from "@/components/claims/UserDetails.vue";
import VisitDetailsVue from "@/components/claims/VisitDetails.vue";
import ItemsListVue from "@/components/claims/ItemsList.vue";
import UserHeader from "@/components/utils/UserHeader.vue";

export default {
  components: {
    MainTab,
    UserHeader,
  },
  data() {
    return {
      activeTab: 0,
      activeName: "user-details",
      tabs: [
        {
          id: 1,
          title: "User Details",
          component: UserDetailsVue,
          name: "user-details",
        },
        {
          id: 2,
          title: "Hospital Details",
          component: HospitalDetails,
          name: "hospital-details",
        },
        {
          id: 3,
          title: "Visit",
          component: VisitDetailsVue,
          name: "visit",
        },
        {
          id: 4,
          title: "Items",
          component: ItemsListVue,
          name: "items",
        },
      ],
      item: {},
      id: this.$route.params.id,
      user: {},
      hospital: {},
      visit: {},
      items: [],
      busy: false,
    };
  },
  methods: {
    getItem() {
      this.busy = true;
      this.$finance
        .getClaim(this.id)
        .then((res) => {
          console.log(res);
          this.item = res.data;
          this.visit = res.data.visits;
          this.items = res.data.items;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.log(err);
        });
    },

    getUser() {
      this.$users
        .getEnrollee(this.item.user_id)
        .then((res) => {
          console.log(res, "user");
          this.user = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getHospital() {
      this.$users
        .getHospital(this.item.hospital_id)
        .then((res) => {
          console.log(res, "hospital");
          this.hospital = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    item: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.getHospital();
          this.getUser();
        }
      },
      immediate: true,
      deep: true,
    },
  },

  beforeMount() {
    this.getItem();
  },

  computed: {},
};
</script>

<style>
.el-tabs__item {
  color: var(--dark-100) !important;
  font-weight: 400;
  font-size: 12.7px;
}

.el-tabs__item.is-active {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.el-tabs__active-bar {
  background-color: var(--primary-color) !important;
}
</style>
