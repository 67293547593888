// import Vue from 'vue';
import axios from "@/plugins/axios";
import createPersistedState from "vuex-persistedstate";

// Vue.prototype.$http = http

import "toastify-js/src/toastify.css";
import Toastify from "toastify-js";
import router from "@/router";

const getDefaultState = () => {
  return {
    allHospitals: [],
    hospitals: [],
    hospital: {},
    loading: false,
    isSuccessful: false,
    dataSet: null,
    metaData: {},
    validationErrors: {}
  };
};

export default {
  namespaced: true,
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    hospitals: (state) => {
      return state.allHospitals;
    },
    hospital: (state) => {
      return state.hospital;
    },
    isLoading: (state) => {
      return state.loading;
    },
    isSuccessful: (state) => {
      return state.isSuccessful;
    },
  },
  mutations: {
    SET_DATA: (state, payload) => {
      state.dataSet = payload.items;
      state.metaData = payload.meta.pagination;
    },
    SET_SEARCH_DATA: (state, payload) => {
      state.dataSet = payload;
      state.metaData = null
    },
    SET_HOSPITAL: (state, hospital) => {
      state.hospital = hospital;
    },
    SET_VALIDATION_ERRORS: (state, payload) => {
      state.loading = false;
      state.validationErrors = payload;
    },
    SET_LOADING_STATUS: (state, data) => {
      state.loading = data;
    },
    SET_SUCCESS_STATUS: (state, data) => {
      state.isSuccessful = data;
    },
    REMOVE_ERRORS: (state) => {
      state.validationErrors = {}
    }
  },
  actions: {
    // Get Hospitals
    getHospitals({ commit }, page) {
      commit("SET_LOADING_STATUS", true);
      axios
        .get("/admin/hospitals?page=" + page)
        .then((res) => {
          let resPayload = res.data.data;
          commit("SET_DATA", resPayload);
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
        });
    },

    // Get Hospitals
    searchHospitals({ commit }, payload) {
      commit("SET_LOADING_STATUS", true);
      axios
        .post(`/admin/hospitals/search`, payload.search)
        .then((res) => {
          let resPayload = res.data.data
          commit("SET_SEARCH_DATA", resPayload);
          
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
        });
    },

    // Create New Hospital
    async createHospital({ commit, dispatch }, payload) {
      commit("SET_LOADING_STATUS", true);
      axios
        .post("/admin/hospitals/add", payload)
        .then((res) => {
          dispatch("getHospitals");
          Toastify({
            text: "Hospital Created",
            className: "info",
            style: {
              background: "green",
              fontSize: "12px",
              broderRadius: "5px",
            },
          }).showToast();
          commit("SET_SUCCESS_STATUS", true);
          return res;
        })
        .catch((err) => {
          
          if(err.response.data.errors) {
            let errPayload = err.response.data.errors
            commit("SET_VALIDATION_ERRORS", errPayload)
          }
          return err;
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
        });
    },

    // Delete Hospital
    async deleteHospital({ commit, dispatch }, id) {
      commit("SET_LOADING_STATUS", true);
      axios
        .get(`/admin/hospitals/delete/${id}`)
        .then((res) => {
          dispatch("getHospitals");
          Toastify({
            text: "Hospital Deleted Succesfully",
            className: "info",
            style: {
              background: "green",
              fontSize: "12px",
              broderRadius: "5px",
            },
          }).showToast();
          router.go(-1);
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
        });
    },

    // Get Single Hospital
    async getHospital({ commit }, id) {
      const res = await axios.get(`/admin/hospitals/get/${id}`);
      commit("SET_HOSPITAL", res.data);
    },

    // Edit Hospital
    editHospital({ dispatch, commit }, payload) {
      commit("SET_LOADING_STATUS", true);
      axios
        .post(`/admin/hospitals/update/${payload.id}`, payload.data)
        .then((res) => {
          Toastify({
            text: "Hospital Updated",
            className: "info",
            style: {
              background: "green",
              fontSize: "12px",
              broderRadius: "5px",
            },
          }).showToast();
          commit("SET_SUCCESS_STATUS", true);
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          commit("SET_LOADING_STATUS", false);
          dispatch("getHospital", payload.id);
        });
    },

    // Remove Success Modal
    removeSuccessModal({ commit }) {
      commit("SET_SUCCESS_STATUS", false);
    },
  },
};
