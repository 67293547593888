<template>
  <div>
    <!-- User Details -->
    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
      <span
        v-for="(value, name) in details"
        :key="name"
        class="tw-flex tw-flex-col"
      >
        <span class="tw-capitalize tw-font-light tw-text-xs">{{
          name.split("_").join(" ")
        }}</span>
        <span class="tw-font-semibold tw-text-sm">{{
          value === null ? "---" : value
        }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user_item: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    details() {
      let data = {
        full_name: `${this.user_item.first_name} ${this.user_item.last_name}`,
        unique_id: this.user_item.unique_id,
        email: this.user_item.email,
        phone_number: this.user_item.phone_number,
        date_of_birth: this.user_item.dob,
        country: this.user_item.country,
        description: this.user_item.description,
        city: this.user_item.city,
        state: this.user_item.state,
        address: this.user_item.address,
      };
      return data;
    },
  },
};
</script>

<style></style>
