<template>
    <div>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <validation-observer v-slot="{ invalid, handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            class="tw-flex tw-flex-col tw-gap-4"
          >
            <div>
              <validation-provider
                name="plan_name"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Name</label>
  
                <input
                  type="text"
                  id="input"
                  placeholder="Enter Service Name"
                  v-model="payload.name"
                  :class="{
                    error: dirty && invalid,
                  }"
                />
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
  
            <div>
              <validation-provider
                name="plan_description"
                rules="required"
                v-slot="{ dirty, invalid, errors }"
              >
                <label for="">Description</label>
  
                <textarea
                  name=""
                  cols="30"
                  id="input"
                  rows="5"
                  placeholder="Enter Service Description"
                  v-model="payload.description"
                  :class="{
                    error: dirty && invalid,
                  }"
                ></textarea>
                <span class="tw-text-xs tw-text-error" v-if="errors">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
  
            <span
              slot="footer"
              class="dialog-footer tw-flex tw-gap-2 tw-justify-center"
            >
              <button
                class="veo-btn veo-primary"
                :disabled="invalid"
                :class="{ 'tw-bg-gray-400': invalid || busy }"
              >
                {{ editMode ? "Update Service" : "Create New Service" }}
              </button>
            </span>
          </form>
        </validation-observer>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: null,
      },
      editMode: {
        type: Boolean,
        default: false,
      },
    },
  
    data() {
      return {
        dialogVisible: false,
        payload: {
          name: null,
          description: null,
          price: null,
        },
        busy: false,
      };
    },
  
    methods: {
      onSubmit() {
        let formData = new FormData();
        formData.append("name", this.payload.name);
        formData.append("description", this.payload.description);
        this.busy = true;
        this.editMode ? this.updateServices(formData) : this.addServices(formData)
      },
  
      addServices(value){
        this.$inventory
          .addService(value)
          .then((res) => {
            console.log(res);
            this.$toastify({
              text: "Service created successfully",
              className: "info",
              style: {
                background: "#333",
                fontSize: "12px",
                borderRadius: "5px",
              },
            }).showToast();
            this.busy = false;
            this.$emit("completeAdd");
          })
          .catch((err) => {
            console.log(err);
            this.busy = false;
          });
      },
  
      updateServices(value){
        this.$inventory
          .updateService(this.item.id, value)
          .then((res) => {
            console.log(res);
            this.$toastify({
              text: "Service Updated successfully",
              className: "info",
              style: {
                background: "#333",
                fontSize: "12px",
                borderRadius: "5px",
              },
            }).showToast();
            this.busy = false;
            this.$emit("completeAdd");
          })
          .catch((err) => {
            console.log(err);
            this.busy = false;
          });
      },
  
      handleClose() {
        this.$emit("handleClose");
        this.payload = {
          name: "",
          description: "",
          price: "",
        };
      },
    },
  
    watch: {
      visible: {
        handler(val) {
          this.dialogVisible = val;
        },
        immediate: true,
      },
  
      item: {
        handler(val) {
          if (Object.keys(val).length > 0) {
            this.payload = {
              name: val.name,
              description: val.description,
            };
          }
        },
        immediate: true,
      },
    },
  };
  </script>
  
  <style></style>
  