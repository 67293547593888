<template>
  <div>
    <span class="tw-grid tw-grid-cols-4 tw-gap-4">
      <span class="tw-text-sm">Name</span>
      <span class="tw-text-sm">Type</span>
      <span class="tw-text-sm">Percentage</span>
    </span>
    <div class="tw-flex tw-flex-col tw-gap-4">
      <div
        class="tw-grid tw-grid-cols-4 tw-gap-4"
        v-for="(item, idx) in items"
        :key="item.id"
      >
        <span><input id="input" :disabled="editing !== item.id" v-model="item.name" type="text" /></span>
        <span><input id="input" :disabled="editing !== item.id" v-model="item.type" type="text" /></span>
        <span><input id="input" :disabled="editing !== item.id" v-model="item.percentage" type="text" /></span>
        <div class="tw-flex tw-gap-2">
          <button
            class="veo-btn veo-primary tw-w-full"
            :class="{ 'tw-bg-gray-400': isLoading === item.id }"
            @click="edit(item)"
          >
             {{ editing !== item.id ? 'Edit' : 'Done' }}
          </button>
          <button
            class="veo-btn veo-primary tw-bg-red-600 tw-w-full"
            :class="{ 'tw-bg-gray-400': busy }"
            @click="deleteRecord(item.id)"
          >
            Delete
          </button>
          <button
            v-if="idx + 1 === items.length && !adding"
            @click="adding = true"
            class="veo-btn veo-outline tw-text-primary-color tw-w-full tw-whitespace-nowrap"
          >
            Add New
          </button>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-4 tw-gap-4" v-if="adding || items.length <= 0">
        <span><input id="input" type="text" v-model="form.name" /></span>
        <span><input id="input" type="text" v-model="form.type" /></span>
        <span><input id="input" type="text" v-model="form.percentage" /></span>
        <div class="tw-flex tw-gap-2">
          <button
            @click="createBonus"
            class="veo-btn veo-primary tw-w-full"
            :class="{ 'tw-bg-gray-400': busy }"
          >
            Save
          </button>
          <button
            v-if="items.length > 0"
            class="veo-btn veo-primary tw-bg-red-600 tw-w-full"
            @click="adding = false"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      busy: false,
      form: {
        name: "",
        type: "",
        percentage: "",
      },
      items: [],
      adding: false,
      editing: null,
      isLoading: null
    };
  },
  methods: {
    fetchRecords() {
      this.$config.fetchBonusRecords().then((res) => {
        console.log(res);
        this.items = res.data.data;
      });
    },

    edit(item) {
        this.editing ? this.completeEdit(item) : this.changeState(item)
    },

    changeState(value){
        this.editing = !this.editing ? value.id : this.editing !== value.id ? value.id : null
    },

    completeEdit(value){
        console.log(value);
        this.isLoading = !this.isLoading ? value.id : null
        let payload = {
            name: value.name,
            percentage: value.percentage,
            type: value.type
        }
      this.$config
        .editBonus(payload, value.id)
        .then((res) => {
          console.log(res);
          this.fetchRecords();
          this.$swal.fire("Done!", "Bonus Edited.", "success");
        })
        .catch(() => {
          this.$swal.fire("Oops!", "Bonus Not updated.", "error");
        })
        .finally(() => {
          this.isLoading = null;
          this.editing = null
        });
    },

    createBonus() {
      this.busy = true;
      this.$config
        .createBonus(this.form)
        .then((res) => {
          console.log(res);
          this.fetchRecords();
          this.$swal.fire("Done!", "Bonus Created.", "success");
          this.form = {
            name: "",
            type: "",
            percentage: "",
          };
          this.adding = false
        })
        .catch(() => {
          this.$swal.fire("Oops!", "Wait Period Not updated.", "error");
        })
        .finally(() => {
          this.busy = false;
        });
    },

    complete(value) {
        this.$config
          .removeBonus(value)
          .then((res) => {
            this.$swal.fire(
              "Deleted!",
              "Bonus deleted succesfully.",
              "success"
            );
            this.fetchRecords();
            return res;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      deleteRecord(value) {
        this.$swal
          .fire({
            title: "Continue?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            console.log(result, "kkk");
            if (result.isConfirmed) {
              this.complete(value);
            }
          });
      },
  },

  beforeMount() {
    this.fetchRecords();
  },
};
</script>

<style></style>
