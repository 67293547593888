var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.busy)?_c('div',[_c('span',[_vm._v("Retrieving data...")])]):_c('div',[_c('user-header',{attrs:{"title":_vm.title,"subTitle":_vm.subTitle,"picture":_vm.picture,"displayKey":[
        'unique_id',
        'phone_number',
        'lga',
        'state',
        'country',
        'description',
        'address' ],"data":_vm.dataList}}),_c('div',{staticClass:"tw-bg-white tw-p-8 tw-w-full"},[_c('table-component',{attrs:{"items":_vm.items,"fields":_vm.fields,"paginate":false,"showBaseCount":false,"disableDeleteAction":true,"disableEditAction":true},on:{"view":_vm.viewRecord},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('h6',{staticClass:"tw-font-bold"},[_vm._v("List of Registered Staffs")])]},proxy:true},{key:"button",fn:function(){return [_c('button',{staticClass:"veo-btn veo-primary",on:{"click":function($event){_vm.isVisible = !_vm.isVisible}}},[_vm._v("Add Staff")])]},proxy:true}])})],1),_c('add-staff',{attrs:{"visible":_vm.isVisible},on:{"isComplete":_vm.isComplete,"handleClose":function($event){_vm.isVisible = !_vm.isVisible}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }