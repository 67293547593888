// import Vue from 'vue';
import $http from "@/https/axios";
// import Axios from "axios";
import createPersistedState from "vuex-persistedstate";

import router from "@/router";

import "toastify-js/src/toastify.css";
import Toastify from "toastify-js";

// Vue.prototype.$http = http

const getDefaultState = () => {
  return {
    token: "",
    user: null,
    loggedIn: false,
    loading: false,
  };
};

export default {
  namespaced: true,
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isAuthenticated: (state) => {
      return state.token;
    },
    isLoggedIn: (state) => {
      return state.loggedIn;
    },
    getUser: (state) => {
      return state.user;
    },
    isLoading: (state) => {
      return state.loading;
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_LOGGED_IN: (state) => {
      state.loggedIn = true;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_LOADING: (state) => {
      state.loading = true;
    },
    END_LOADING: (state) => {
      state.loading = false;
    },
    RESET: (state) => {
      Object.assign(state, getDefaultState());
    },
    LOGOUT: (state) => {
      state.user = null
      state.token = null
      localStorage.removeItem('veo-token')
      localStorage.clear()
    },
  },
  actions: {
    // User Login
    async userLogin({ commit }, payload) {
      commit("SET_LOADING");
      try {
        const res = await $http.post("auth/login", payload);
        commit("SET_TOKEN", res.data.access_token);
        console.log(res.data.access_token, "ommmo");
        commit("SET_USER", res.data.user);
        let token = res.data.access_token;
        localStorage.setItem("veo-token", token);
        let loggedIn = true;
        commit("SET_LOGGED_IN", loggedIn);
        Toastify({
          text: "Logged In",
          className: "info",
          style: {
            background: "green",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
        router.push("/");
        console.log(res);
      } catch (error) {
        Toastify({
          text: "Incorrect Login Credentials",
          className: "info",
          style: {
            background: "red",
            fontSize: "12px",
            borderRadius: "5px",
          },
        }).showToast();
        return error;
      } finally {
        commit("END_LOADING");
      }
    },
    async userLogout({ commit }) {
      commit('LOGOUT')
    },
  },
};
