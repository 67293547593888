import Prices from "@/pages/inventory/PricesPage.vue";
import Drugs from "@/pages/inventory/DrugsPage.vue";
import Services from "@/pages/inventory/ServicesPage.vue";

const router = [
  {
    path: "/prices",
    name: "prices",
    component: Prices,
    meta: {
      layout: "AppDashboardLayout",
      parent: "store",
      name: "prices",
      requiresAuth: true,
    },
  },

  {
    path: "/drugs",
    name: "drugs",
    component: Drugs,
    meta: {
      layout: "AppDashboardLayout",
      parent: "store",
      name: "drugs",
      requiresAuth: true,
    },
  },

  {
    path: "/services",
    name: "services",
    component: Services,
    meta: {
      layout: "AppDashboardLayout",
      parent: "store",
      name: "services",
      requiresAuth: true,
    },
  },

  
];

export default router;
