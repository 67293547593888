<template>
  <div class="tw-bg-white tw-rounded-md tw-p-8">
    <h5 class="tw-font-bold tw-text-center tw-mb-6 tw-uppercase">
      {{ ID ? "edit admin" : "create new admin" }}
    </h5>
    <validation-observer v-slot="{ invalid, handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        class="tw-flex tw-flex-col tw-gap-4"
      >
        <span
          v-if="Object.keys(validationErrors).length > 0"
          class="tw-mb-5 tw-block tw-text-xs tw-text-error"
          >{{ validationErrors }}</span
        >
        <div class="tw-flex tw-gap-4">
          <div class="tw-w-full">
            <validation-provider
              name="first name"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="first_name">First Name</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="first_name"
                placeholder="Enter First name"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.first_name"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
          <div class="tw-w-full">
            <validation-provider
              name="last name"
              rules="required"
              v-slot="{ dirty, invalid, errors }"
            >
              <label for="last name">Last Name</label>
              <input
                type="text"
                name="text"
                id="input"
                v-model="last_name"
                placeholder="Enter Last Name"
                :class="{
                  error: dirty && invalid,
                }"
              />
              <small
                class="tw-text-error tw-block tw-text-[10px]"
                v-for="error in validationErrors.last_name"
                :key="error"
                >*{{ error }}</small
              >
              <span class="tw-text-xs tw-text-error" v-if="errors">{{
                errors[0]
              }}</span>
            </validation-provider>
          </div>
        </div>

        <div class="tw-w-full" v-if="!ID">
          <validation-provider
            name="email"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Email Address</label>
            <input
              type="text"
              name="text"
              id="input"
              v-model="email"
              placeholder="Email Address"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.email"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-w-full" v-if="!ID">
          <validation-provider
            name="role"
            rules="required"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Select Role</label>
            <!-- {{roles}} -->
            <select
              name=""
              id="select"
              v-model="role_id"
              :class="{
                error: dirty && invalid,
              }"
            >
              <option value="">--Select One--</option>
              <option v-for="item in roles" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.role_id"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-w-full">
          <validation-provider
            name="password"
            :rules="!ID ? 'required' : ''"
            v-slot="{ dirty, invalid, errors }"
          >
            <label for="username">Password</label>
            <input
              type="text"
              name="text"
              id="input"
              v-model="password"
              placeholder="Enter Password"
              :class="{
                error: dirty && invalid,
              }"
            />
            <small
              class="tw-text-error tw-block tw-text-[10px]"
              v-for="error in validationErrors.password"
              :key="error"
              >*{{ error }}</small
            >
            <span class="tw-text-xs tw-text-error" v-if="errors">{{
              errors[0]
            }}</span>
          </validation-provider>
        </div>

        <div class="tw-mt-4 tw-text-center">
          <button
            class="veo-btn veo-primary tw-w-5/12"
            v-bind:disabled="invalid || busy"
            :class="{ 'tw-bg-gray-400': invalid || busy }"
          >
            <span>{{ busy ? "..." : ID ? "Edit Admin" : "Add Admin" }}</span>
          </button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import roles from "@/api/roles.json";
export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      role_id: "",
      validationErrors: {},
      busy: false,
      roles,
      ID: this.$route.params.uuid,
      user: {},
    };
  },

  methods: {
    createAdmin() {
      this.busy = true;
      const formdata = new FormData();
      formdata.append("first_name", this.first_name);
      formdata.append("last_name", this.last_name);
      formdata.append("email", this.email);
      formdata.append("password", this.password);
      formdata.append("role_id", this.role_id);
      this.$config
        .createAdmin(formdata)
        .then((res) => {
          console.log(res);
          this.$swal.fire("Done!", "Admin added succesfully.", "success");
          this.first_name = "";
          this.last_name = "";
          this.email = "";
          this.password = "";
          this.role_id = "";
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.busy = false;
        });
    },

    onSubmit() {
      this.ID ? this.editAdmin() : this.createAdmin();
    },

    editAdmin() {
      this.busy = true;
      const formdata = new FormData();
      formdata.append("first_name", this.first_name);
      formdata.append("last_name", this.last_name);
      formdata.append("password", this.password);
      this.$config
        .updateAdmin(formdata, this.ID)
        .then((res) => {
          console.log(res);
          this.$swal.fire("Done!", "Admin edited succesfully.", "success");
          this.getAdminRecord();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.busy = false;
        });
    },

    getAdminRecord() {
      this.$config.getAdminRecord(this.ID).then((res) => {
        console.log(res);
        let userData = res.data;
        this.first_name = userData.first_name;
        this.last_name = userData.last_name;
        // this.email = ""
      });
    },
  },

  watch: {
    ID: {
      handler(val) {
        if (val) {
          this.getAdminRecord();
        }
      },
      immediate: true,
    },
  },

  // beforeMount() {
  //   this.getAdminRecord();
  // },
};
</script>

<style></style>
