import $request from "@/https/axios";

const $config = {
  //   Lsu Admins
  async listAdmins() {
    return $request.get(`admin/admin-user`).then((response) => response.data);
  },

  async createAdmin(formdata) {
    return $request
      .post(`admin/admin-user`, formdata)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },

  async updateAdmin(formdata, ID) {
    return $request
      .post(`admin/admin-user/update/${ID}`, formdata)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },

  async removeAdminRecord(ID) {
    return $request
      .post(`admin/admin-user/delete/${ID}`)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },

  async  getAdminRecord(ID) {
    return $request
      .get(`admin/admin-user/view/${ID}`)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },

 
  

  async fetchRemovalRequestRecord() {
    return $request
      .get(`admin/account-removals`)
      .then((response) => response.data);
  },

  async fetchBonusRecords() {
    return $request
      .get(`admin/bonus-settings`)
      .then((response) => response.data);
  },


  async createBonus(formdata) {
    return $request
      .post(`admin/bonus-settings`, formdata)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },

  async editBonus(formdata, ID) {
    return $request
      .post(`admin/bonus-settings/update/${ID}`, formdata)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },

  async removeBonus(ID) {
    return $request
      .post(`admin/bonus-settings/delete/${ID}`)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },

  async fetchWaitPeriodRecords() {
    return $request.get(`admin/wait-period`).then((response) => response.data);
  },

  async updateWaitPeriodRecords(formdata, ID) {
    return $request
      .post(`admin/wait-period/update/${ID}`, formdata)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },
  
};

export default $config;
